import { getInputs, organizeForm } from '@vfit/shared/data-access';
import CmsForm from '../CmsForm/cmsForm';
import { ILeadPlatform } from './leadPlatform.models';

const LeadPlatform = ({ form, onSuccess, onError, onTrackSubmit }: ILeadPlatform) => {
  const cmsForms = organizeForm(form);
  const widgetForms = getInputs(cmsForms.input);
  return (
    <CmsForm
      {...widgetForms}
      config={cmsForms.config}
      submitConfig={cmsForms.submit}
      onSuccessForm={onSuccess}
      onErrorForm={onError}
      onTrackSubmit={onTrackSubmit}
    />
  );
};

export default LeadPlatform;
