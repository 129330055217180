import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const Success: React.FC<ISvgComponent> = ({ color, height, width, shrinkable, ...rest }) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 24 24"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Zm6.842-17.218a.75.75 0 0 0-1.06.043l-8.68 9.403-3.55-3.848a.75.75 0 0 0-1.103 1.018l4.102 4.444a.75.75 0 0 0 1.103 0l9.23-10a.75.75 0 0 0-.042-1.06Z"
      clipRule="evenodd"
    />
  </S.Svg>
);

