import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const Plus: React.FC<ISvgComponent> = ({ color, height, width, shrinkable, ...rest }) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 24 24"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeMiterlimit="10"
      d="M19.375 12H4.625M12 4.625v14.75"
    />
  </S.Svg>
);

