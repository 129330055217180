import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const CloseLs: React.FC<ISvgComponent> = ({ color, height, width, shrinkable, ...rest }) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 17.033 17.033"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.25"
      d="m2.642 14.392 11.75-11.75m0 11.75L2.642 2.641"
    />
  </S.Svg>
);

