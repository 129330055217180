import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const WarningBig: React.FC<ISvgComponent> = ({
  color,
  secondColor,
  height,
  width,
  shrinkable,
  ...rest
}) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 64 64"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M26.161 11.471 7.495 46.044a6.667 6.667 0 0 0 5.853 9.907h37.333a6.667 6.667 0 0 0 5.854-9.853L37.868 11.524a6.667 6.667 0 0 0-11.707 0z"
    />
    <path
      stroke={secondColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M32.015 21.285V40.07M32.348 46.618a.333.333 0 1 1-.667 0 .333.333 0 0 1 .667 0z"
    />
    <path
      stroke={secondColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M32.142 46.926a.333.333 0 1 0-.363-.072"
    />
  </S.Svg>
);

