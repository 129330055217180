import { css } from 'styled-components';
import { fonts } from '../../fonts';

export const cssfonts = {
  light: css`
    font-family: ${fonts.vodafone};
    font-weight: 300;
  `,
  regular: css`
    font-family: ${fonts.vodafone};
    font-weight: 400;
  `,
  bold: css`
    font-family: ${fonts.vodafone};
    font-weight: 700;
  `,
  exbold: css`
    font-family: ${fonts.vodafone};
    font-weight: 800;
  `,
} as const;

