import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const TickCircle: React.FC<ISvgComponent> = ({ color, height, width, shrinkable, ...rest }) => (
<S.Svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={color}
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      d="M45.853 10.07a1 1 0 0 0-1.414-1.414l1.414 1.414ZM21.676 32.833l-.707.707.707.708.707-.708-.707-.707Zm-9.72-11.143a1 1 0 0 0-1.414 1.414l1.415-1.414Zm34.346-4.325a1 1 0 0 0-1.928.53l1.928-.53Zm-13.315 26.36.406.914-.406-.914Zm-27.644-8.33.843-.538-.843.538ZM9.444 6.82l-.657-.753.657.753Zm28.228.562a1 1 0 0 0 1.294-1.525l-1.294 1.525Zm6.767 1.274-23.47 23.47 1.414 1.414 23.47-23.47-1.414-1.414Zm-22.055 23.47L11.956 21.69l-1.414 1.414L20.969 33.54l1.415-1.413Zm21.99-14.23c2.776 10.086-2.245 20.668-11.793 24.916l.813 1.827c10.45-4.649 15.947-16.233 12.908-27.274l-1.928.53ZM32.581 42.81c-9.556 4.239-20.774.862-26.396-7.954L4.5 35.932c6.154 9.65 18.433 13.346 28.893 8.707l-.811-1.828ZM6.186 34.856C.564 26.05 2.226 14.45 10.102 7.574L8.787 6.067C.165 13.593-1.654 26.292 4.5 35.933l1.686-1.077ZM10.1 7.574c7.877-6.867 19.6-6.953 27.571-.192l1.294-1.525c-8.726-7.402-21.558-7.306-30.179.21l1.314 1.507Z"
    />
  </S.Svg>
);

