import { ExitIcon } from '@vfit/shared-icons';
import { Background, Container, ModalContent, Padder } from './fullScreenModal.style';
import { IFullModalProps } from './fullScreenModal.models';

const FullScreenModal = (props: IFullModalProps) => {
  const { children, show, onCloseModal, showIcon = true } = props;

  if (!show) {
    return null;
  }

  return (
    <Background>
      <Container>
        {showIcon && (
          <div>
            <span
              role="button"
              tabIndex={0}
              className="closing-icon"
              onClick={onCloseModal}
              onKeyDown={onCloseModal}
            >
              <ExitIcon />
            </span>
          </div>
        )}
        <ModalContent className="modalContent">
          <Padder>{children}</Padder>
        </ModalContent>
      </Container>
    </Background>
  );
};

export default FullScreenModal;
