import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const DotLs: React.FC<ISvgComponent> = ({ color, height, width, shrinkable, ...rest }) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 17.033 17.033"
    shrinkable={shrinkable}
    {...rest}
  >
    <circle cx="8.517" cy="8.517" r="2.4" fill={color} />
  </S.Svg>
);

