import { breakpoints, colors } from '@vfit/shared/themes';
import styled from 'styled-components';

export const Background = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: #000000c4;
`;

export const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background-color: ${colors.$ffffff};
  overflow: hidden;

  .closing-icon {
    position: absolute;
    right: 0;
    margin-top: 21.5px;
    margin-right: 21.5px;

    &:hover {
      cursor: pointer;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    .closing-icon {
      margin-top: 48px;
      margin-right: 38px;
    }

    svg {
      width: 32px;
      height: 32px;
    }
  }
`;

export const ModalContent = styled.div`
  height: 100vh;
  overflow: auto;
  margin: 0;
`;

export const Padder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px;
  height: 100%;

  @media (min-width: ${breakpoints.tablet}) {
    padding: 80px;
  }
`;
