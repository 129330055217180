import React, {useEffect} from 'react';
import Script from "next/script";
import {useRouter} from "next/router";

const ScrollRestoration = () => {
  const router = useRouter();

  useEffect(() => {
    router.beforePopState(state => {
      state.options.scroll = false;// eslint-disable-line no-param-reassign
      return true;
    });
  }, []);

  return (
    <Script id="scroll-restoration">
      {`window.history.scrollRestoration = "manual"`}
    </Script>
  )
};

export default ScrollRestoration;
