import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const EyeSlash: React.FC<ISvgComponent> = ({
  color = '#0D0D0D',
  height,
  width,
  shrinkable,
  ...rest
}) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 20 20"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      d="M16.6667 3.35938L3.33341 16.6927M10.0001 15.4167C4.63653 15.4167 2.21153 10.4167 2.11882 10.1865C2.07302 10.0513 2.07302 9.90491 2.11882 9.76979C2.21153 9.58333 4.63653 4.58333 10.0001 4.58333C15.3636 4.58333 17.7886 9.58333 17.8813 9.81354C17.9271 9.94866 17.9271 10.0951 17.8813 10.2302C17.7886 10.4167 15.3636 15.4167 10.0001 15.4167ZM10.0001 12.9167C9.23094 12.9134 8.49453 12.6051 7.95241 12.0595C7.4103 11.5138 7.10677 10.7754 7.10843 10.0063C7.11008 9.23715 7.41679 8.50007 7.96124 7.95678C8.5057 7.41349 9.24344 7.10838 10.0126 7.10838C10.7817 7.10838 11.5195 7.41349 12.0639 7.95678C12.6084 8.50007 12.9151 9.23715 12.9167 10.0063C12.9184 10.7754 12.6149 11.5138 12.0728 12.0595C11.5306 12.6051 10.7942 12.9134 10.0251 12.9167H10.0001Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </S.Svg>
);
