import { fontSizes, lineHeights } from '../sizes';
import { mathRound } from './mathRound';

/**
 * Convert pixel to rem as a string with suffix
 * @param px number(pixel) or string
 * @param rootFontSize (optional, default = 16)
 * @returns string
 *
 * example: _pxToRem(16) -> '1rem'
 */
const _pxToRem = (px: number | string, rootFontSize = 16) =>
  typeof px === 'number' ? `${px ? `${mathRound(px / rootFontSize)}rem` : 0}` : px;

/**
 * Convert any values of pixel to rem as a string with suffix
 * assuming root font size as 16px
 * @param px one or more number(pixel) or string
 * @returns string
 *
 * example: pxToRem(16, 'auto', 22) -> '1rem auto 1.375rem'
 */
export const pxToRem = (...px: (number | string)[]) => px.map((p) => _pxToRem(p)).join(' ');

/**
 * Convert any values of pixel to rem as a string with suffix
 * assuming root font size as 16px
 * @param px array of numbers(pixel) or string
 * @returns string
 *
 * example: pxToRemJoin([16, 22], ',') -> '1rem, 1.375rem'
 */
export const pxToRemJoin = (px: (number | string)[], separator: string) =>
  px.map((p) => _pxToRem(p)).join(separator);

/**
 * Normalize a numeric value to the closer value allowed in an object of numbers
 * @param value
 * @param obj
 * @returns
 */
const normalize = (value: number, obj: { [key: string]: number }) => {
  if (value === 0) {
    return 0;
  }

  const sortedValues = [...Object.values(obj)].sort((a, b) => a - b);

  if (value <= sortedValues[0]) {
    return sortedValues[0];
  }

  if (value >= sortedValues[sortedValues.length - 1]) {
    return sortedValues[sortedValues.length - 1];
  }

  let index = 0;
  while (index < sortedValues.length - 1 && value > sortedValues[index]) {
    if (value > sortedValues[index + 1]) {
      // eslint-disable-next-line no-plusplus
      index++;
      // eslint-disable-next-line no-continue
      continue;
    }
    break;
  }

  return mathRound(value - sortedValues[index]) < mathRound(sortedValues[index + 1] - value)
    ? sortedValues[index]
    : sortedValues[index + 1];
};

/**
 * Normalize a font size (in pixel) to the closer value
 * allowed in the theme's fontSizes
 * @param fontSize (pixel)
 * @returns
 * Example: normalizeFontSize(23) -> 24
 */
export const normalizeFontSize = (fontSize: number) => normalize(fontSize, fontSizes);

/**
 * Normalize a line height to the closer value
 * allowed in the theme's lineHeights
 * @param lineHeight (ratio)
 * @returns
 * Example: normalizeLineHeight(1.23) -> 1.25
 */
export const normalizeLineHeight = (lineHeight: number) => normalize(lineHeight, lineHeights);

/**
 * Compute and normalize a font-size (to rem)
 * @param fontSize (pixel)
 * @returns
 *
 * example: pxToFontSize(16.7) -> '1rem'
 */
export const pxToFontSize = (fontSize: number) => pxToRem(normalizeFontSize(fontSize));

/**
 * Compute and normalize a font size (to rem) and create the CSS rule in rem
 * @param fontSize (pixel)
 * @returns
 * Example: pxToCssFontSize(16) -> 'font-size: 1rem;'
 */
export const pxToCssFontSize = (fontSize: number) => `font-size: ${pxToFontSize(fontSize)};`;

/**
 * Compute line-height (ratio)
 * @param fontSize (pixel)
 * @param lineHeight (pixel)
 * @returns
 *
 * example: _pxToLineHeight(16, 22) -> 1.375
 */
const _pxToLineHeight = (fontSize: number, lineHeight: number) => mathRound(lineHeight / fontSize);

/**
 * Compute and normalize line-height (ratio)
 * @param fontSize (pixel)
 * @param lineHeight (pixel)
 * @returns
 *
 * example: pxToLineHeight(16, 22) -> 1.4
 */
export const pxToLineHeight = (fontSize: number, lineHeight: number) =>
  normalizeLineHeight(_pxToLineHeight(normalize(fontSize, fontSizes), lineHeight));

/**
 * Compute and normalize line-height (rem)
 * @param fontSize (pixel)
 * @param lineHeight (pixel)
 * @returns
 *
 * example: pxToLineHeightRem(18, 22) -> 1.4rem
 */
export const pxToLineHeightRem = (fontSize: number, lineHeight: number) =>
  `${pxToRem(mathRound(fontSize * pxToLineHeight(fontSize, lineHeight)))}`;

/**
 * Convert a line height from pixel to ratio,
 * then normalize it and create the CSS rule
 * @param fontSize (pixel)
 * @param lineHeight (pixel)
 * @returns
 * Example: pxToCssLineHeight(16, 22) -> 'line-height: 1.4;'
 */
export const pxToCssLineHeight = (fontSize: number, lineHeight: number) =>
  `line-height: ${pxToLineHeight(fontSize, lineHeight)};`;

/**
 * Compute CSS rules for font-size (rem) and line-height (ratio)
 * @param fontSize (pixel)
 * @param lineHeight (pixel)
 * @returns
 *
 * example: pxToCssFont(16, 22) -> `font-size: 1rem; line-height: 1.4;`
 */
export const pxToCssFont = (fontSize: number, lineHeight: number) => `
  ${pxToCssFontSize(fontSize)}
  ${pxToCssLineHeight(fontSize, lineHeight)}
`;

