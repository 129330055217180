import { colors } from '@vfit/shared/themes';
import styled from 'styled-components';
import { ICCSProps } from './loadingSpinner.models';

export const SpinnerContainer = styled.div<ICCSProps>`
  display: flex;
  z-index: 99;
  ${({ isChild }) => (!isChild ? 'position: absolute; top: 50%; left: 50%' : 'margin: 16px 0')};
  justify-content: center;
  align-items: center;

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }

    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }

  & svg {
    animation: rotate 2s linear infinite;
    z-index: 2;
    margin: -25px 0 0 -25px;
    width: 50px;
    height: 50px;

    & circle {
      stroke: ${colors.$e60000};
      stroke-linecap: round;
      fill: trasparent;
      animation: dash 1.5s ease-in-out infinite;
    }
  }
`;

