import { API } from '@vfit/shared/data-access';
import { INextError, LoggerInstance, serverlessClient } from '@vfit/shared/data-access';
import { useMutation } from 'react-query';
import { IVideoRecognitionResponse } from './videoRecognition.models';

/**
 * This method is used to aws lambda Video Recognition api service
 * and retrieve the sim session.
 * This in used in the VRLanding component
 * Method: POST
 *
 * @returns
 */
const videoRecognitionService = (simSerial: string) =>
  serverlessClient.post(API.VIDEO_RECOGNITION, {
    sim_serial: simSerial,
  }) as Promise<IVideoRecognitionResponse>;

/**
 * This method is used to instantiate a custom useMutation hook to handle the videoRecognitionService
 * @returns
 */
export const useVideoRecognitionMutation = () =>
  useMutation(['videoRecognition'], (simSerial: string) => videoRecognitionService(simSerial), {
    onSuccess: (data: IVideoRecognitionResponse) => {
      localStorage.setItem('videoRecognition', JSON.stringify(data));
    },
    onError: (error: INextError) => {
      LoggerInstance.error(`ERROR on videoRecognition: `, error);
      localStorage.setItem(`videoRecognition`, JSON.stringify(error));
    },
  });
