import type { SVGProps } from 'react';
import { memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      focusable="false"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      {...props}
    >
      <title>{'Vodafone logo'}</title>
      <g data-name="<Group>">
        <circle data-name="<Path>" cx={141.73} cy={141.73} r={141.73} fill="#e60000" />
        <path
          data-name="<Path>"
          d="M142.83 220.77c-38.94.13-79.46-33.11-79.63-86.48-.09-35.29 18.95-69.26 43.29-89.46 23.74-19.66 56.26-32.27 85.76-32.37 3.8 0 7.77.3 10.2 1.13-25.79 5.35-46.32 29.35-46.23 56.58a14.78 14.78 0 0 0 .17 2.31c43.16 10.51 62.75 36.55 62.87 72.58s-28.32 75.55-76.43 75.71z"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default memo<SVGProps<SVGSVGElement>>(SvgComponent);
