import {
  breakpoints,
  colors,
  fonts,
  pxToCssFont,
  pxToCssFontSize,
  pxToRem,
} from '@vfit/shared/themes';
import styled from 'styled-components';

export const StyledInputContainer = styled.div<{ isColMiddle?: boolean; isHidden?: boolean }>`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
  padding: 20px 16px;
  flex: 100%;
  @media (min-width: ${breakpoints.tablet}) {
    flex: ${({ isColMiddle }) => (isColMiddle ? '50%' : '100%')};
  }
`;

export const StyledFormTextContainer = styled.div<{ textAlign?: string }>`
  padding: 0 20px 0 16px;
  text-align: ${({ textAlign }) => textAlign || 'left'};
  h2,
  h4 {
    font-weight: inherit;
  }
`;

export const StyledFieldset = styled.div<{
  isError?: boolean;
  isDisabled?: boolean;
}>`
  width: 100%;
  position: relative;
  margin: 0;
  padding: 18px 16px 18px 20px;
  display: flex;
  transition: border 2s;
  border-radius: 5.008px;
  background: transparent;
  ${({ isError, isDisabled }) => {
    if (isError) {
      return `border: 1px solid ${colors.$e60000}`;
    }
    return isDisabled ? `border: 1px solid #BEBEBE` : `border: 1px solid #BEBEBE`;
  }};

  .wrapper {
    display: inline-flex;
    width: 100%;
    margin: 0;
    padding: 0;
    height: 22px;
  }

  .wrapper-icon {
    display: flex;

    @media (min-width: ${breakpoints.mobile}) {
      align-self: center;
    }

    @media (min-width: ${breakpoints.tablet}) {
      align-self: center;
    }
  }

  .icon {
    position: relative;
    margin: auto;
    display: flex;
    flex-direction: column;
    appearance: none;
    background: none;
    border: none;
    font-family: inherit;
    font-size: inherit;
    padding: 0;

    &:hover {
      cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
    }
  }

  ${(props) => (props.isDisabled ? '&:hover {cursor: not-allowed}' : '')}
`;

export const StyledCheckboxContainer = styled.div<{ isColMiddle?: boolean; isHidden?: boolean }>`
  padding: 20px;
  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
  flex: 100%;
  @media (min-width: ${breakpoints.tablet}) {
    flex: ${({ isColMiddle }) => (isColMiddle ? '50%' : '100%')};
  }
`;

export const StyledInput = styled.input`
  background-color: transparent;
  margin: auto;
  padding: 0;
  width: 100%;
  height: 22px;
  outline: 0;
  border: 0;
  text-overflow: ellipsis;
  font-family: ${fonts.regular};
  ${pxToCssFontSize(18)}
  text-align: left;

  &:disabled {
    background-color: transparent;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${colors.$262626};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin: auto;
  }
`;

export const StyledDropdown = styled.select`
  background-color: transparent;
  margin: auto;
  padding: 0;
  width: 100%;
  height: 22px;
  outline: 0;
  border: 0;
  text-overflow: ellipsis;
  font-family: ${fonts.regular};
  ${pxToCssFontSize(18)}
  text-align: left;

  &:disabled {
    background-color: transparent;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${colors.$262626};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin: auto;
  }
`;

export const StyledCheckbox = styled.input`
  width: ${pxToRem(20)};
  height: ${pxToRem(20)};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
  appearance: none;
  border-radius: ${pxToRem(3)};
  border: ${pxToRem(1)} solid ${colors.$bebebe};
  margin-right: ${pxToRem(10)};
  padding: 0;
  transition: background-color 0.3s ease;

  &::before {
    display: block;
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 12 9'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M11 1 4.158 8 1 4.77'/%3E%3C/svg%3E");
    width: ${pxToRem(12)};
    height: ${pxToRem(9)};
  }

  &:checked {
    background-color: ${colors.$00697c};
    border-color: ${colors.$00697c};
  }
`;

export const StyledCheckboxLabel = styled.label`
  ${pxToCssFont(16, 20)}
  display: flex;
  flex-direction: row;
  cursor: pointer;
  text-align: left;
`;

export const StyledLegend = styled.label`
  color: ${colors.$7e7e7e};
  height: 18px;
  padding: 0 8px;
  margin-left: -5.008px;
  background: white;
  z-index: -2;
  ${pxToCssFontSize(18)}
  font-family: ${fonts.regular};
  text-align: left;
  position: absolute;
  transition: 0.2s;

  ${StyledInput}:not(:placeholder-shown) ~ &,
  ${StyledInput}:focus ~ & {
    top: -10px;
    ${pxToCssFont(14, 18)}
    z-index: 2;
    color: ${colors.$262626};
  }
`;

export const StyledSection = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const StyledContainerButton = styled.div`
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 400px;
`;

export const StyledRadioGroup = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  row-gap: 20px;
  cursor: pointer;
  border: 2px solid ${colors.$ffffff};

  section {
    display: flex;
  }

  .label {
    ${pxToCssFont(18, 20)};
  }

  .options {
    margin-left: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    span {
      vertical-align: top;
      margin-left: 5px;
    }
  }

  input[type='radio'] {
    appearance: none;
    height: 25px;
    width: 25px;
    border: 1px solid ${colors.$bebebe};
    border-radius: 50%;
    outline: none;
    cursor: pointer;
  }

  input[type='radio']::before {
    content: '';
    display: block;
    width: 100%;
    height: 75%;
    border-color: ${colors.$00697c};
    border-radius: 50%;
  }

  input[type='radio']:checked::before {
    background: ${colors.$00697c};
    border-color: ${colors.$00697c};
  }

  input[type='radio']:checked {
    border-color: ${colors.$00697c};
    align-items: center;
    display: flex;
  }
`;
