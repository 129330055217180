import { Gender, ICountry, IProvince } from './fiscal-code.models';
import foreignCountries from './foreign-countries.json';
import italianMunicipalities from './italian-municipalities.json';
import italianProvinces from './italian-provinces.json';
import { dateCode, getCheckCode, getValidDate, nameCode, surnameCode } from './fiscal-code.utils';

const getForeignCountries = (): ICountry[] => foreignCountries;
// https://github.com/matteocontrini/comuni-json/blob/master/comuni.json
const getItalianMunicipalities = (): ICountry[] => italianMunicipalities;
const getItalianProvinces = (): IProvince[] => italianProvinces;

const calculateFiscalCode = (
  name: string,
  surname: string,
  gender: Gender,
  dayOfBirth: number,
  monthOfBirth: number,
  yearOfBirth: number,
  country: ICountry
): string => {
  const { code } = country;
  const surnameStr = surnameCode(surname).substr(0, 3);
  const nameStr = nameCode(name).substr(0, 3);
  try {
    const birthday = getValidDate(dayOfBirth, monthOfBirth, yearOfBirth);
    const dateStr = dateCode(
      `0${birthday.getDate()}`.slice(-2).toString(),
      birthday.getMonth() + 1,
      birthday.getFullYear(),
      gender
    );
    const cf = `${surnameStr}${nameStr}${dateStr}${code}`;
    const checkCode = getCheckCode(cf);
    return `${cf}${checkCode}`;
  } catch (error) {
    return '';
  }
};

export { getForeignCountries, getItalianMunicipalities, getItalianProvinces, calculateFiscalCode };
