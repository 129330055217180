import { useState, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';
import { IPortalProps } from './portal.models';

function createWrapper(wrapperId: string): HTMLDivElement {
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute('id', wrapperId);
  return wrapperElement;
}

function appendToBody(wrapperElement: HTMLDivElement) {
  document.body.appendChild(wrapperElement);
}

function ReactPortal({
  children,
  wrapperId = 'portal-wrapper',
  customCreateWrapper,
  customAppendElement,
}: IPortalProps) {
  const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    let element: HTMLDivElement = document.getElementById(wrapperId) as HTMLDivElement;
    let systemCreated = false;
    // if element is not found with wrapperId or wrapperId is not provided,
    // creat e and append to body
    if (!element) {
      systemCreated = true;
      element = customCreateWrapper ? customCreateWrapper() : createWrapper(wrapperId);
      if (customAppendElement) {
        customAppendElement(element);
      } else {
        appendToBody(element);
      }
    }
    setWrapperElement(element);

    return () => {
      // delete the programatically created element
      if (systemCreated && element?.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, [wrapperId]);

  // wrapp erElement state will be null on very first render.
  if (wrapperElement === null) return null;

  return createPortal(<>{children}</>, wrapperElement);
}

export default ReactPortal;
