import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const MagnifyingGlassMinus: React.FC<ISvgComponent> = ({
  color,
  height,
  width,
  shrinkable,
  ...rest
}) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height || '21'}
    width={width || '20'}
    viewBox="0 0 20 21"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      d="M14.4492 14.4805L21.5 21.5273M6 9.51562H13M16.5 9.5C16.5 13.3672 13.3672 16.5 9.5 16.5C5.63281 16.5 2.5 13.3672 2.5 9.5C2.5 5.63281 5.63281 2.5 9.5 2.5C13.3672 2.5 16.5 5.63281 16.5 9.5Z"
      stroke={color || '#0D0D0D'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </S.Svg>
);
