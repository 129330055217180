import { Loader } from '@googlemaps/js-api-loader';
import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;

export interface ILoaderInterface {
  apiKey: string;
  version: string;
  libraries: any;
  language: string;
  region: string;
}

/** MAPS SERVICE
 *  It creates an instance of Loader from Google Maps Javascript API server
 */
export const loaderObj: ILoaderInterface = {
  apiKey: process.env?.['NX_GOOGLE_MAPS_API_KEY'] as string,
  version: 'weekly',
  libraries: ['places'],
  language: 'it',
  region: 'it',
};

const stringFields = {
  city: 'administrative_area_level_3',
  postalCode: 'postal_code',
  stateOrProvince: 'administrative_area_level_2',
  street: 'route',
  streetNumber: 'street_number',
  region: 'administrative_area_level_1',
};

const addressFilterFunction = (
  detailsAddress: GeocoderAddressComponent[],
  selector: 'city' | 'postalCode' | 'stateOrProvince' | 'street' | 'streetNumber' | 'region'
): string | undefined =>
  // TODO: [refactoring]: prevedere nome piu parlante al posto di x
  detailsAddress.find((x: GeocoderAddressComponent) =>
    x.types.includes(stringFields[`${selector}`])
  )?.short_name || '';

/** RETRIEVE ADDRESS FIELD
 * @description Helper function that receives GeocoderResponse object to be destructured
 * in an address object
 * @param -
 * - address: (google.maps.GeocoderResponse) --> input to be destructured
 * @returns an object in which you have all address object fields
 * @author Andrea
 */
const retireveAddressFields = (address: google.maps.GeocoderResponse) => {
  const detailsAddress = address.results[0].address_components;
  // LoggerInstance.debug(address.results[0].geometry.location.lat())
  // LoggerInstance.debug(address.results[0].geometry.location.lng())

  // TODO --> MAPPING WITH FUNCTION
  const placeDetails = {
    city: addressFilterFunction(detailsAddress, 'city'),
    postalCode: addressFilterFunction(detailsAddress, 'postalCode'),
    stateOrProvince: addressFilterFunction(detailsAddress, 'stateOrProvince'),
    street: addressFilterFunction(detailsAddress, 'street'),
    streetNumber: addressFilterFunction(detailsAddress, 'streetNumber'),
    region: addressFilterFunction(detailsAddress, 'region'),
    latitude: address.results[0].geometry.location.lat().toString(),
    longitude: address.results[0].geometry.location.lng().toString(),
    placeId: address.results[0].place_id,
  };
  return placeDetails;
};

/** CALL-AUTOCOMPLETE
 * @description It calls autocomplete service to use getPlacePredictions from an
 * input string.
 * @param -
 * - input: (string) --> argument to be used in getPlacePredictions method
 * @returns null array if no predictions available, an array of predictions otherwise
 * @author Andrea
 */
export const callAutocomplete = async (input: string) => {
  const mapsLoader = new Loader(loaderObj);
  const autocompleteService = mapsLoader
    .load()
    .then((google) => google && new google.maps.places.AutocompleteService());

  /** AUTOCOMPLETE SERVICE
   * It creates an AutocompleteService instance from a promise using Map Service
   */
  const service = await autocompleteService;
  const response = await service.getPlacePredictions({
    input,
    componentRestrictions: { country: 'it' },
    types: ['address'],
  });

  return response.predictions;
};

// TODO --> POSSIBILITY TO PUT TOGETHER

/** USE-PLACEID
 * @description It calls geocoder service to use geocode method from an
 * input placeId.
 * It uses retrieveAddressField() helper function to destructure the response object
 * @param placeId
 * - placeId: (string) --> argument to be used in geocode method
 * @returns an object in which you have all address object fields
 * @author Andrea
 */
export const getPlaceId = async (placeId: string) => {
  const mapsLoader = new Loader(loaderObj);
  const google = await mapsLoader.load();
  const geocoder = google && new google.maps.Geocoder();
  const details = await geocoder.geocode({ placeId });
  if (details) {
    return retireveAddressFields(details);
  }
  return null;
};

/** USE-ADDRESSGEOCODER
 * @description It calls geocoder service to use geocode method from an
 * input text.
 * It uses retrieveAddressField() helper function to destructure the response object
 * @param -
 * - text: (string) --> argument to be used in geocode method
 * @returns an object in which you have all address object fields
 * @author Andrea
 */
export const useAddressGeocoder = async (text: string | undefined) => {
  if (text === undefined) {
    return undefined;
  }
  const mapsLoader = new Loader(loaderObj);
  const google = await mapsLoader.load();
  const geocoder = google && new google.maps.Geocoder();

  const details = await geocoder.geocode({ address: text });

  if (details) {
    return retireveAddressFields(details);
  }
  return null;
};

