import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const Menu: React.FC<ISvgComponent> = ({ color, height, width, shrinkable, ...rest }) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 26.333 26.333"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeMiterlimit="10"
      d="M25.833 21.838H.5m25.333-8.718H.5m25.333-8.625H.5"
    />
  </S.Svg>
);

