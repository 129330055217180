import { css } from 'styled-components';
import { cssfonts } from '../cssfonts';

export const footnote2 = {
  regular: css`
    ${cssfonts.regular}
    font-size: 0.75rem; /* 12px */
    line-height: 1.35; /* 12/16 */
  `,
} as const;

