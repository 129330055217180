import { PropsWithChildren, useEffect } from 'react';
import { persistQueryClient, } from 'react-query/persistQueryClient-experimental';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
import { QueryClient, QueryClientProvider, setLogger } from 'react-query';
import { handleError, handleSuccess } from '@vfit/shared/data-access';
import { persistWithIndexedDB } from "./persistWithIndexedDB";



const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      suspense: false,
      staleTime: 60 * 60 * 1000,
      cacheTime: 60 * 60 * 1000 * 24,
      onError: (error) => {
        if (typeof error == 'string') {
          if (error?.toLowerCase() !== 'missing queryfn') {
            handleError(error);
          }
        } else {
          handleError(error);
        }
      },
      onSuccess: (data) => {
        handleSuccess(data);
      },

    },
    mutations: {
      retry: 0,
    },
  },
});

setLogger({
  log: console.log,
  warn: console.log,
  error: console.log,
});

export interface AsyncProviderProps {
  card?: boolean;
}

export const QueryProvider = ({
  children,
  enablePersistor,
  enablePersistorIndexDB
}: PropsWithChildren<{ enablePersistor?: boolean, enablePersistorIndexDB?: boolean }>) => {
  useEffect(() => {
    if (enablePersistor) {
      // the persister
      const localStoragePersistor = createWebStoragePersistor({ storage: window.localStorage });
      persistQueryClient({
        queryClient,
        persistor: localStoragePersistor,
      });
    }
    if (enablePersistorIndexDB){
      persistWithIndexedDB(queryClient)

    }
  }, []);

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

