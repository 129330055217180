import { keyframes } from 'styled-components';

export const stretchOutUp = keyframes`
  from {
    opacity: 1;
    transform: scale3d(1, 1, 1);
    transform-origin: top center;
  }
  
  to {
    opacity: 0;
    transform: scale3d(1, 0, 1);
  }
`;

