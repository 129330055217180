import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const CircleStatusError: React.FC<ISvgComponent> = ({
  color = '#EB6100',
  height,
  width,
  shrinkable,
  ...rest
}) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 20 20"
    shrinkable={shrinkable}
    {...rest}
  >
    <circle cx="10" cy="10" r="10" fill={color} />
    <path
      d="M 14.0002,5.9998 6,14"
      stroke="white"
      strokeWidth="1.065"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M 5.9998,5.998 14,14"
      stroke="white"
      strokeWidth="1.065"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </S.Svg>
);
