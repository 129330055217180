import styled, { css } from 'styled-components';
import { cssprops } from '@vfit/shared/themes';
import * as S from '../../button.style';
import { IButtonAStyledComponent } from './buttonA.models';

const _disabled = css`
  &,
  &:hover,
  &:active {
    ${S.disabled}
  }
`;

export const A = styled.a<IButtonAStyledComponent>`
  text-decoration: none;
  ${S.common}
  ${({ disabled }) => (disabled ? _disabled : '')}
  ${cssprops.styled}
`;

