import { ExitIcon } from '@vfit/shared-icons';
import { useDeviceType } from '@vfit/shared/hooks';
import { IModalProps } from '../../modal.models';
import * as S from './modalFullContainer.style';
import { IconButton, OverlayGlobalStyle } from './modalFullContainer.style';

export const ModalFullContainer: React.FC<IModalProps> = ({
  children,
  onCloseModal,
  isDetailsModal,
  withBorderRadius,
  height,
  tabletMode,
  customId,
  isDisabledClose,
  enableCloseClickOutside,
  closeColor,
}) => {
  const { isMobile } = useDeviceType();
  return (
    <>
      {isMobile && <OverlayGlobalStyle />}
      <S.Background
        onClick={() => {
          if (enableCloseClickOutside) onCloseModal?.();
        }}
      >
        <S.Container
          id={customId}
          isDetailsModal={isDetailsModal}
          withBorderRadius={withBorderRadius || false}
          tabletMode={tabletMode}
          maxHeight={height}
          onClick={(e) => {
            if (enableCloseClickOutside) e.stopPropagation();
          }}
        >
          {!isDisabledClose && (
            <IconButton id={`${customId}-closing-icon-container`} color={closeColor}>
              <span
                role="button"
                tabIndex={0}
                className="closing-icon"
                onClick={() => onCloseModal?.()}
                onKeyDown={() => onCloseModal?.()}
              >
                <ExitIcon />
              </span>
            </IconButton>
          )}
          <S.ModalContent className="modalContent" height={height}>
            {children}
          </S.ModalContent>
        </S.Container>
      </S.Background>
    </>
  );
};
