import {IFrameDetail} from "./interfaces";

export class IFrameService {
  private customIFrameEvent: CustomEvent | undefined;

  /**
   * Handle IFrame
   */
  public handleIFrame(
    iFrameDetail?: IFrameDetail
  ): void {
    this.customIFrameEvent = new CustomEvent('iframeServiceNotificationEvent', {detail: iFrameDetail});
    document.dispatchEvent(this.customIFrameEvent);
  }
}



