import { SVGProps, memo } from 'react';

const SpinnerIcon = (props: SVGProps<SVGAElement>) => {
  return (
    <svg viewBox="0 0 50 50">
      <circle cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
    </svg>
  );
};

export default memo<SVGProps<SVGAElement>>(SpinnerIcon);
