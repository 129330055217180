import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const ArrowRightFw: React.FC<ISvgComponent> = ({
  color,
  height,
  width,
  shrinkable,
  ...rest
}) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 18 17"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      fill={color}
      d="M0.167264 7.732H15.8393L14.5673 8.668L6.69526 0.771999H9.81526L17.8553 8.86L9.81526 16.948H6.67127L14.5673 9.052L15.8393 9.988H0.167264V7.732Z"
    />
  </S.Svg>
);
