import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const Eye: React.FC<ISvgComponent> = ({
  color = '#0D0D0D',
  height,
  width,
  shrinkable,
  ...rest
}) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 20 20"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      d="M10.0001 15.4163C4.63653 15.4163 2.21153 10.4163 2.11882 10.1861C2.07302 10.051 2.07302 9.90458 2.11882 9.76947C2.21153 9.58301 4.63653 4.58301 10.0001 4.58301C15.3636 4.58301 17.7886 9.58301 17.8813 9.81322C17.9271 9.94833 17.9271 10.0948 17.8813 10.2299C17.7886 10.4163 15.3636 15.4163 10.0001 15.4163Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0001 12.9164C9.23094 12.9131 8.49453 12.6048 7.95241 12.0591C7.4103 11.5135 7.10677 10.7751 7.10843 10.006C7.11008 9.23682 7.41679 8.49974 7.96124 7.95645C8.5057 7.41317 9.24344 7.10805 10.0126 7.10805C10.7817 7.10805 11.5195 7.41317 12.0639 7.95645C12.6084 8.49974 12.9151 9.23682 12.9167 10.006C12.9184 10.7751 12.6149 11.5135 12.0728 12.0591C11.5306 12.6048 10.7942 12.9131 10.0251 12.9164H10.0001Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </S.Svg>
);
