import { css } from 'styled-components';
import { colors } from '../../colors';
import { cssfonts } from '../cssfonts';

const common = css`
  font-size: 1.125rem; /* 18px */
  line-height: 1.35; /* 18/24 */
`;

export const body1 = {
  extrabold: css`
    ${cssfonts.exbold}
    ${common}
  `,
  bold: css`
    ${cssfonts.bold}
    ${common}
  `,
  link: css`
    ${cssfonts.bold}
    ${common}
    text-decoration: underline;

    &:hover {
      ${colors.$e60000}
    }
  `,
  regular: css`
    ${cssfonts.regular}
    ${common}
  `,
} as const;

