import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const Download: React.FC<ISvgComponent> = ({ color, height, width, shrinkable, ...rest }) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 26.333 26.333"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      d="M21 16V19.3333C21 19.7754 20.7893 20.1993 20.4142 20.5118C20.0391 20.8244 19.5304 21 19 21H5C4.46957 21 3.96086 20.8244 3.58579 20.5118C3.21071 20.1993 3 19.7754 3 19.3333V16"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7 10L11.6464 14.6464C11.8417 14.8417 12.1583 14.8417 12.3536 14.6464L17 10"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 14V3"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </S.Svg>
);

