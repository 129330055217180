import { IGradientImage } from '@vfit/shared/models';
import styled from 'styled-components';

export const GradientLambdaImageContainer = styled.div<{
  absolute?: boolean;
  gradient?: IGradientImage;
}>`
  height: 100%;

  ::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    background: ${({ gradient }) => gradient};
  }
`;

export const GradientDefaultImageContainer = styled.div<{
  absolute?: boolean;
  gradient?: IGradientImage;
}>`
  div {
    position: inherit !important;

    ::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: inline-block;
      background: ${({ gradient }) => gradient};
    }
  }
`;
