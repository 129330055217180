import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const PlusBtn: React.FC<ISvgComponent> = ({ color, height, width, shrinkable, ...rest }) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 15.75 15.75"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
      d="M14.25 7.875H1.5M7.875 1.5v12.75"
    />
  </S.Svg>
);

