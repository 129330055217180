import Link from 'next/link';
import { Svg } from '../../../../../Svg';
import { NodeWithSvg } from '../../../NodeWithSvg';
import { useButton } from '../../button.hook';
import { IButtonA } from './buttonA.models';
import * as S from './buttonA.style';

export const A: React.FC<IButtonA> = ({
  as,
  children,
  disableAppMobileOpenInBrowser,
  disabled,
  href: _href,
  locale,
  onClick: _onClick,
  onMouseEnter: _onMouseEnter,
  onMouseLeave: _onMouseLeave,
  onTouchStart,
  prefetch,
  replace,
  scroll,
  shallow,
  model = 'regular',
  size,
  svg,
  svgPosition = 'right',
  variant = 'primary',
  name,
  ...rest
}) => {
  const { color, height, href, onClick, onMouseEnter, onMouseLeave } = useButton<HTMLAnchorElement>(
    {
      _href,
      _onClick,
      _onMouseEnter,
      _onMouseLeave,
      disableAppMobileOpenInBrowser,
      disabled,
      size,
      variant,
    }
  );

  const anchorTag = (
    <S.A
      {...rest}
      disabled={!href || disabled}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onTouchStart={onTouchStart}
      model={model}
      size={size}
      variant={variant}
      data-name={name}
    >
      {model === 'regular' ? (
        <NodeWithSvg color={color} height={height} svg={svg} svgPosition={svgPosition}>
          {children}
        </NodeWithSvg>
      ) : (
        <Svg name={svg || 'plusBtn'} color={color} height={height} />
      )}
    </S.A>
  );

  if (!href) {
    return anchorTag;
  }

  return (
    <Link
      as={as}
      href={href}
      locale={locale}
      passHref={!disabled}
      prefetch={prefetch}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      legacyBehavior
    >
      {anchorTag}
    </Link>
  );
};

