import { requireStaticImageAtomComponent } from '../require-media';

export interface ITechText {
  tech: string;
  code: string;
  image?: string;
  variants?: ITechText[];
}

const FTTH_IMAGE = 'logo-ftth.png';
const FTTC_IMAGE = 'logo-fttc.png';
const FWA_IMAGE = 'logo-fwa.png';
const ADSL_IMAGE = 'logo-adsl.png';

// removed code btoa nodejs 14
const ALL_TECHS: ITechText[] = [
  {
    tech: 'FTTH',
    code: '12|13',
    image: FTTH_IMAGE,
  },
  {
    tech: 'FTTC',
    code: '14|15',
    image: FTTC_IMAGE,
  },
  {
    tech: 'ADSL',
    code: '16|17',
    image: ADSL_IMAGE,
  },
  {
    tech: 'FWA',
    code: '18|19',
    image: FWA_IMAGE,
    variants: [
      {
        tech: 'OUTDOOR',
        code: '20',
      },
      {
        tech: 'OUTDOOR 4G',
        code: '21',
      },
      {
        tech: 'OUTDOOR 5G',
        code: '22',
      },
      {
        tech: 'INDOOR',
        code: '23',
      },
      {
        tech: 'INDOOR 4G',
        code: '24',
      },
      {
        tech: 'INDOOR 5G',
        code: '25',
      },
      {
        tech: '4G',
        code: '26',
      },
      {
        tech: '5G',
        code: '27',
      },
    ],
  },
];

const replaceAll = (str: string, search: string, replacement: string) =>
  str.split(search).join(replacement);

const getVariants = (tech: ITechText) => {
  const localVariants = tech?.variants && tech?.variants?.length > 0 ? tech.variants : [];
  const variants =
    localVariants?.length > 1
      ? localVariants?.map((el) => ({
          tech: `${tech.tech} ${el.tech}`,
          code: `${tech.code}|${el.code}`,
        }))
      : localVariants;
  variants.unshift({
    tech: tech.tech,
    code: tech.code,
  });
  return variants?.sort((a, b) => b.tech.length - a.tech.length) || [];
};

const purifyTextWithLogo = (sanitizeText: string): string => {
  let organizedText = sanitizeText;
  const lineSize = 13;

  ALL_TECHS.forEach((tech) => {
    const variants = getVariants(tech);
    variants.forEach((variant) => {
      organizedText = replaceAll(organizedText, variant.tech, `${variant.code}`);
    });
    variants.forEach((variant) => {
      organizedText = replaceAll(
        organizedText,
        variant.code,
        `${variant.tech} <img width='${lineSize}' alt="${variant.tech}" src='${
          requireStaticImageAtomComponent(tech?.image || '').src
        }'/>`
      );
    });
  });

  return organizedText;
};

export { purifyTextWithLogo };
