import { checkWindow } from '@vfit/shared/data-access';
import { InputProps, ISubmitForm } from '@vfit/shared/models';
import { handleEnv } from '@vfit/shared/data-access';
import { IDataForm } from './cmsForm.models';

const getDataFromDAM = async (fileName: string) => {
  const cmsUrl = handleEnv('NEXT_PUBLIC_CMS');
  if (cmsUrl) {
    let baseUrl = new URL(cmsUrl).origin;
    baseUrl = `${baseUrl}/content/dam/webaem/vodafone`;
    const url = `${baseUrl}/${fileName}`;
    if (url) {
      try {
        const dataFromCmsRes = await fetch(url);
        const dataFromCms = await dataFromCmsRes.json();
        return dataFromCms;
      } catch (e) {
        return false;
      }
    }
    return false;
  }
  return false;
};

const appendHiddenQueryParams = (data: IDataForm, bodyServiceInputKey?: string): IDataForm => {
  if (!checkWindow()) return data;
  const queryParamsToRemove = ['app', 'icmp'];
  const params = new URLSearchParams(window.location.search);
  const paramsObj = Array.from(params.keys()).reduce(
    (acc, val) => ({ ...acc, [val]: params.get(val) }),
    {}
  );
  queryParamsToRemove?.forEach((key) => {
    if (paramsObj?.[key]) delete paramsObj[key];
  });
  if (bodyServiceInputKey) {
    return {
      ...data,
      [bodyServiceInputKey]: {
        ...data?.[bodyServiceInputKey],
        ...paramsObj,
      },
    };
  }
  return {
    ...paramsObj,
    ...data,
  };
};

const createSubmitMethod = (data: any, inputs: InputProps[], submitConfig: ISubmitForm) => {
  const {
    method,
    url: urlConfig,
    headers,
    splitHiddenValues,
    bodyServiceInputKey,
    enableQueryParamsInHidden,
  } = submitConfig as ISubmitForm;
  const url = method === 'GET' ? `${urlConfig}${new URLSearchParams(data)}` : urlConfig;

  const apiHeaders = {};
  let isMultipartFormData = false;
  headers?.forEach((headerData) => {
    if (headerData.title === 'content-type' && headerData.value === 'multipart/form-data') {
      isMultipartFormData = true;
    }
    apiHeaders[headerData.title] = headerData.value;
  });

  let formData: FormData;
  let body;
  if (isMultipartFormData) {
    formData = new FormData();
    Object.keys(data)?.forEach((keyData) => {
      formData.append(keyData, data[keyData]);
    });
    body = formData;
  } else {
    let dataToSend = data;
    if (splitHiddenValues && bodyServiceInputKey) {
      const dataToFormat: IDataForm = {
        [bodyServiceInputKey]: {},
      };
      Object.keys(data).forEach((dataKey) => {
        const foundedInput = inputs.find((el) => el.name === dataKey);
        const value = data[dataKey];
        if (value) {
          if (foundedInput?.isHidden) {
            dataToFormat[dataKey] = value;
          } else {
            dataToFormat[bodyServiceInputKey][dataKey] = value;
          }
        }
      });
      dataToSend = dataToFormat;
    }
    body = JSON.stringify(
      enableQueryParamsInHidden
        ? appendHiddenQueryParams(dataToSend, bodyServiceInputKey)
        : dataToSend
    );
  }

  return {
    url,
    method,
    body,
    headers: apiHeaders,
  };
};

export { getDataFromDAM, appendHiddenQueryParams, createSubmitMethod };
