import { Svg } from '../../../Svg';
import { INodeWithSvg } from './nodeWithSvg.models';

export const NodeWithSvg: React.FC<INodeWithSvg> = ({
  children,
  color,
  svg,
  svgPosition = 'left',
  height,
}) => (
  <>
    {svg && svgPosition === 'left' && <Svg name={svg} color={color} height={height} />}
    {children}
    {svg && svgPosition === 'right' && <Svg name={svg} color={color} height={height} />}
  </>
);

