import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const Account: React.FC<ISvgComponent> = ({ color, height, width, shrinkable, ...rest }) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 26.333 26.333"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      fill={color}
      d="M2.903 24.817a.5.5 0 0 0 1 0zm19.527 0a.5.5 0 1 0 1 0zM18.678 7.028h-.5zm-5.512-5.512v-.5zm5.512 0h.5v-.5h-.5zm-14.775 23.3c0-6.297 4.23-11.238 9.263-11.238v-1c-5.75 0-10.263 5.57-10.263 12.239zm9.263-11.238c5.034 0 9.264 4.941 9.264 11.239h1c0-6.67-4.513-12.239-10.264-12.239zm5.012-6.55c0 .991-.294 1.96-.844 2.784l.831.556a6.012 6.012 0 0 0 1.013-3.34zm-.844 2.784a5.011 5.011 0 0 1-2.25 1.846l.383.924a6.011 6.011 0 0 0 2.698-2.214zm-2.25 1.846a5.012 5.012 0 0 1-2.895.285l-.195.981a6.012 6.012 0 0 0 3.473-.342zm-2.895.285a5.012 5.012 0 0 1-2.566-1.371l-.707.707a6.012 6.012 0 0 0 3.078 1.645zm-2.566-1.371A5.012 5.012 0 0 1 8.25 8.006l-.98.195a6.012 6.012 0 0 0 1.645 3.078zM8.25 8.006a5.012 5.012 0 0 1 .285-2.896l-.924-.382A6.012 6.012 0 0 0 7.27 8.2zm.285-2.896a5.012 5.012 0 0 1 1.846-2.249l-.555-.831c-.99.66-1.76 1.599-2.215 2.698zm1.846-2.249a5.012 5.012 0 0 1 2.784-.845v-1a6.012 6.012 0 0 0-3.34 1.014zm2.784-.845h5.512v-1h-5.512zm5.012-.5v5.512h1V1.516z"
    />
  </S.Svg>
);

