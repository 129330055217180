import { IBanner, IStickyBannerHeaderCMS } from '@vfit/shared/models';
import { purify } from '@vfit/shared/themes';

const stickyBannerHeaderOrganize = (cmsConfig: IStickyBannerHeaderCMS): IBanner => ({
  animationDelay: cmsConfig?.stickybanner?.animationDelay || '',
  animationType: cmsConfig?.stickybanner?.animationType || 'slide-down',
  htmlDesktop: purify([cmsConfig?.stickybanner?.htmlDesktop || ''])?.[0] || '',
  htmlTablet: purify([cmsConfig?.stickybanner?.htmlTablet || ''])?.[0] || '',
  htmlMobile: purify([cmsConfig?.stickybanner?.htmlMobile || ''])?.[0] || '',
  useGlobalBanner: cmsConfig?.stickybanner?.useGlobalBanner || false,
});

export { stickyBannerHeaderOrganize };
