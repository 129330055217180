import { ExitIcon, Logo } from '@vfit/shared-icons';
import * as S from './fullScreenModalExtended.style';
import { IFullModalProps } from './fullScreenModalExtended.models';

export const FullScreenModalExtended = ({
  children,
  show,
  onCloseModal,
  showIcon = true,
  showLogo = false,
}: IFullModalProps) => {
  if (!show) {
    return null;
  }

  return (
    <S.Background>
      {showLogo && (
        <S.LogoWrapper>
          <Logo />
        </S.LogoWrapper>
      )}
      <S.Container>
        <S.ModalContent className="modalContent">
          <>
            {showIcon && (
              <S.IconWrap
                role="button"
                tabIndex={0}
                onClick={onCloseModal}
                onKeyDown={onCloseModal}
              >
                <ExitIcon />
              </S.IconWrap>
            )}
            {children}
          </>
        </S.ModalContent>
      </S.Container>
    </S.Background>
  );
};
