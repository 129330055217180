import { StrictUnion, areAnchorPropsOf } from '../../cta.models';
import { Btn, A } from './components';
import { IButtonA } from './components/A/buttonA.models';
import { IButtonBtn } from './components/Btn/buttonBtn.models';

type IButtonProps = StrictUnion<IButtonBtn | IButtonA>;

export const Button: React.FC<IButtonProps> = (props) => {
  if (areAnchorPropsOf<IButtonA>(props)) {
    return <A {...props} />;
  }

  return <Btn {...props} />;
};

