// eslint-disable-next-line @typescript-eslint/ban-ts-comment

import ky, { Hooks, SearchParamsOption } from 'ky';
import type { Options as KyOptions , KyInstance } from 'ky';
import { API } from '@vfit/shared/data-access';
import { ClientType } from './model';
import { options as defaultOptions } from './const';
import { LoggerInstance } from '../logger';

interface Options extends KyOptions {
  notJsonParsing?:boolean
}
export class HttpClient {
  public baseURL: string;

  clientType: ClientType;

  baseHeaders: Options['headers'];

  webConsumerClient: KyInstance;

  prefixUrl: any;

  hooks: Hooks;

  searchParams?: SearchParamsOption | any;

  mode: RequestMode | undefined;

  credentials: RequestCredentials | undefined;

  timeout: number;

  retry: number;

  constructor(clientType: ClientType, customOptions?: any) {
    this.clientType = clientType;
    this.baseURL = defaultOptions[clientType].baseUrl;
    this.baseHeaders = customOptions?.headers || defaultOptions[clientType].headers;
    this.prefixUrl = defaultOptions[clientType].prefixUrl;
    this.hooks = customOptions?.hooks || defaultOptions[clientType].hooks;
    this.mode = defaultOptions[clientType].mode;
    this.credentials = defaultOptions[clientType].credentials;
    this.timeout = defaultOptions[clientType].timeout || 10000;
    this.searchParams = customOptions?.searchParams || defaultOptions[clientType].searchParams;
    this.retry = customOptions?.retry || defaultOptions[clientType].retry;
    this.webConsumerClient = ky.create({
      retry: this.retry,
      ...(this.prefixUrl && { prefixUrl: this.prefixUrl }),
      ...(this.baseHeaders && { headers: this.baseHeaders }),
      ...(this.hooks && { hooks: this.hooks }),
      ...(this.searchParams && { searchParams: this.searchParams }),
      ...(this.mode && { mode: this.mode }),
      ...(this.credentials && { credentials: this.credentials }),
      ...(this.timeout && { timeout: this.timeout }),
    });
  }

  get = async (endpoint: string, options?: Options | any): Promise<any> => {
    const { notJsonParsing } = options || { notJsonParsing: false };
    const customHooks = options?.hooks || defaultOptions[this.clientType].hooks;
    try {
      LoggerInstance.debug('API chiamata '.concat(endpoint));
      const response = await this.webConsumerClient.get(API[endpoint] || endpoint, {
        ...options,
        ...(customHooks && { hooks: customHooks }),
      });

      if (notJsonParsing) return response;
      return await response.json();
    } catch (error) {
      LoggerInstance.error('error', error);
      throw error;
    }
  };

  post = async (endpoint: string, payload?: BodyInit | any, options?: Options): Promise<any> => {
    const { notJsonParsing } = options || { notJsonParsing: false };
    const customHooks = options?.hooks || defaultOptions[this.clientType].hooks;
    try {
      const response = await this.webConsumerClient.post(API[endpoint] || endpoint, {
        ...options,
        ...(payload && { json: payload }),
        ...(customHooks && { hooks: customHooks }),
      });
      if (notJsonParsing) return response;
      return await response.json();
    } catch (error) {
      LoggerInstance.error('error', error);
      throw error;
    }
  };

  put = async (endpoint: string, payload?: BodyInit | any, options?: Options) => {
    const { notJsonParsing } = options || { notJsonParsing: false };
    try {
      const response = await this.webConsumerClient.put(API[endpoint] || endpoint, {
        ...options,
        ...(payload && { json: payload }),
      });
      if (notJsonParsing) return response;
      return await response.json();
    } catch (error) {
      LoggerInstance.error('error', error);
      throw error;
    }
  };

  delete = async (endpoint: string, payload?: BodyInit | any, options?: Options) => {
    const { notJsonParsing } = options || { notJsonParsing: false };
    try {
      const response = await this.webConsumerClient.delete(API[endpoint] || endpoint, {
        ...options,
        ...(payload && { json: payload }),
      });
      if (notJsonParsing) return response;
      return await response.json();
    } catch (error) {
      LoggerInstance.error('error', error);
      throw error;
    }
  };

  head = async (endpoint: string, options?: Options) => {
    try {
      return await this.webConsumerClient.head(API[endpoint] || endpoint, { ...options }).json();
    } catch (error) {
      throw new Error(`error, /todoImplement error manager: ${error}`);
    }
  };

  patch = async (endpoint: string, payload?: BodyInit | any, options?: Options) => {
    const { notJsonParsing } = options || { notJsonParsing: false };
    try {
      const response = await this.webConsumerClient.patch(API[endpoint] || endpoint, {
        ...options,
        ...(payload && { json: payload }),
      });
      if (notJsonParsing) return response;
      return await response.json();
    } catch (error) {
      LoggerInstance.error('error', error);
      throw error;
    }
  };
}

export const nextClient = new HttpClient('NEXT');
export const cmsClient = new HttpClient('CMS');
export const serverlessClient = new HttpClient('WEBAPI_SERVERLESS');
