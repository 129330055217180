import { css } from 'styled-components';
import { pxToCssFont } from '../../utils';
import { breakpoints } from '../../breakpoints';
import { cssfonts } from '../cssfonts';

const common = css`
  font-size: 1.25rem; /* 20px */
  line-height: 1.4; /* 20/28 */

  @media (min-width: ${breakpoints.desktop}) {
    font-size: 1.375rem; /* 22px */
    line-height: 1.45; /* 22/32 */
  }
`;

export const h6 = {
  extrabold: css`
    ${cssfonts.exbold}
    ${common}
  `,
  bold: css`
    ${cssfonts.bold}
    ${common}
  `,
  regular: css`
    ${cssfonts.regular}
    ${common}
  `,
  light: css`
    ${cssfonts.light}
    ${common}
  `,
} as const;

