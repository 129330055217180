import { useFormContext } from 'react-hook-form';
import { CustomInputProps, Opt } from '@vfit/shared/models';
import { useEffect, useState } from 'react';
import { ErrorMessage } from '../ErrorMessage/errorMessage';
import * as S from '../../cmsForm.style';
import { getDataFromDAM } from '../../cmsForm.utils';

export const CustomSelect = ({
  name,
  label,
  options,
  isColMiddle,
  isHidden,
  isDisabled,
  isPendingRequest,
  urlData,
  ...props
}: CustomInputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const [remoteOptions, setRemoteOptions] = useState<Opt[]>([]);
  const error = errors[name]?.message as string | undefined;
  const id = `${name}-${props.type}-${label}`;

  const checkRemoteOptions = async () => {
    if (!urlData) return;
    const dataFromDam = await getDataFromDAM(urlData);
    setRemoteOptions(dataFromDam || []);
  };

  useEffect(() => {
    checkRemoteOptions();
  }, []);

  const renderOptions = () => {
    const optionMap = urlData ? remoteOptions : options;
    return optionMap?.map(({ description, value }) => (
      <option key={value} value={value}>
        {description}
      </option>
    ));
  };

  return (
    <S.StyledCheckboxContainer isColMiddle={isColMiddle} isHidden={isHidden}>
      <S.StyledFieldset isError={!!error}>
        <S.StyledDropdown
          {...register(name)}
          {...props}
          disabled={isDisabled || isPendingRequest}
          id={id}
        >
          <option value="">{label}</option>
          {renderOptions()}
        </S.StyledDropdown>
      </S.StyledFieldset>
      <ErrorMessage error={error} />
    </S.StyledCheckboxContainer>
  );
};
