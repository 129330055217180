import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@vfit/shared/atoms';
import { purify } from '@vfit/shared/themes';
import { IErrorFormApi, ISubmitForm } from '@vfit/shared/models';
import { FormProvider, useForm } from 'react-hook-form';
import { getVariantByActionStyle, isJSON } from '@vfit/shared/data-access';
import { useState } from 'react';
import { CustomCheckbox, CustomInput, CustomRadio, CustomSelect } from './components';
import * as S from './cmsForm.style';
import { ICmsForm, IDataForm } from './cmsForm.models';
import { createSubmitMethod } from './cmsForm.utils';

// How to use? Docs
// const testForm = organizeForm({});
// const signUpForm = getInputs(testForm);
// const initialValuesSignUp = {
//   ...signUpForm.initialValues,
//   piva: 'test piva',
// };
// <CmsForm
//   {...signUpForm}
//   initialValues={initialValuesSignUp}
//   titleForm="Form dinamiche Vuoi maggiori informazioni?"
//   descriptionForm="Lascia i tuoi dati e ti contatteremo al più presto."
//   onSubmit={onSubmitSignUp}
//   labelButtonSubmit="Ti chiamiamo gratis"
// />

const CmsForm = ({ ...props }: ICmsForm) => {
  const {
    initialValues,
    inputs,
    onFormSubmitted,
    onCustomSubmit,
    onErrorForm,
    onSuccessForm,
    onTrackSubmit,
    submitConfig,
    validationSchema,
    config,
  } = props;
  const {
    title: titleForm,
    description: descriptionForm,
    footer,
    textAlign: textAlignForm,
  } = config;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pTitleForm, pDescriptionForm, pFooter] = purify([titleForm, descriptionForm, footer]);

  const formMethods = useForm({
    ...(!submitConfig?.alwaysEnabledSubmitButton && { mode: 'onBlur' }),
    resolver: yupResolver(validationSchema),
    defaultValues: { ...(initialValues as any) },
  });

  const formSubmit = async (data: IDataForm) => {
    onTrackSubmit?.(submitConfig?.label || '');
    if (onCustomSubmit) {
      onCustomSubmit(data);
      return;
    }
    const { success: successConfig, errors: errorsConfig } = submitConfig as ISubmitForm;

    const { url, headers, body, method } = createSubmitMethod(
      data,
      inputs,
      submitConfig as ISubmitForm
    );

    const submitResponse = (type: 'success' | 'error', errorCmsApi?: IErrorFormApi) => {
      if (type === 'success') onSuccessForm?.(successConfig);
      else onErrorForm?.(errorsConfig, errorCmsApi);
    };

    try {
      setIsLoading(true);
      const response = await fetch(url, {
        method,
        headers,
        body,
      });
      if (response.ok) {
        setIsLoading(false);
        onFormSubmitted?.(data);
        submitResponse('success');
      } else {
        const dataRes = await response?.text();
        setIsLoading(false);
        submitResponse('error', {
          status: response?.status || 500,
          data: isJSON(dataRes) ? JSON.parse(dataRes) : data,
        });
      }
    } catch (error: any) {
      setIsLoading(false);
      submitResponse('error', {
        status: error?.status || 500,
      });
    }
  };

  const createInputs = () =>
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    inputs.map(({ validations, typeValue, value, ...inputProps }) => {
      switch (inputProps.type) {
        case 'select':
          return (
            <CustomSelect {...inputProps} key={inputProps.name} isPendingRequest={isLoading} />
          );
        case 'checkbox':
          return (
            <CustomCheckbox {...inputProps} key={inputProps.name} isPendingRequest={isLoading} />
          );
        case 'radio':
          return <CustomRadio {...inputProps} key={inputProps.name} isPendingRequest={isLoading} />;
        default:
          return <CustomInput {...inputProps} key={inputProps.name} isPendingRequest={isLoading} />;
      }
    });

  const isDisabledSubmit = submitConfig?.alwaysEnabledSubmitButton
    ? isLoading
    : isLoading || !formMethods?.formState?.isValid;

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(formSubmit)}>
        <S.StyledFormTextContainer textAlign={textAlignForm}>
          {pTitleForm && (
            <h2
              dangerouslySetInnerHTML={{
                __html: pTitleForm,
              }}
            />
          )}
          {pDescriptionForm && (
            <h4
              dangerouslySetInnerHTML={{
                __html: pDescriptionForm,
              }}
            />
          )}
        </S.StyledFormTextContainer>
        <S.StyledSection>{createInputs()}</S.StyledSection>
        {submitConfig?.label && (
          <S.StyledContainerButton>
            <Button
              type="submit"
              disabled={isDisabledSubmit}
              variant={getVariantByActionStyle(submitConfig.variant)}
            >
              {submitConfig.label}
            </Button>
          </S.StyledContainerButton>
        )}
        {pFooter && (
          <S.StyledFormTextContainer textAlign={textAlignForm} style={{ marginBottom: 40 }}>
            <p
              dangerouslySetInnerHTML={{
                __html: pFooter,
              }}
            />
          </S.StyledFormTextContainer>
        )}
      </form>
    </FormProvider>
  );
};

export default CmsForm;
