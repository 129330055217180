import { ErrorDetail, Severity } from './interfaces';
import { LoggerService } from '../logger';

export class ErrorService {
  private logger = new LoggerService();

  private customErrorEvent: CustomEvent | undefined;

  /**
   * Handle Errors
   */
  public handleError(severity: Severity, errorDetail?: ErrorDetail): void {
    switch (severity) {
      case Severity.HIGH:
        this.handleHighSeverity(errorDetail);
        break;
      case Severity.MEDIUM:
        this.handleMediumSeverity(errorDetail);
        break;
      case Severity.LOW:
      default:
        this.handleLowSeverity(errorDetail.message, errorDetail.errorCode);
    }
  }

  /**
   * Handle high severity error
   */
  private handleHighSeverity(errorDetail: ErrorDetail): void {
    const detail: ErrorDetail = {
      ...errorDetail,
      severity: Severity.HIGH,
    };

    this.customErrorEvent = new CustomEvent('modalErrorServiceNotificationEvent', { detail });
    document.dispatchEvent(this.customErrorEvent);
  }

  /**
   * Handle medium severity error
   */
  public handleMediumSeverity(errorDetail: ErrorDetail): void {
    const detail: ErrorDetail = {
      ...errorDetail,
      severity: Severity.MEDIUM,
    };

    this.customErrorEvent = new CustomEvent('modalErrorServiceNotificationEvent', { detail });
    document.dispatchEvent(this.customErrorEvent);
  }

  /**
   * Handle low severity error
   */
  public handleLowSeverity(description: string, errorCode: string): void {
    this.logger.warn(`SEVERITY: ${Severity.LOW} | ERROR CODE: ${errorCode} | ${description}`);
  }

  /**
   * Get Severity High
   */

  static getSeverityErrorHigh(): Severity {
    return Severity.HIGH;
  }

  /**
   * Get severuty medium
   */

  static getSeverityErrorMedium(): Severity {
    return Severity.MEDIUM;
  }

  /**
   * Get severity Low
   */

  static getSeverityErrorLow(): Severity {
    return Severity.LOW;
  }
}
