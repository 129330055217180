import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const ChevronDownBtn: React.FC<ISvgComponent> = ({
  color,
  height,
  width,
  shrinkable,
  ...rest
}) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 9.136 9.136"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M8.386 2.659 4.568 6.477.75 2.659"
    />
  </S.Svg>
);

