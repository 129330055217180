import { ButtonSlide, CustomText, ImageAtoms } from '@vfit/shared/atoms';
import { IGenericError } from '@vfit/shared/models';
import { purify } from '@vfit/shared/themes';
import { checkIsApp } from '@vfit/consumer/data-access';
import {
  ButtonSlideContainer,
  Container,
  Title,
  TextContainer,
  ImageContainer,
  TitleCentered,
  TopContainer,
} from './genericDetail.style';

const GenericDetail = ({
  title,
  description,
  submitButtonLabel,
  submitButtonAction,
  secondButtonLabel,
  secondButtonAction,
  options,
  imageUrl,
  invertButtons = false,
  titleAlwaysCenter = false,
  isButtonSticky,
}: IGenericError) => {
  const [pDescription, pTitle] = purify([description, title]);
  return (
    <Container>
      <TopContainer isButtonSticky={isButtonSticky}>
        {imageUrl && (
          <ImageContainer>
            <ImageAtoms
              nameCard={title}
              image={imageUrl}
              imageMobile={imageUrl}
              style={{ width: '50.69px', height: '47.9px' }}
              isMobile
            />
          </ImageContainer>
        )}
        {titleAlwaysCenter && pTitle && (
          <TitleCentered dangerouslySetInnerHTML={{ __html: pTitle }} />
        )}
        {!titleAlwaysCenter && pTitle && (
          <Title dangerouslySetInnerHTML={{ __html: pTitle }} textAlign={options?.textAlign} />
        )}

        {pDescription && (
          <TextContainer>
            <CustomText
              text={pDescription}
              size={options?.size || 18}
              lineHeight={options?.lineHeight || 24}
              sizeMobile={options?.sizeMobile}
              lineHeightMobile={options?.lineHeightMobile}
              modal={options?.isModal}
              textAlign={options?.textAlign}
              fontFamily={options?.fontFamily}
            />
          </TextContainer>
        )}
      </TopContainer>
      <ButtonSlideContainer isSticky={isButtonSticky}>
        {!invertButtons && (
          <>
            {secondButtonLabel && secondButtonAction && (
              <ButtonSlide
                label={secondButtonLabel}
                onClick={secondButtonAction}
                isApp={checkIsApp()}
                borderColor="#0d0d0d"
                hoverColor="#262626"
                hoverTextColor="#fff"
                clickColor="#7e7e7e"
                clickTextColor="#fff"
                name="action_second_genericDetail"
              />
            )}
            {submitButtonLabel && submitButtonAction && (
              <ButtonSlide
                label={submitButtonLabel}
                onClick={submitButtonAction}
                isApp={checkIsApp()}
                hoverColor="#bd0000"
                clickColor="#a10000"
                buttonColor="#e60000"
                labelColor="#fff"
                name="action_first_genericDetail"
              />
            )}
          </>
        )}
        {invertButtons && (
          <>
            {submitButtonLabel && submitButtonAction && (
              <ButtonSlide
                label={submitButtonLabel}
                onClick={submitButtonAction}
                hoverColor="#bd0000"
                clickColor="#a10000"
                buttonColor="#e60000"
                labelColor="#fff"
                name="action_first_genericDetail"
              />
            )}
            {secondButtonLabel && secondButtonAction && (
              <ButtonSlide
                label={secondButtonLabel}
                onClick={secondButtonAction}
                borderColor="#0d0d0d"
                hoverColor="#262626"
                hoverTextColor="#fff"
                clickColor="#7e7e7e"
                clickTextColor="#fff"
                name="action_second_genericDetail"
              />
            )}
          </>
        )}
      </ButtonSlideContainer>
    </Container>
  );
};
export default GenericDetail;

