import { errorMock } from '@vfit/consumer/data-access';
import { API } from '@vfit/shared/data-access';
import {
  INextError,
  LoggerInstance,
  nextClient,
  CustomOptions,
  handleUseQuery,
  UseQueryConfig,
} from '@vfit/shared/data-access';
import { UseQueryResult } from 'react-query';
import { ICoexistenceCustomerType, ICoexistenceuserResponse } from './coexistenceUser.modules';

/**
 * Available Customer Types
 */
export const CUSTOMER_TYPES: ICoexistenceCustomerType = {
  IN_MIGRATION: 'inmigration',
  LEGACY: 'legacy',
  NEXT: 'next',
  ANONYMOUS: 'anonymous',
};

/**
 * This method is used before the findCaller service to determine what is the user type.
 * API  commerce/v1/coexistenceuser.
 * Method : GET
 * QueryString Params: salesChannel=selfService
 * @returns
 */
const coexistenceUserService = (customOptions?: CustomOptions) =>
  nextClient.get(API.COEXISTENCE_USER, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  }) as Promise<ICoexistenceuserResponse>;

/**
 * This method is used to instantiate a custom useQuery hook to handle the coexintenceUserService
 * @param coexistenceEnabler
 * @param customOptions
 * @returns
 */
export const useCoexistenceUser = (coexistenceEnabler?: boolean, customOptions?: CustomOptions) => {
  const keysDependency: string | string[] =
    customOptions && customOptions.keyDependency ? customOptions.keyDependency : 'coexistenceUser';

  const options: UseQueryConfig = {
    queryKey: keysDependency,
    queryFn: () => coexistenceUserService(),
    options: {
      ...(customOptions && { ...customOptions }),
      enabled: coexistenceEnabler,
      onSuccess: (data: ICoexistenceuserResponse) => {
        if (
          data.userType === CUSTOMER_TYPES.NEXT ||
          CUSTOMER_TYPES.ANONYMOUS ||
          CUSTOMER_TYPES.LEGACY
        ) {
          // todo continue calling GET findCaller
          localStorage.setItem('coexistenceUser', JSON.stringify(data));
        }
        if (data.userType === CUSTOMER_TYPES.IN_MIGRATION) {
          // todo I do not know what to do
        }
      },
      onError: (error: INextError) => {
        LoggerInstance.error('ERROR - coexistenceUser: ', error);
        localStorage.setItem(
          'coexistenceUser',
          JSON.stringify(errorMock('coexistenceUser', error))
        );
      },
    },
  };

  return handleUseQuery(options) as UseQueryResult<ICoexistenceuserResponse, INextError>;
};
