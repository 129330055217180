import styled from 'styled-components';
import { colors, pxToRem } from '@vfit/shared/themes';
import { IBgProps } from './modalAsDrawer.models';

export const Background = styled.div<IBgProps>`
  height: 100vh;
  left: 0;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 4;
  ${(p) => (p.colorTheBg ? `background-color: rgba(0, 0, 0, 50%);` : '')}
  ${(p) => `animation: slide-${p.show ? 'up' : 'down'} ${p.animationTime / 1000}s forwards`};

  @keyframes slide-down {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(100%);
    }
  }

  @keyframes slide-up {
    from {
      transform: translateY(100%);
    }

    to {
      transform: translateY(0);
    }
  }
`;

export const Drawer = styled.section<{ isModalFullContainer?: boolean }>`
  background-color: ${colors.$ffffff};
  border-radius: ${pxToRem(6, 6, 0, 0)};
  bottom: 0;
  display: flex;
  justify-content: center;
  height: ${({ isModalFullContainer }) => (isModalFullContainer ? 'auto' : '80%')};
  left: 0;
  padding: ${pxToRem(48, 0)};
  position: fixed;
  right: 0;
  width: 100vw;
  z-index: 5;
`;

export const Frame = styled.div`
  overflow-y: auto;
  width: calc(100vw - ${pxToRem(88)});
`;
