import { useMediaQuery } from '@vfit/shared/hooks';
import { colors, Colors } from '@vfit/shared/themes';
import { useMemo } from 'react';
import { useCta } from '../../cta.hook';
import { IUseButton } from './button.models';

export const useButton = <T>({
  _href,
  _onClick,
  _onMouseEnter,
  _onMouseLeave,
  disableAppMobileOpenInBrowser,
  disabled,
  size,
  variant,
}: IUseButton<T>) => {
  const { href, isHover, onClick, onMouseEnter, onMouseLeave } = useCta<T>({
    _href,
    _onClick,
    _onMouseEnter,
    _onMouseLeave,
    disableAppMobileOpenInBrowser,
    disabled,
  });

  const isDesktop = useMediaQuery('desktop', 'min');

  const color: Colors = useMemo(() => {
    switch (true) {
      case disabled &&
        (variant === 'primary' || variant === 'mva:primary' || variant === 'mva:secondary'):
      case variant === 'primary':
      case variant === 'mva:primary':
      case variant === 'mva:secondary':
        return colors.$ffffff;
      case disabled && variant === 'mva:alt1':
        return colors.$9a9a9a;
      case disabled:
        return colors.$bebebe;
      case variant === 'mva:alt1':
        return !isHover ? colors.$0d0d0d : colors.$ffffff;
      case variant === 'secondary':
        return !isHover ? colors.$262626 : colors.$ffffff;
      case variant === 'tertiary':
      default:
        return colors.$262626;
    }
  }, [disabled, isHover, variant]);

  const height = useMemo(() => {
    switch (size) {
      case 'big':
        return 16;
      case 'medium':
        return 11;
      case 'responsive':
        return !isDesktop ? 11 : 15;
      case 'small':
      default:
        return 11;
    }
  }, [isDesktop, size]);

  return { color, height, href, onClick, onMouseEnter, onMouseLeave };
};

