import { CustomOptions, nextClient } from '@vfit/shared/data-access';
import { API } from '@vfit/shared/data-access';

export const attachmentDownload = (
  documentId: string,
  salesChannel: string,
  filters: string,
  customOptions?: CustomOptions
) =>
  nextClient.get(`${API.ATTACHMENT_DOWNLOAD_DOCUMENT}/${documentId}`, {
    notJsonParsing: true,
    searchParams: {
      salesChannel,
      filters, // it is required but can be ''
    },
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  }) as Promise<Response>;
