import styled, { css } from 'styled-components';
import { breakpoints, colors, cssfonts, fonts, getTypography, pxToRem } from '@vfit/shared/themes';
import { IErrorPropsForStyled } from './errorGenericV2.models';

const normalize = css`
  b,
  strong {
    ${cssfonts.exbold};
  }

  > * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const variants = {
  title: {
    mva: css`
      ${getTypography('h3.bold')}
    `,
    sunrise: css`
      ${getTypography('h2.extrabold')}
      color: ${colors.$e60000};
    `,
  },
  subtitle: {
    mva: css`
      ${getTypography('h5.bold')}
    `,
    sunrise: css`
      ${getTypography('h3.extrabold')}
    `,
  },
  description: {
    mva: css`
      ${getTypography('body2.regular')}
    `,
    sunrise: css`
      ${getTypography('h4.light')}
    `,
  },
  insideButtonContainer: {
    mva: css`
      flex-direction: row;
      justify-content: flex-end;
    `,
    sunrise: css`
      position: static;
      align-items: center;
      margin: ${pxToRem(113, 0, 0)};
    `,
  },
  bodyWrap: {
    mva: css`
      gap: ${pxToRem(16)};

      @media (min-width: ${breakpoints.tablet}) {
        max-width: ${pxToRem(400)};
      }

      @media (min-width: ${breakpoints.desktop}) {
        max-width: ${pxToRem(440)};
      }
    `,
    sunrise: css`
      gap: ${pxToRem(24)};

      @media (min-width: ${breakpoints.tablet}) {
        max-width: ${pxToRem(520)};
      }

      @media (min-width: ${breakpoints.desktop}) {
        max-width: ${pxToRem(840)};
      }
    `,
  },
};

export const Container = styled.div`
  color: ${colors.$262626};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  text-align: center;
`;

export const MessageDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  @media (min-width: ${breakpoints.desktop}) {
    position: static;
  }
`;

export const Title = styled.div<IErrorPropsForStyled>`
  ${normalize}
  margin-bottom: ${pxToRem(24)};

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${pxToRem(20)};
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: ${pxToRem(30)};
  }

  ${({ variant }) => (variant ? variants.title[variant] : '')}
`;

export const Subtitle = styled.div<IErrorPropsForStyled>`
  ${normalize}
  ${({ variant }) => (variant ? variants.subtitle[variant] : '')}
`;

export const Description = styled.div<IErrorPropsForStyled>`
  ${normalize}
  ${({ variant }) => (variant ? variants.description[variant] : '')}
`;

export const InsideButtonContainer = styled.div<IErrorPropsForStyled>`
  bottom: 0;
  right: 0;
  flex-direction: column;
  font-family: ${fonts.regular};
  display: flex;
  width: 100%;
  gap: ${pxToRem(16)};
  margin-top: ${pxToRem(24)};

  button {
    width: 100%;
  }

  @media (min-width: ${breakpoints.tablet}) {
    width: 100%;
    position: absolute;
    margin-top: 0;

    button {
      min-width: ${pxToRem(400)};
      max-width: min-content;
      padding: ${pxToRem(0, 105)};
    }

    ${({ variant }) => (variant ? variants.insideButtonContainer[variant] : '')}
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ImageWrapper = styled.div`
  height: ${pxToRem(120)};
  width: ${pxToRem(120)};
`;

export const BodyWrap = styled.div<IErrorPropsForStyled>`
  display: flex;
  flex-direction: ${({ descriptionOnTop }) => (descriptionOnTop ? 'column-reverse' : 'column')};
  margin: ${pxToRem(24, 0, 0)};

  @media (min-width: ${breakpoints.tablet}) {
    gap: ${pxToRem(16)};
  }

  ${({ variant }) => (variant ? variants.bodyWrap[variant] : '')}
`;
