import { resetData } from './api';
import { handleUseQuery, handleUseQueryes } from './handleUseQuery';
import { UseQueryConfig, CustomOptions, CustomMutationOptions } from './model';

export {
  handleUseQueryes,
  handleUseQuery,
  resetData
};

export type { UseQueryConfig, CustomOptions, CustomMutationOptions };
