import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const CircleStatusInfo: React.FC<ISvgComponent> = ({
  color = '#008A00',
  height,
  width,
  shrinkable,
  ...rest
}) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 20 20"
    shrinkable={shrinkable}
    {...rest}
  >
    <circle cx="10" cy="10" r="9.5" fill="white" stroke={color} />
    <path d="M 15,7 8.0761,13 5,10.332" stroke={color} strokeLinecap="round" />
  </S.Svg>
);
