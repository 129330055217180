import { QueryClient } from 'react-query';

// Example of handleUseQuery function provider.
// export const getCountry = (customOptions?: CustomOptions) => {
//   const keysDependency =
//     customOptions && customOptions.keyDependency
//       ? customOptions.keyDependency
//       : ['getCountry'];
//   const options: UseQueryConfig = {
//     queryKey: keysDependency,
//     queryFn: () => nextClient.get(API.COUNTRY),
//     options: {
//       ...(customOptions && { ...customOptions }),
//       /* EG: of the options we can pass
//             onSuccess: (data: any) => {
//                             LoggerInstance.debug('API  GETCOUNTRY CALL WITH NEW UTILS, OVERRIDED ON SUCCESS MESSAGE --> DATA: ', data)
//                         }, */
//     },
//   };
//   return handleUseQuery(options);
// };

export const resetData = (queryClient: QueryClient, keys: string[] = []) => {
  // TODO keys required always populated besause this cause the reset of cms data
  if (keys.length === 0) return false;
  if (keys.length === 0) localStorage.clear();
  else {
    keys.forEach((key) => {
      localStorage.removeItem(key);
      queryClient.removeQueries([key]);
      queryClient.resetQueries([key]);
    });
  }
  return true;
};
