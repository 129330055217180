export enum LogLevel {
  ALL = 1,
  TRACE = 2,
  DEBUG = 3,
  INFO = 4,
  WARNING = 5,
  ERROR = 6,
  NONE = 7,
}

class LoggerService {
  public level: number;

  constructor() {
    const envLevel = process.env["NEXT_PUBLIC_LOGGER_LEVEL"] || 'ALL';
    this.level = LogLevel[envLevel];
  }

  /**
   * Invoke the message logger based on "ALL" severity
   * @param message - messages to be printed
   */
  public log(...message: any[]): void {
    this.logMessage(LogLevel.ALL, ...message);
  }

  /**
   * Invoke the message logger based on "INFO" severity
   * @param message - messages to be printed
   */
  public info(...message: any[]): void {
    this.logMessage(LogLevel.INFO, ...message);
  }

  /**
   * Invoke the message logger based on "TRACE" severity
   * @param message - messages to be printed
   */
  public trace(...message: any[]): void {
    this.logMessage(LogLevel.TRACE, ...message);
  }

  /**
   * Invoke the message logger based on "WARNING" severity
   * @param message - messages to be printed
   */
  public warn(...message: any[]): void {
    this.logMessage(LogLevel.WARNING, ...message);
  }

  /**
   * Invoke the message logger based on "ERROR" severity
   * @param message - messages to be printed
   */
  public error(...message: any[]): void {
    this.logMessage(LogLevel.ERROR, ...message);
  }

  /**
   * Invoke the message logger based on "DEBUG" severity
   * @param message - messages to be printed
   */
  public debug(...message: any[]): void {
    this.logMessage(LogLevel.DEBUG, ...message);
  }

  /**
   * Format the whole date
   */
  private formatMessage(logLevel: number): string {
    return `${this.formatDate()} | Level: ${LogLevel[
      logLevel
      ]?.toUpperCase()} |`;
  }

  /**
   * Format the whole date
   * @returns the formatted date
   */
  private formatDate(): string {
    const date: Date = new Date();
    const fullYear: string = this.adjustDateFormat(date.getUTCFullYear());
    const UTCDate: string = this.adjustDateFormat(date.getUTCDate());
    const month: string = this.adjustDateFormat(date.getUTCMonth() + 1);
    const hours: string = this.adjustDateFormat(date.getHours());
    const minutes: string = this.adjustDateFormat(date.getMinutes());
    const seconds: string = this.adjustDateFormat(date.getSeconds());
    const milliseconds: string = this.adjustDateFormat(date.getMilliseconds());
    return `${fullYear}-${UTCDate}-${month} ${hours}:${minutes}:${seconds}:${milliseconds}`;
  }

  /**
   * Adjust date section formatting
   * @param logLevel - severity of the log
   * @param messages - messages to be printed
   */
  public logMessage(logLevel: number, ...messages: string[]): void {
    if (logLevel >= this.level && logLevel !== LogLevel.NONE) {
      if (logLevel === LogLevel.WARNING) {
        console.warn(this.formatMessage(logLevel), ...messages);
      } else if (logLevel === LogLevel.ERROR) {
        //console.error(this.formatMessage(logLevel), ...messages);
        console.trace(this.formatMessage(logLevel), ...messages);
      } else if (logLevel === LogLevel.DEBUG) {
        //console.debug(this.formatMessage(logLevel), ...messages);
        console.trace(this.formatMessage(logLevel), ...messages);
      }else if (logLevel === LogLevel.INFO) {
        console.info(this.formatMessage(logLevel), ...messages);
      } else {
        console.log(this.formatMessage(logLevel), ...messages);
      }
    }
  }

  /**
   * Adjust date section formatting
   * @param input - date section to be formatted
   * @returns the date section adjusted
   */
  // eslint-disable-next-line class-methods-use-this
  private adjustDateFormat(input: number): string {
    return input < 10 ? `0${input}` : String(input);
  }
}

const LoggerInstance = new LoggerService()

export {
  LoggerService,
  LoggerInstance
}
