import React from 'react';
import Script from 'next/script';
import { handleEnv } from '@vfit/shared/data-access';
import { IAppDynamics } from './appDynamics.models';

const AppDynamics = ({ isConfig }: IAppDynamics) => {
  const apiKey = handleEnv('NX_APP_DYNAMICS_API_KEY');
  const isDevLocal = () => process.env['NODE_ENV'] === 'development';
  if (!apiKey || isDevLocal()) return null;
  if (isConfig) {
    return (
      <Script
        id="appdynamics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window["adrum-start-time"] = new Date().getTime();
           (function(config){
            config.appKey = "${apiKey}";
            config.adrumExtUrlHttp = 'http://cdn.appdynamics.com';
            config.adrumExtUrlHttps = 'https://cdn.appdynamics.com';
            config.beaconUrlHttp = 'http://fra-col.eum-appdynamics.com';
            config.beaconUrlHttps = 'https://fra-col.eum-appdynamics.com';
            config.resTiming = '{"bufSize":200,"clearResTimingOnBeaconSend":true}';
            config.maxUrlLength = 1024;
            config.longStackTrace = false;
            config.spa = {"spa2":true};
          })
          (window["adrum-config"] || (window["adrum-config"] = {}));
        `,
        }}
      />
    );
  }
  return (
    <Script
      id="appdynamics-adrum"
      strategy="afterInteractive"
      src="https://cdn.appdynamics.com/adrum/adrum-latest.js"
    />
  );
};

export default AppDynamics;
