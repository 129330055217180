import { LoggerInstance } from '@vfit/shared/data-access';
import MUNICIPALITIES from './italian-municipalities.json';
import { IMunicipality } from './municipalities.models';

export function normalizeString(str: string) {
  return (
    str
      .trim()
      // eslint-disable-next-line
      .replace(new RegExp(/[àá]/g), "a'")
      // eslint-disable-next-line
      .replace(new RegExp(/[èé]/g), "e'")
      // eslint-disable-next-line
      .replace(new RegExp(/[ìí]/g), "i'")
      // eslint-disable-next-line
      .replace(new RegExp(/[òó]/g), "o'")
      // eslint-disable-next-line
      .replace(new RegExp(/[ùú]/g), "u'")
      .toUpperCase()
  );
}

export function searchByNameAndProvince(name: string, prov: string) {
  const qNome = normalizeString(name);
  const qProv = prov && normalizeString(prov);
  let results: IMunicipality[] = MUNICIPALITIES.filter((c) =>
    qProv ? c[1] === qProv && c[2] === qNome : c[2] === qNome
  ).map((c) => ({ cc: c[0], prov: c[1], name: c[2], active: c[3] === 1 }));

  // One results: no problem!
  if (results.length === 1) {
    return results[0];
  }

  // if many results look for the active one
  results = results.filter((c) => c.active);

  if (results.length === 1) return results[0];
  if (prov) throw new Error(`Comune with name of ${name} and prov ${prov} doesn't exists`);
  else if (name.length === 4 && name.toUpperCase() === name)
    // eslint-disable-next-line
    return GetByCC(name);
  else
    throw new Error(
      `Comune with name of ${name} is found in more than one province. Please specify the province code`
    );
}

export function searchByCC(cc: string) {
  let result;
  try {
    // eslint-disable-next-line
    result = GetByCC(cc);
  } catch (error) {
    LoggerInstance.debug('searchByCC error', error);
  }
  if (result !== undefined) {
    return result;
  }
  return null;
}

export function getMunicipality(
  name: string,
  prov: string,
  cc: string,
  check: boolean = true
): IMunicipality | null {
  if (check || cc === undefined || prov === undefined) {
    let comune = searchByNameAndProvince(name, prov);

    if (comune === undefined && name.length === 4) {
      comune = searchByCC(name);
    }

    if (comune === undefined) {
      throw new Error(`Comune with name ${name} doesn't exist`);
    } else if (cc !== undefined && comune?.cc !== cc) {
      throw new Error(`Comune with cc ${cc} doesn't exist`);
    } else {
      return {
        name,
        prov,
        cc,
      };
    }
  } else {
    return {
      name,
      prov,
      cc,
    };
  }
}

export function GetByCC(municipality: string) {
  let result;
  // eslint-disable-next-line
  for (const item of MUNICIPALITIES) {
    if (item.code === municipality) {
      result = item;
    }
  }
  if (result !== undefined) {
    return getMunicipality(result.municipality, result.province, result.code, false);
  }
  throw new Error(`Comune with cc ${municipality} doesn't exist`);
}

