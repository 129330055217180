export interface IExtra {
  text?: string;
  icon?: string | JSX.Element;
  color?: string;
  action?: () => void;
  extraClass?: string;
}

export interface IOptionalText {
  detail?: string;
  title: string;
  description?: string;
  value: string;
  extra?: IExtra;
  selection?: boolean;
}

export enum ActionTextType {
  product = 'product',
  club = 'club',
}

