import React, { useEffect, useState } from 'react';
import { checkWindow, useTracking } from '@vfit/shared/data-access';
import { IErrorFormApi, ISubmitOutput } from '@vfit/shared/models';
import { ILeadModalDetail } from './interfaces';
import VfModal from '../VfModal/vfModal';
import LeadPlatform from '../LeadPlatform/leadPlatform';

export const ModalLeadWrapper = () => {
  const { trackView } = useTracking({
    event: ['starting_tool_lead'],
    disableInitialTrack: true,
  });

  const initialLead: ILeadModalDetail = {
    title: '',
    onClose: () => {},
  };
  const setModalHeight = () => {
    const defaultValue = 563;
    if (!checkWindow()) return defaultValue;
    const height = window?.innerHeight;
    if (!height) return defaultValue;
    return height - (height * 30) / 100;
  };
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalDetail, setModalDetail] = useState<ILeadModalDetail>(initialLead);

  const actionLead = (event) => {
    const { detail } = event as CustomEvent as { detail: ILeadModalDetail };
    setModalDetail(detail || modalDetail);
    setShowModal(true);
    trackView({
      page_name_override: 'Tool lead',
      page_subsection: 'tool-lead',
      event_name: 'product_view',
      event_category: 'sales',
      page_section: 'eshop',
      page_environment: 'Web',
      ...(detail.trackingOption && { ...detail.trackingOption }),
      ...(detail.product && { ...detail.product }),
    });
  };

  const handleOnTrackSubmit = (ctaLabel?: string) => {
    trackView({
      page_name_override: `Tool lead:${ctaLabel?.toLowerCase() || 'ti chiamiamo gratis'}`,
      event_name: 'ui_interaction',
      event_label_track: `${ctaLabel?.toLowerCase() || 'ti chiamiamo gratis'}`,
      event_category: 'users',
      event_action: 'click',
      tracking_type: 'link',
      page_section: 'eshop',
      page_subsection: 'tool-lead',
      page_environment: 'Web',
      ...(modalDetail?.trackingOption && { ...modalDetail.trackingOption }),
    });
  };

  const resetModalStatus = () => {
    setModalDetail(initialLead);
    setShowModal(false);
  };

  const handleCloseModal = () => {
    if (modalDetail.onClose) modalDetail.onClose();
    resetModalStatus();
  };

  const trackResponse = (type: 'success' | 'error', error?: IErrorFormApi) => {
    const isError = type === 'error';

    const getErrorTrack = () => {
      const statusCode = error?.status?.toString() || '';
      const subCode = error?.data?.['subCode'] || '';
      const description = error?.data?.['description'] || '';
      let pageError = statusCode;
      if (subCode) {
        pageError = `${pageError}_${subCode}`;
      }
      if (description) {
        pageError = `${pageError}_${description.replace(
          /[\s~`!@#$%^&*()àòùì_+\-={[}\]|\\:;"'<,>.?/]+/g,
          '_'
        )}`;
      }
      return {
        event_category: 'error',
        page_error: pageError,
        page_error_code: statusCode,
        page_type: 'error page',
        event_label_track: 'error',
        page_subsection: 'tool-lead',
        page_section: 'eshop',
      };
    };

    trackView({
      page_name_override: `Tool lead:${type === 'success' ? 'typ' : 'page_error'}`,
      event_name: type === 'success' ? 'typ' : 'page_error',
      ...(isError && {
        ...getErrorTrack(),
      }),
      page_browser_system: 'browser',
      ...(modalDetail?.product && { ...modalDetail.product }),
      page_subsection: 'tool-lead',
      page_section: 'eshop',
      ...(modalDetail?.trackingOption && { ...modalDetail.trackingOption }),
    });
  };

  const handleSuccess = (submitOutput: ISubmitOutput) => {
    modalDetail?.onSuccess?.(submitOutput);
    trackResponse('success');
    resetModalStatus();
  };

  const handleError = (submitOutput: ISubmitOutput[], errorCmsApi?: IErrorFormApi) => {
    modalDetail?.onError?.(submitOutput, errorCmsApi);
    trackResponse('error', errorCmsApi);
    resetModalStatus();
  };

  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.addEventListener('leadModalNotificationEvent', actionLead);
    }
    return () => document.removeEventListener('leadModalNotificationEvent', actionLead);
  }, []);

  return (
    <VfModal
      wrapperId={modalDetail.id}
      height={setModalHeight()}
      isOpen={showModal}
      handleClose={handleCloseModal}
    >
      <LeadPlatform
        form={modalDetail.form}
        onTrackSubmit={handleOnTrackSubmit}
        onSuccess={handleSuccess}
        onError={handleError}
      />
    </VfModal>
  );
};

