/**
 * obtain the value of a specific storage
 * @param item is storage key
 */
export const getToken = (item: string) => {
  if (typeof window !== 'undefined') {
    return window.sessionStorage.getItem(item);
  }
  return false;
};
