/**
 * Round number with a specific number of decimals
 * Prevent by arithmetic errors
 * @param n number
 * @param decimals number
 * @returns
 * Example: 1.525 - 1.5 -> 0.02499999999999991
 * but mathRound(1.525 - 1.5) -> 0.025
 */
export const mathRound = (n: number, decimals = 6) => Number(n.toFixed(decimals));

