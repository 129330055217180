import { css } from 'styled-components';
import { cssfonts } from '../cssfonts';

const common = css``;

export const button2 = {
  regular: css`
    ${cssfonts.regular}
    font-size: 0.875rem; /* 14px */
    letter-spacing: 0.5px;
    line-height: 1; /* differs from DS typography */
    text-transform: uppercase;
  `,
  bold: css`
    ${cssfonts.bold}
    font-size: 0.875rem; /* 14px */
    letter-spacing: 0.5px;
    line-height: 1; /* differs from DS typography */
    text-transform: uppercase;
  `,
} as const;

