export const fonts = {
  vodafone: 'Vodafone', // All in one.
  regular: 'VodafoneRg',
  light: 'VodafoneLt',
  exbold: 'VodafoneExB',
  instrumentSansRegular: 'VodafoneIsRg',
  instrumentSansBold: 'VodafoneIsRgB',
} as const;

type FontsKeys = keyof typeof fonts;
export type Fonts = (typeof fonts)[FontsKeys];
