export const colors = {
  

  $transparent: 'transparent',

  // black
  $262626: '#262626', // default
  $0d0d0d: '#0d0d0d',
  $353535_40: '#35353566', // rgba(53, 53, 53, 40%)
  $000000_16: '#00000029', // rgba(0, 0, 0, 16%)
  $25282B: '#25282B',

  // grey
  $f2f2f2: '#f2f2f2',
  $bebebe: '#bebebe',
  $ebebeb: '#ebebeb', // MVA
  $b99898: '#b99898', // MVA
  $7e7e7e: '#7e7e7e',
  $eeeeee: '#eee',
  $333333: '#333333',
  $313131: '#313131', // MVA
  $9a9a9a: '#9a9a9a', // MVA
  $565656: '#565656', // MVA

  // orange
  $eb6100: '#eb6100',

  // yellow
  $fecb00: '#fecb00',

  // green
  $008a00: '#008a00',
  $00697c: '#00697c',
  $0096AD: '#0096AD',

  // red
  $eb8a90: '#eb8a90', // MVA
  $e60000: '#e60000', // Vodafone
  $bd0000: '#bd0000',
  $a10000: '#a10000',
  $820000: '#820000',
  $c30000: '#c30000', // MVA

  //pink
  $9c2aa0: '#9c2aa0',

  // white
  $ffffff: '#fff',
  $ffffff_80: '#fffc', // rgba(255, 255, 255, 80%)
  $ffffff_60: '#fff9', // rgba(255, 255, 255, 60%)

  //Cyan
  $007c92: '#007c92', // MVA
} as const;

type ColorsKeys = keyof typeof colors;
export type Colors = (typeof colors)[ColorsKeys];

