import { LoggerInstance } from '@vfit/shared/data-access';
import { Gender, IFiscalCodeReverse } from './fiscal-code.models';
import {
  CHECK_CODE_CHARS,
  CHECK_CODE_EVEN,
  CHECK_CODE_ODD,
  MONTH_CODES,
  OMOCODIA_TABLE_INVERSE,
} from './fiscal-code.constants';
import { GetByCC } from './municipalities.utils';

export function toNumberIfOmocodia(input: any) {
  //  Number.isNaN(Number(input)) TODO??
  // eslint-disable-next-line
  if (isNaN(input)) {
    let res = '';
    const tokens = input.split('');
    // eslint-disable-next-line
    for (let i = 0; i < tokens.length; i++) {
      const e = tokens[i];
      // eslint-disable-next-line
      res += isNaN(e) ? OMOCODIA_TABLE_INVERSE[e] : e;
    }
    return res;
  }
  return input;
}

export function normalizeString(str: string) {
  return (
    str
      .trim()
      // eslint-disable-next-line prefer-regex-literals
      .replace(new RegExp(/[àá]/g), "a'")
      // eslint-disable-next-line prefer-regex-literals
      .replace(new RegExp(/[èé]/g), "e'")
      // eslint-disable-next-line prefer-regex-literals
      .replace(new RegExp(/[ìí]/g), "i'")
      // eslint-disable-next-line prefer-regex-literals
      .replace(new RegExp(/[òó]/g), "o'")
      // eslint-disable-next-line prefer-regex-literals
      .replace(new RegExp(/[ùú]/g), "u'")
      .toUpperCase()
  );
}

export function daysInMonth(m: number, y: number) {
  switch (m) {
    case 1:
      return (y % 4 === 0 && y % 100 !== 0) || y % 400 === 0 ? 29 : 28;
    case 8:
    case 3:
    case 5:
    case 10:
      return 30;
    default:
      return 31;
  }
}

export function isValidDate(d: number, m: number, y: number) {
  const month = m - 1;
  return month >= 0 && month < 12 && d > 0 && d <= daysInMonth(month, y);
}

export function getValidDate(d: string | number, m: number, y: number): Date {
  if (typeof d === 'string' && m === undefined && y === undefined) {
    return new Date(d);
  }
  if (isValidDate(d as number, m as number, y)) {
    return new Date(y, m - 1, d as number, 0, 0, 0, 0);
  }
  throw new Error(`The date ${y}/${m}/${d} is not a valid date`);
}

export function extractVowels(str: string) {
  return str.replace(/[^AEIOU]/gi, '');
}

export function extractConsonants(str: string) {
  return str.replace(/[^BCDFGHJKLMNPQRSTVWXYZ]/gi, '');
}

export function dateCode(day: string, month: number, year: number, gender: Gender) {
  let yearToReturn = `0${year}`;
  yearToReturn = yearToReturn.substr(yearToReturn.length - 2, 2);
  const monthToReturn = MONTH_CODES[month - 1];
  let daysToReturn = day;
  if (gender.toUpperCase() === 'F') {
    daysToReturn = (+daysToReturn + 40).toString();
  }
  return `${yearToReturn}${monthToReturn}${daysToReturn}`;
}

export function getCheckCode(cf: string) {
  const cFToReturn = cf.toUpperCase();
  let val = 0;
  for (let i = 0; i < 15; i += 1) {
    const c = cFToReturn[i];
    val += i % 2 !== 0 ? CHECK_CODE_EVEN[c] : CHECK_CODE_ODD[c];
  }
  val %= 26;
  return CHECK_CODE_CHARS.charAt(val);
}

export function check(fiscalCode: string) {
  if (typeof fiscalCode !== 'string') {
    return false;
  }
  let cf = fiscalCode.toUpperCase();
  if (cf.length !== 16) {
    return false;
  }
  if (
    !/^[A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1}$/.test(
      cf
    )
  ) {
    return false;
  }
  const expectedCheckCode = fiscalCode.charAt(15);
  cf = fiscalCode.slice(0, 15);
  return getCheckCode(cf).toUpperCase() === expectedCheckCode.toUpperCase();
}

export function surnameCode(surname: string) {
  const codeSurname = `${extractConsonants(surname)}${extractVowels(surname)}XXX`;
  return codeSurname.substr(0, 3).toUpperCase();
}

export function nameCode(name: string) {
  let codName = extractConsonants(name);
  if (codName.length >= 4) {
    codName = codName.charAt(0) + codName.charAt(2) + codName.charAt(3);
  } else {
    codName += `${extractVowels(name)}XXX`;
    codName = codName.substr(0, 3);
  }
  return codName.toUpperCase();
}

export function reverse(fiscalCode: string) {
  const name = fiscalCode.substr(3, 3);
  const surname = fiscalCode.substr(0, 3);

  let yearCode = fiscalCode.substr(6, 2);
  yearCode = toNumberIfOmocodia(yearCode);
  const year19XX = parseInt(`19${yearCode}`, 10);
  const year20XX = parseInt(`20${yearCode}`, 10);
  const currentYear20XX = new Date().getFullYear();
  const year = year20XX > currentYear20XX ? year19XX : year20XX;
  const monthChar = fiscalCode.substr(8, 1);
  const month = MONTH_CODES.indexOf(monthChar);
  let gender = 'M';
  let dayString = fiscalCode.substr(9, 2);
  dayString = toNumberIfOmocodia(dayString);
  let day = parseInt(dayString, 10);
  if (day > 31) {
    gender = 'F';
    day -= 40;
  }
  const birthday = new Date(Date.UTC(year, month, day, 0, 0, 0, 0));
  let cc = fiscalCode.substr(11, 4);
  const ccNumbers = toNumberIfOmocodia(cc.substr(1, 3));
  cc = cc.charAt(0) + ccNumbers;
  const birthplace = GetByCC(cc);
  return {
    name,
    surname,
    gender,
    day: birthday.getDate(),
    year: birthday.getFullYear(),
    month: birthday.getMonth() + 1,
    birthday: birthday.toISOString().slice(0, 10),
    birthplace: birthplace?.name || '',
    birthplaceProvincia: birthplace?.prov || '',
    cf: fiscalCode,
  };
}

export function computeInverse(fiscalCode: string): IFiscalCodeReverse | null {
  if (typeof fiscalCode !== 'string') return null;
  const fiscalCodeToReturn = fiscalCode.toUpperCase();
  if (check(fiscalCodeToReturn)) {
    return reverse(fiscalCodeToReturn);
  }
  LoggerInstance.error('Provided input is not a valid Codice Fiscale');
  return null;
}

