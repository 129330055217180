import { useCallback, useMemo, useState } from 'react';
import { AppMobile } from '@vfit/shared/data-access';
import { IUseCta } from './cta.models';

export const useCta = <T>({
  _href,
  _onClick,
  _onMouseEnter,
  _onMouseLeave,
  disabled,
  disableAppMobileOpenInBrowser,
}: IUseCta<T>) => {
  const [isHover, setIsHover] = useState(false);

  const href = useMemo(() => (_href && !disabled ? _href : undefined), [_href, disabled]);

  const isApp = useMemo(() => AppMobile.checkIsApp(), []);

  const toggleIsHover = useCallback(() => {
    setIsHover((prev) => !prev);
  }, []);

  const onClick = useCallback(
    (e: React.MouseEvent<T>) => {
      if (disabled || _href === '') {
        return;
      }

      if (isApp && href && !disableAppMobileOpenInBrowser) {
        e.preventDefault();
        AppMobile.openInBrowser(href);
      }

      _onClick?.(e);
    },
    [_onClick, disableAppMobileOpenInBrowser, disabled, href, isApp]
  );

  const onMouseEnter = useCallback(
    (e: React.MouseEvent<T>) => {
      toggleIsHover();
      _onMouseEnter?.(e);
    },
    [_onMouseEnter, toggleIsHover]
  );

  const onMouseLeave = useCallback(
    (e: React.MouseEvent<T>) => {
      toggleIsHover();
      _onMouseLeave?.(e);
    },
    [_onMouseLeave, toggleIsHover]
  );

  return { href, isHover, onClick, onMouseEnter, onMouseLeave, toggleIsHover };
};
