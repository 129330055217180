import { LinkProps } from 'next/link';
import { checkWindow } from './check-window';

export const checkIsApp = (): boolean => {
  if (!checkWindow()) return false;
  const queryString = new URLSearchParams(window?.location?.search);
  return !!queryString.get('app') || !!localStorage.getItem('isApp');
};

export const execute = (name: string, params: Record<string, any>, callback: string | null) => {
  const is = (type: ArrayConstructor | ObjectConstructor, val: any) =>
    ![undefined, null].includes(val) && val.constructor === type;

  const serialize = (p: Record<string, any>) =>
    Object.keys(p)
      .map((key) => {
        let item = p[key];

        if (is(Array, item) || is(Object, item)) {
          item = JSON.stringify(item);
        }

        return `${key}=${encodeURIComponent(item)}`;
      })
      .join('&');

  (
    window as Window &
      typeof globalThis & {
        BWB_JS: any;
      }
  )?.BWB_JS?.execute(name, serialize(params), callback);
};

export const openInBrowser = (url: LinkProps['href']) => {
  execute('openInBrowser', { url }, null);
};

export const setBackButtonUrl = (url: LinkProps['href']) => {
  execute('setBackButtonUrl', { url }, null);
};
