import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const WarningDot: React.FC<ISvgComponent> = ({
  color,
  height,
  width,
  shrinkable,
  ...rest
}) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 24 24"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      stroke={color}
      d="M12.0002 7.98234V15.0448M9.80644 4.32108L2.80644 17.2823C2.59871 17.663 2.49358 18.0912 2.50138 18.5248C2.50918 18.9584 2.62964 19.3825 2.85092 19.7555C3.0722 20.1284 3.3867 20.4374 3.76352 20.6521C4.14034 20.8667 4.56652 20.9797 5.00019 20.9798H19.0002C19.4339 20.9797 19.86 20.8667 20.2369 20.6521C20.6137 20.4374 20.9282 20.1284 21.1495 19.7555C21.3707 19.3825 21.4912 18.9584 21.499 18.5248C21.5068 18.0912 21.4017 17.663 21.1939 17.2823L14.1939 4.32108C13.9787 3.92728 13.6615 3.59867 13.2755 3.36975C12.8895 3.14082 12.449 3.02002 12.0002 3.02002C11.5514 3.02002 11.1109 3.14082 10.7249 3.36975C10.3389 3.59867 10.0217 3.92728 9.80644 4.32108ZM12.3001 17.4998C12.3001 17.6655 12.1658 17.7998 12.0001 17.7998C11.8344 17.7998 11.7001 17.6655 11.7001 17.4998C11.7001 17.3341 11.8344 17.1998 12.0001 17.1998C12.1658 17.1998 12.3001 17.3341 12.3001 17.4998Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </S.Svg>
);

