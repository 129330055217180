import React, { createContext, PropsWithChildren, useEffect, useState } from 'react';

interface ITabsSelection {
  selectedTab: number;
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  isOneActive: boolean;
  setIsOneActive: React.Dispatch<React.SetStateAction<boolean>>;
  isTwoActive: boolean;
  setIsTwoActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialState: ITabsSelection = {
  selectedTab: 1,
  setSelectedTab: () => {},
  isActive: true,
  setIsActive: () => {},
  isOneActive: true,
  setIsOneActive: () => {},
  isTwoActive: true,
  setIsTwoActive: () => {},
};

export const TabsContext = createContext(initialState);

export const TabsContextProvider = ({ children }: PropsWithChildren<object>) => {
  const [selectedTab, setSelectedTab] = useState<number>(1);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [isOneActive, setIsOneActive] = useState<boolean>(true);
  const [isTwoActive, setIsTwoActive] = useState<boolean>(true);

  useEffect(() => {
    if (!isOneActive && !isTwoActive) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  }, [isOneActive, isTwoActive]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    selectedTab,
    setSelectedTab,
    isActive,
    setIsActive,
    isOneActive,
    setIsOneActive,
    isTwoActive,
    setIsTwoActive,
  };
  return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>;
};
