import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const Camera: React.FC<ISvgComponent> = ({ color, height, width, shrinkable, ...rest }) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 24 24"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      fill={color}
      d="M14.977 6.689v.5h.5v-.5zm.5-1.44v-.5Zm3 1.44h-.5v.5h.5zm-13.954 0v-.5zm-2 2h-.5Zm0 8.062h-.5ZM21.477 6.69h.5v-.5h-.5zM14.99 12.72a3.013 3.013 0 0 1-3.013 3.012v1a4.012 4.012 0 0 0 4.013-4.012zm-3.013 3.012a3.012 3.012 0 0 1-3.012-3.012h-1a4.012 4.012 0 0 0 4.012 4.012zM8.965 12.72a3.012 3.012 0 0 1 3.012-3.013v-1a4.013 4.013 0 0 0-4.012 4.013zm3.012-3.013a3.013 3.013 0 0 1 3.013 3.013h1a4.013 4.013 0 0 0-4.013-4.013zm3.5-3.018v-.94h-1v.94zm0-.94-.707-.707a1 1 0 0 0-.293.707zm0 0v-1a1 1 0 0 0-.707.293zm0 0h2.5v-1h-2.5zm2.5 0 .708-.707a1 1 0 0 0-.708-.293zm0 0h1a1 1 0 0 0-.292-.707zm0 0v.94h1v-.94zm-3 .44H4.523v1h10.454zm-10.454 0a2.5 2.5 0 0 0-1.768.732l.707.707a1.5 1.5 0 0 1 1.06-.44zm-1.768.732a2.5 2.5 0 0 0-.732 1.768h1c0-.398.158-.78.439-1.06zm-.732 1.768v8.062h1V8.69Zm0 8.062a2.5 2.5 0 0 0 .732 1.768l.707-.707a1.5 1.5 0 0 1-.44-1.06zm.732 1.768a2.5 2.5 0 0 0 1.768.732v-1a1.5 1.5 0 0 1-1.061-.44zm1.768.732h14.954v-1H4.523Zm14.954 0a2.5 2.5 0 0 0 1.768-.732l-.707-.707a1.5 1.5 0 0 1-1.06.44zm1.768-.732a2.5 2.5 0 0 0 .732-1.768h-1a1.5 1.5 0 0 1-.439 1.06zm.732-1.768V6.69h-1V16.75Zm-.5-10.562h-3v1h3z"
    />
  </S.Svg>
);

