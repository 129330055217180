import React, { useContext, useState } from 'react';
import { IFullRenderContext, IFullRenderProvider } from './interfaces';

export const FullRenderContext = React.createContext<IFullRenderContext>({
  fullRender: false,
  setFullRender: () => {console.log('setFullRender')},
});

export const FullRenderProvider = ({
  children,
  fullRender: fullRenderProps,
}: IFullRenderProvider) => {
  const [fullRender, setFullRenderData] = useState<boolean>(fullRenderProps || false);

  const setFullRender = (isFR: boolean) => {
    setFullRenderData(isFR);
  };

  return (
    <FullRenderContext.Provider
      value={{
        fullRender,
        setFullRender,
      }}
    >
      {children}
    </FullRenderContext.Provider>
  );
};

export const useFullRender = () => useContext(FullRenderContext);
