import type { SVGProps } from 'react';
import { memo } from 'react';

const Arrow = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 1.0354L7.54545 7.58086L1 14.1263"
      stroke={props.stroke || '#333'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default memo<SVGProps<SVGSVGElement>>(Arrow);
