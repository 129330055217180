import { TouchEventHandler, useState } from 'react';

export type ISwipeStatus = 'up' | 'down' | 'none';

export const useSwipeElement = (minSwipeDistance = 50) => {
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);
  const [swipeStatus, setSwipeStatus] = useState<ISwipeStatus>('none');

  const handleSwipeStatus = (distance: number) => {
    if (distance > minSwipeDistance) setSwipeStatus('up');
    if (distance < -minSwipeDistance) setSwipeStatus('down');
    setTimeout(() => setSwipeStatus('none'), 100);
  };

  const onTouchStart: TouchEventHandler<HTMLDivElement> = (e) => {
    setTouchEnd(null); // otherwise, the swipe is triggered even with regular touch events
    setTouchStart(e.targetTouches[0].clientY);
  };

  const onTouchMove: TouchEventHandler<HTMLDivElement> = (e) =>
    setTouchEnd(e.targetTouches[0].clientY);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    handleSwipeStatus(touchStart - touchEnd);
  };

  return { onTouchStart, onTouchMove, onTouchEnd, swipeStatus };
};
