import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';
import { IOptions } from '@vfit/shared/models';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 400;
  font-family: ${fonts.exbold};
  color: ${colors.$262626};
  text-align: left;
  height: 100%;

  .iframe-holder {
    height: 100%;

    svg {
      position: fixed;
      top: 50%;
      left: 50%;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    padding: 8px 0 0;
  }
`;

export const Title = styled.div<IOptions>`
  ${pxToCssFont(30, 38)}
  margin-bottom: 32px;

  @media (min-width: ${breakpoints.tablet}) {
    text-align: ${({ textAlign }) => textAlign || 'center'};
    margin-bottom: 24px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    text-align: left;
    ${pxToCssFont(36, 45)}
    margin-bottom: 32px;
  }
`;

export const Description = styled.div`
  div {
    margin: 0 auto;
    font-family: ${fonts.regular};
    ${pxToCssFont(18, 24)}
    text-align: left;

    @media (min-width: ${breakpoints.tablet}) {
      text-align: center;
    }

    @media (min-width: ${breakpoints.desktop}) {
      text-align: left;
    }
  }
`;
