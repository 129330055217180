import Script from 'next/script';
import {LoggerInstance} from "@vfit/shared/data-access";

const OneTrustCookieConsents = () => {
  LoggerInstance.debug('OneTrustCookieConsents ^^^^^^^^^^');

  return (
      <Script
        id="wa-onetrust-script"
        data-domain-script="4dd15f2d-c736-4d10-a002-4db051f7f24d"
        strategy="beforeInteractive"
        async
        defer
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      />
  );
};

export default OneTrustCookieConsents;
