import { ITrackErrorRes, TEventName, TrackingOptions, PageProduct } from '@vfit/shared/data-access';
import { IDeactivationForm, IOptions } from '@vfit/shared/models';
import {
  ICMSForm,
  IErrorFormApi,
  ISubmitOutput,
  IClickToCallMeData,
  ICmsAppClickToCallChoice,
} from '@vfit/shared/models';

export interface IFrameDetail {
  title?: string;
  url: string;
  onClose?: () => void;
  id?: string;
  fullScreenExtended?: boolean;
  fullscreen?: boolean;
  showIcon?: boolean;
  showLogo?: boolean;
  icon?: string;
  variant?: 'mva' | 'sunrise';
}

export interface IProductLead {
  category?: string | string[];
  id?: string | string[];
  name?: string | string[];
  price?: string | string[];
  quantity?: string;
}

export interface ILeadModalDetail {
  title?: string;
  form?: ICMSForm;
  product?: IProductLead;
  onClose?: () => void;
  onError?: (submitErrorOutput: ISubmitOutput[], errorCmsApi?: IErrorFormApi) => void;
  onSuccess?: (submitOutput: ISubmitOutput) => void;
  id?: string;
  trackingOption?: TrackingOptions;
}

export interface ErrorDetail {
  errorCode?: string;
  fullScreenExtended?: boolean;
  severity?: Severity;
  title?: string;
  subtitle?: string;
  message?: string;
  messageOnTop?: boolean;
  actionEvent?: () => void;
  secondActionEvent?: () => void;
  onClose?: () => void;
  actionText?: string;
  secondButtonText?: string;
  event?: TEventName;
  event_label?: string;
  options?: IOptions;
  opts?: TrackingOptions;
  disableTrack?: boolean;
  url?: string;
  fullscreen?: boolean;
  showIcon?: boolean;
  showLogo?: boolean;
  icon?: string;
  variant?: 'mva' | 'sunrise';
  keepOpenModalAfterAction?: boolean;
  hideTitle?: boolean;
  disableCloseModalOnClickAction?: boolean;
  isButtonSticky?: boolean;
  isDisabledDatadog?: boolean;
  trackError?: ITrackErrorRes;
  invertButtons?: boolean;
}

export enum Severity {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

export interface IToastDetail {
  title: string;
  timer?: number;
}

export interface IVolaModalDetail {
  id?: string;
  title?: string;
  url: string;
  cmsClickToCallData?: IClickToCallMeData;
  onClose?: () => void;
  onError?: (submitErrorOutput: ISubmitOutput[], errorCmsApi?: IErrorFormApi) => void;
  onSuccess?: (submitOutput: ISubmitOutput) => void;
  taggingOption?: TrackingOptions;
  pageProduct?: PageProduct;
  cmsAppClickToCallChoice?: ICmsAppClickToCallChoice;
  defaultNumber?: string;
}

export interface IDeactivationModalDetail {
  form?: IDeactivationForm;
  id?: string;
}

export interface IHtmlModalDetail {
  target: string;
  htmlDesktop: string;
  id?: string;
  onClose?: () => void;
  htmlTablet?: string;
  htmlMobile?: string;
  isEnabledAlwaysShown?: boolean;
  isEnabled?: boolean;
  timerToDisplay?: number;
}
