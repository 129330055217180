export const fontSizes = {
  $12: 12,
  $14: 14,
  $16: 16,
  $18: 18,
  $20: 20,
  $22: 22,
  $24: 24,
  $28: 28,
  $30: 30,
  $32: 32,
  $36: 36,
  $42: 42,
  $44: 44,
  $52: 52,
  $64: 64,
  $70: 70,
} as const;

type FontSizesKeys = keyof typeof fontSizes;
export type FontSizes = (typeof fontSizes)[FontSizesKeys];

export const lineHeights = {
  xxxxs: 1,
  xxxs: 1.1,
  xxs: 1.15,
  xs: 1.2,
  s: 1.25,
  m: 1.3,
  l: 1.35,
  xl: 1.4,
  xxl: 1.45,
  xxxl: 1.5,
  xxxxl: 1.55,
} as const;

type LineHeightsKeys = keyof typeof lineHeights;
export type LineHeights = (typeof lineHeights)[LineHeightsKeys];

