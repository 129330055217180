import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const Search: React.FC<ISvgComponent> = ({ color, height, width, shrinkable, ...rest }) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 26.333 26.333"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m16.432 16.432 9.401 9.401m-6.666-16a9.333 9.333 0 1 1-18.667 0 9.333 9.333 0 0 1 18.667 0z"
    />
  </S.Svg>
);

