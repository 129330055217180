import {
  IActionCustom,
  IActionSlide,
  IActionStyle,
  IActionType,
  IClickToCallMeData,
  ICmsAppClickToCallChoice,
  IErrorFormApi,
  ISubmitOutput,
} from '@vfit/shared/models';
import { AppMobile, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { QueryClient } from 'react-query';
import { TrackingOptions, volaModalManager } from '@vfit/shared/data-access';
//import { IAppSession } from '@vfit/consumer/data-access';
import { openPopup } from './openPopup';
import { openLeadModal } from './openLeadModal';
import { IGetAllCmsForm, manageErrorLeadPlatform, manageSuccessLeadPlatform } from './cmsForm';
import { IGetAllDeactivationForm, openDeacivationModal } from './openDeacivationModal';

export const getVariantByActionStyle = (
  actionStyle?: IActionStyle | string
): 'primary' | 'secondary' | 'tertiary' => {
  if (!actionStyle) return 'primary';
  switch (actionStyle) {
    case IActionStyle.PRIMARY:
    case IActionStyle.PRIMARY.toString():
    case 'primary':
      return 'primary';
    case IActionStyle.SECONDARY:
    case IActionStyle.SECONDARY.toString():
    case 'secondary':
      return 'secondary';
    case IActionStyle.TERTIARY:
    case IActionStyle.TERTIARY.toString():
    case 'tertiary':
      return 'tertiary';
    default:
      return 'primary';
  }
};

export const getButtonActionByActionType = (
  actionSlide: IActionSlide | undefined,
  push?,
  queryClient?: QueryClient
) => {

  if (!actionSlide) return;
  const buttonType = actionSlide?.type ? +actionSlide.type : 0;
  switch (buttonType) {
    case IActionType.CUSTOM_URL:
      if (actionSlide?.url) {
        window.open(actionSlide.url, actionSlide?.isBlank === 'true' ? '_blank' : '_self');
      }
      break;
    case IActionType.COVERAGE_TOOL:
      break;
    case IActionType.CALL_ME_NOW:
      if (actionSlide?.callMeNow) openPopup(actionSlide.callMeNow);
      else if (actionSlide?.url) openPopup(actionSlide.url);
      break;
    case IActionType.CALL_ME_NOW_SUNRISE:
      if (actionSlide?.title && actionSlide?.url) {
        let defaultNumber = '';
        let taggingOption: TrackingOptions;
        let cmsAppClickToCallChoice: ICmsAppClickToCallChoice = {};
        let cmsClickToCallData: IClickToCallMeData | null = null;
        if (AppMobile.checkIsApp()) {
          // TODO: Remove this controls and move in consumer/business.. libs
          const appSession = getFromLocalStorageByKey('appSession');
          const isMobileType = actionSlide?.localUrl === '$MOBILE';
          taggingOption = {
            product_name: 'smart backup',
            product_category: 'addon',
            product_id: '0054123',
            product_price: [''],
          };
          defaultNumber = isMobileType
            ? appSession?.value
            : appSession?.items?.find((el) => el?.type?.toLowerCase() === 'landline')?.value;
          cmsClickToCallData = queryClient.getQueryData('getClickToCallAppConfigurations');
          cmsAppClickToCallChoice = {
            title: "Attiva l'offerta",
            description: `<span>Confermi di voler attivare l'offerta sul numero <b>${defaultNumber}</b>?</span>`,
          };
        }
        volaModalManager.handleVolaModal({
          url: actionSlide?.url || '',
          cmsClickToCallData: { ...(cmsClickToCallData as IClickToCallMeData) },
          defaultNumber,
          cmsAppClickToCallChoice,
          ...(taggingOption && { taggingOption }),
        });
      }
      break;
    case IActionType.LEAD_MODAL:
      if (queryClient) {
        const allLeads = queryClient?.getQueryData('getAllLeads') as IGetAllCmsForm;
        // form name
        const ref = actionSlide?.localUrl || '';
        const forms = allLeads?.[ref].forms;
        if (forms && push) {
          openLeadModal({
            form: forms,
            onSuccess: (submitOutput: ISubmitOutput) =>
              manageSuccessLeadPlatform(submitOutput, push, queryClient),
            onError: (submitOutput: ISubmitOutput[], errorCmsApi?: IErrorFormApi) =>
              manageErrorLeadPlatform(submitOutput, errorCmsApi, push, queryClient),
          });
        }
      }
      break;
    case IActionType.DEACTIVATION_MODAL:
      if (queryClient) {
        const allDeactivation = queryClient?.getQueryData(
          'getAllDeactivationModal'
        ) as IGetAllDeactivationForm;
        const ref = actionSlide?.localUrl || '';
        const forms = allDeactivation?.[ref];
        if (forms && push) {
          openDeacivationModal({
            form: forms,
          });
        }
      }
      break;
    case IActionType.LOCAL_URL:
      if (actionSlide?.localUrl && push)
        push(`/${actionSlide.localUrl}`, undefined, { shallow: true });
      break;
    default:
      break;
  }
};

/**
 * Check if string is JSON
 * @param str
 */
export const isJSON = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const navigationButtonTypes = [IActionType.CUSTOM_URL, IActionType.LOCAL_URL];

export const getActionUrlByActionType = (btn: IActionCustom) =>
  btn.url && btn.type && navigationButtonTypes.includes(Number(btn.type))
    ? btn.localUrl || btn.url
    : undefined;

