import * as S from './errorMessage.style';

interface Props {
  error?: string;
}

export const ErrorMessage = ({ error }: Props) => {
  if (!error) return null;
  return (
    <div>
      <S.StyledErrorMessage>{error}</S.StyledErrorMessage>
    </div>
  );
};
