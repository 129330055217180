import { useQueries, useQuery } from 'react-query';
import { UseQueryConfig } from './model';

export const handleUseQuery = (config: UseQueryConfig) => {
  const { queryKey, queryFn, options } = config;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery(queryKey, queryFn, {
    retry: 0,
    ...options,
  });
};
export default handleUseQuery;

export const handleUseQueryes = (queries: UseQueryConfig[]) => {
  const queryNames: any[] = [];
  const queriesFormatted: object[] = queries.map((query) => {
    const queryKey = Array.isArray(query.queryKey) ? query.queryKey[0] : query.queryKey;
    queryNames.push(queryKey);
    return {
      queryKey: query.queryKey,
      queryFn: query.queryFn,
      ...query.options,
    };
  });
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const resParallelQueries = useQueries(queriesFormatted);
  const formattedRes = resParallelQueries.map((queryRes, index) => ({
    [queryNames[index]]: { ...queryRes },
  }));
  return Object.assign({}, ...formattedRes);
};
