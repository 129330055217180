import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const Leaf: React.FC<ISvgComponent> = ({ color, height, width, shrinkable, ...rest }) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 18 18"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      d="M1.46484 17.2875C4.50853 12.4949 8.41407 8.30799 12.9836 4.93873M17.4273 4.09001C17.1711 9.81751 12.3623 14.3625 6.62985 14.3C5.70018 14.2905 4.77572 14.1598 3.87985 13.9113C3.48072 12.3181 3.45385 10.6543 3.80133 9.04907C4.1488 7.4438 4.86129 5.94012 5.88355 4.65459C6.90581 3.36905 8.21038 2.33621 9.69614 1.63611C11.1819 0.936011 12.8089 0.587472 14.4511 0.617512H17.4248L17.4273 4.09001Z"
      stroke={color}
      strokeLinecap="round"
    />
  </S.Svg>
);
