import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const Calendar: React.FC<ISvgComponent> = ({
  color,
  height,
  width,
  shrinkable,
  ...rest
}) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 18 18"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      fill={color}
      d="M6.167 1.083a.5.5 0 0 0-1 0zm-1 2.5a.5.5 0 0 0 1 0zm7.666-2.5a.5.5 0 0 0-1 0zm-1 2.5a.5.5 0 0 0 1 0zM1.917 15.25h-.5zm1.666-12.5v-.5zm12.5 0h.5v-.5h-.5zm0 3.03a.5.5 0 1 0 0-1zm-14.166-1a.5.5 0 0 0 0 1zm4.331 5.317a.5.5 0 1 0 .185.983zm.52.452v.5zm0 3.477v-.5h-.012zm-1.192-1.152a.5.5 0 1 0-.86.51zm-.433-4.957a.5.5 0 0 0 0 1zm3.364.5.354.353a.5.5 0 0 0-.354-.853zm-2.526 1.82a.5.5 0 1 0 .707.706zm4.505-1.346a.5.5 0 1 0 .53.848zm2.06-.698h.5a.5.5 0 0 0-.765-.424zm-.5 5.833a.5.5 0 1 0 1 0zM5.166 1.083v2.5h1v-2.5zm6.667 0v2.5h1v-2.5zm3.75 14.167c0 .31-.123.606-.341.825l.707.707c.406-.406.634-.957.634-1.532zm-.341.825a1.167 1.167 0 0 1-.825.342v1c.574 0 1.125-.229 1.532-.635zm-.825.342H3.583v1h10.834Zm-10.834 0c-.31 0-.606-.123-.825-.342l-.707.707c.407.406.958.635 1.532.635zm-.825-.342a1.167 1.167 0 0 1-.341-.825h-1c0 .575.228 1.126.634 1.532zm-.341-.825V4.417h-1V15.25zm0-10.833c0-.31.123-.606.341-.825l-.707-.707a2.167 2.167 0 0 0-.634 1.532zm.341-.825c.22-.22.516-.342.825-.342v-1c-.574 0-1.125.228-1.532.635zm.825-.342h12.5v-1h-12.5Zm12-.5v12.5h1V2.75Zm.5 2.03H1.917v1h14.166Zm-9.65 6.3c.11-.02.223-.031.335-.03l.001-1c-.174-.001-.349.015-.52.047zm.336-.03c.883 0 1.463.598 1.463 1.181h1c0-1.275-1.178-2.182-2.463-2.182zm1.463 1.181c0 .623-.622 1.295-1.463 1.295v1c1.33 0 2.463-1.059 2.463-2.295zm-1.476 1.295a1.333 1.333 0 0 1-.679-.166l-.483.875c.363.201.773.301 1.187.291zm-.679-.166a1.335 1.335 0 0 1-.501-.486l-.86.51c.212.357.515.65.878.851zm-.934-4.443h3.364v-1H5.143zm3.01-.854-2.172 2.173.707.707L8.861 8.77zm2.863 1.676 1.795-1.122-.53-.848-1.795 1.122zm1.03-1.546v5.833h1V8.193z"
    />
  </S.Svg>
);

