import { useCallback, useEffect, useRef, useState } from 'react';
import { computeDatalayer, trackOptions } from './datalayer';
import { shouldTrack, retrieveBasePageName } from './utils';
import { LoggerInstance } from '../logger';
import { IDatalayer, TTrackingType, ITracking, ITrackView, ITrackingReturn } from './models';

const isDevLocal = process.env['NODE_ENV'] === 'development';

declare global {
  interface Window {
    wa_gc?: (datalayer: IDatalayer, event: 'view' | 'link') => void;
    utag_data?: IDatalayer;
    utag_last_tracked_data?: IDatalayer;
    utag?: {
      view: (datalayer: IDatalayer) => void;
      link: (datalayer: IDatalayer) => void;
    };
  }
}

const track = (datalayer: IDatalayer, trackingType?: TTrackingType) => {
  const normalizedDatalayer = {
    ...datalayer,
    ...(datalayer?.product_name?.length && {
      page_name: datalayer?.page_name?.replace(new RegExp(`${datalayer.product_name}:`, 'i'), ''),
      link_name: datalayer?.link_name?.replace(new RegExp(`${datalayer.product_name}:`, 'ig'), ''),
    }),
  };
  if (trackingType !== 'link') {
    window.utag_data = normalizedDatalayer;
  }
  if (isDevLocal) {
    LoggerInstance.debug('Tracking datalayer --> ', normalizedDatalayer);
  } else if (trackingType === 'link' && window.utag?.link) {
    window.utag?.link(normalizedDatalayer);
  } else if (window.utag?.view) {
    window.utag.view(normalizedDatalayer);
  } else {
    document.addEventListener(
      'utag_ready',
      () => {
        if (window.utag?.view) window.utag.view(normalizedDatalayer);
      },
      { once: true }
    );
  }

  window.utag_last_tracked_data = normalizedDatalayer;
};

const useTracking = ({
  event,
  event_label,
  opts,
  pageProduct,
  cartProduct,
  visitorTrackingOpts,
  disableInitialTrack,
  page_country,
  page_language,
  page_locale,
}: ITracking): ITrackingReturn => {
  const [tracked, setTracked] = useState(false);
  const [datalayer, setDatalayer] = useState<IDatalayer | null>(null);
  const [isReadyTrackView, setIsReadyTrackView] = useState(false);
  const trackViewRef = useRef<(p: ITrackView) => void>();

  const trackView = ({
    channel,
    desc,
    event_name,
    event_label_track,
    event_category,
    event_action,
    link_name,
    page_error,
    page_error_code,
    page_type,
    page_name_override,
    ignore_base_page_name,
    form_error,
    tracking_type,
    checkout_consents,
    journey_name,
    journey_type,
    page_section,
    page_subsection,
    page_browser_system,
    page_environment,
    product_id,
    product_name,
    product_category,
    product_price,
    product_quantity,
    visitor_type,
    versatile_prop,
    transaction_id,
  }: ITrackView) => {
    let basePageProductName = '';
    if (pageProduct?.product_name) {
      if (Array.isArray(pageProduct?.product_name)) {
        basePageProductName = pageProduct?.product_name?.[0] || '';
      } else {
        basePageProductName = (pageProduct?.product_name as string) || '';
      }
    }
    if (!basePageProductName && product_name) {
      if (Array.isArray(product_name)) {
        basePageProductName = product_name?.[0] || '';
      } else {
        basePageProductName = (product_name as string) || '';
      }
    }

    const basePageName = !ignore_base_page_name
      ? `${retrieveBasePageName(cartProduct, basePageProductName)}:`
      : '';

    const pageName = `${basePageName}${
      tracking_type === 'link'
        ? page_name_override || event_label || event?.[0]
        : page_name_override || event_label_track || event_name
    }`;
    const linkName =
      tracking_type === 'link' && `${basePageName}${event_label_track || event_name}`;

    const datalayerTrackView: IDatalayer = {
      ...datalayer,
      page_name: pageName,
      ...(event_name && { event_name: [event_name] }),
      ...(linkName && { link_name: linkName }),
      ...(event_label_track && { event_label: event_label_track }),
      ...(event_category && { event_category }),
      ...(event_action && { event_action }),
      ...(link_name && { link_name }),
      ...(page_error && { page_error }),
      ...(page_error_code && { page_error_code }),
      ...(page_type && { page_type }),
      ...(form_error && { form_error }),
      ...(checkout_consents && { checkout_consents }),
      ...(journey_name && { journey_name }),
      ...(journey_type && { journey_type }),
      ...(page_section && { page_section }),
      ...(page_subsection && { page_subsection }),
      ...(page_browser_system && { page_browser_system }),
      ...(product_id && { product_id }),
      ...(product_name && { product_name }),
      ...(product_category && { product_category }),
      ...(product_price && { product_price }),
      ...(product_quantity && { product_quantity }),
      ...(page_environment && { page_environment }),
      ...(visitor_type && { visitor_type }),
      ...(versatile_prop !== undefined && { versatile_prop }),
      ...(channel && { channel }),
      ...(desc && { desc }),
      ...(transaction_id && { transaction_id }),
    };
    track(datalayerTrackView, tracking_type);
  };

  const trackWithNewDatalayer = useCallback(
    (newDatalayer: ITracking & { notOverrideDL?: boolean }) => {
      const tempDatalayer = computeDatalayer(
        newDatalayer.event,
        newDatalayer.event_label,
        newDatalayer.opts,
        newDatalayer.pageProduct,
        newDatalayer.cartProduct,
        newDatalayer.visitorTrackingOpts
      );
      track(tempDatalayer);
      if (!newDatalayer.notOverrideDL) setDatalayer(tempDatalayer);
    },
    []
  );

  useEffect(() => {
    if (!tracked && datalayer && !disableInitialTrack && shouldTrack(event?.[0])) {
      track(datalayer);
      setTracked(true);
    }
    if (datalayer) {
      trackViewRef.current = trackView;
      setIsReadyTrackView(true);
    }
  }, [datalayer]);

  useEffect(() => {
    setDatalayer(
      computeDatalayer(
        event,
        event_label,
        opts,
        pageProduct,
        cartProduct,
        visitorTrackingOpts,
        page_country,
        page_language,
        page_locale
      )
    );
  }, []);

  return {
    trackView: (track_view: ITrackView) => trackViewRef?.current?.(track_view),
    trackWithNewDatalayer,
    isReadyTrackView,
  };
};

export default useTracking;

export const tracking = (datalayer: IDatalayer, trackingType: TTrackingType): IDatalayer => {
  const trackingData = {
    ...trackOptions(
      datalayer.event_name,
      datalayer.event_label,
      undefined,
      Array.isArray(datalayer.product_name)
        ? datalayer.product_name?.[0] || ''
        : (datalayer.product_name as string) || ''
    ),
    ...datalayer,
  };
  track(trackingData, trackingType);
  return trackingData;
};
