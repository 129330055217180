import { LinkProps } from 'next/link';

export type SvgPosition = 'left' | 'right';

// https://digital.interhyp.de/2022/02/17/strict-unions-in-typescript-a-practical-approach/
// Not my solution, typescript behaves quirkly when an union with common keys are built
// Infact it allows the excluded key to be declared as well
// type A = {
//   a: string;
//   b: string;
// }
// type B = {
//   b: string;
//   c: string;
// }
// type C = A | B
// const c: C = {a: "a", b: "c", c: "a"}
// the constant c is valid from typescript POV, but in reality that should not be the case
type UnionKeys<T> = T extends T ? keyof T : never;

type StrictUnionHelper<T, TAll> = T extends any
  ? T & Partial<Record<Exclude<UnionKeys<TAll>, keyof T>, never>>
  : never;

export type StrictUnion<T> = StrictUnionHelper<T, T>;

export const areAnchorPropsOf = <T>(props: any): props is T =>
  // anchor tag props
  (props.download !== undefined ||
    props.href !== undefined ||
    props.hrefLang !== undefined ||
    props.media !== undefined ||
    props.ping !== undefined ||
    props.rel !== undefined ||
    props.target !== undefined ||
    props.referrerPolicy !== undefined ||
    props.as !== undefined ||
    props.locale !== undefined ||
    props.prefetch !== undefined ||
    props.replace !== undefined ||
    props.scroll !== undefined ||
    props.shallow !== undefined ||
    props.soft !== undefined ||
    props.disableAppMobileOpenInBrowser !== undefined) &&
  // button props
  props.form === undefined &&
  props.autoFocus === undefined &&
  props.formAction === undefined &&
  props.formEncType === undefined &&
  props.formMethod === undefined &&
  props.formNoValidate === undefined &&
  props.formTarget === undefined &&
  props.value === undefined;

export interface IUseCta<T> {
  _href?: LinkProps['href'];
  _onClick?: React.MouseEventHandler<T>;
  _onMouseEnter?: React.MouseEventHandler<T>;
  _onMouseLeave?: React.MouseEventHandler<T>;
  disabled?: boolean;
  disableAppMobileOpenInBrowser?: boolean;
}

