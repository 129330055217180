import { pxToRem } from '@vfit/shared/themes';
import { ISvgComponent } from './svg.models';
import { svgObj } from './svgObj';

export type SvgNames = keyof typeof svgObj;

export interface ISvg extends ISvgComponent {
  name: SvgNames;
}

/**
 * Suggest: use only one between width and height
 * to set the svg's dimensions
 */

/**
 * Svg component.
 * SUGGEST: use only one between width and height
 * to set the svg's dimensions
 * @param name (mandatory)
 * @returns
 */
export const Svg: React.FC<ISvg> = ({
  name,
  color = '#0d0d0d',
  secondColor = '#e60000',
  height,
  width,
  shrinkable,
  ...rest
}) => {
  const El = svgObj[name];
  const h = height !== undefined ? pxToRem(height) : undefined;
  const w = width !== undefined ? pxToRem(width) : undefined;

  return (
    <El
      color={color}
      secondColor={secondColor}
      height={h}
      width={w}
      shrinkable={shrinkable}
      {...rest}
    />
  );
};

