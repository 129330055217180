import DOMPurify from 'isomorphic-dompurify'

/**
 * Sanitize an array of HTML text
 * @param text (array of html)
 * @returns
 * Example: const [pDescription, pFootnote] = purify([description, footnote]);
 * Then in component you can use:
 * <Description
 *  dangerouslySetInnerHTML={{
 *    __html: pDescription,
 *  }}
 * />
 * <Footnote
 *  dangerouslySetInnerHTML={{
 *    __html: pFootnote,
 *  }}
 * />
 */
export const purify = (
  text: (string | undefined)[],
  remapFnc?: (textPurified: string) => string
) => {
  DOMPurify.addHook('afterSanitizeAttributes', (node) => {
    // Check for security issue
    // Set all elements owning target to rel=noopener
    // https://developer.chrome.com/docs/lighthouse/best-practices/external-anchors-use-rel-noopener/
    if ('target' in node) {
      node.setAttribute('rel', 'noopener');
    }
  });

  const textToReturn = text.map((t) =>
    t ? DOMPurify.sanitize(t, { ADD_ATTR: ['target'] }) : undefined
  );

  if (!remapFnc) return textToReturn;
  return textToReturn?.map((textEl) => remapFnc(textEl || ''));
};

