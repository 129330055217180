import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const ThumbDown: React.FC<ISvgComponent> = ({ color, height, width, fill, ...rest }) => (
  <S.Svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M5.21719 9.82461C6.41641 11.5074 10.0797 15.8246 10.0797 17.7707C9.92969 19.6309 10.4547 19.4543 10.4797 19.5059C15.1977 19.3809 10.7648 12.2145 12.25 11.7395C13.3625 11.8809 16.4078 12.7199 17.6859 11.852C18.9648 10.9848 18.7648 10.1168 18.4148 9.13555C19.7648 7.66992 18.375 6.16211 18.375 6.16211C18.375 6.16211 19.15 4.88086 17.6812 3.18711C18.3227 1.62305 17.1375 0.505859 15.6977 0.505859H2.90156"
      fill={fill}
      stroke={color}
    />
    <path
      d="M6 8.00586C6 9.38633 4.88047 10.5059 3.5 10.5059H2.5C1.11953 10.5059 0 9.38633 0 8.00586V3.00586C0 1.62539 1.11953 0.505859 2.5 0.505859H6V8.00586Z"
      fill={fill}
      stroke={color}
    />
  </S.Svg>
);
