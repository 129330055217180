import { css } from 'styled-components';
import { breakpoints } from '../../breakpoints';
import { cssfonts } from '../cssfonts';

export const button1 = {
  regular: css`
    ${cssfonts.regular}
    font-size: 1rem; /* 16px */
    line-height: 1; /* differs from DS typography */

    @media (min-width: ${breakpoints.tablet}) {
      font-size: 1.125rem; /* 18px */
    }
  `,
} as const;

