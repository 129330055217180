import { IHtmlModalDetail } from './interfaces';

export class IHtmlModalService {
  private customHtmlModalEvent: CustomEvent | undefined;

  /**
   * Handle IFrame
   */
  public handleHtmlModal(htmlModalDetail?: IHtmlModalDetail): void {
    this.customHtmlModalEvent = new CustomEvent('htmlModalServiceNotificationEvent', {
      detail: htmlModalDetail,
    });
    document.dispatchEvent(this.customHtmlModalEvent);
  }
}
