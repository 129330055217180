import { checkWindow } from '@vfit/shared/data-access';
import { capitalize } from 'lodash-es';
import { CartProduct } from '@vfit/shared/data-access';

const retrieveBasePageName = (cart_product?: CartProduct, product_name?: string) => {
  if (!checkWindow()) return '';

  const hostname = window.location.hostname.split('.');
  const pageType = hostname[0] === 'www' ? hostname[1] : hostname[0];
  const path = window.location.pathname
    .split('/')
    .filter((x) => Boolean(x) && !x.startsWith('['))
    .map((x) => capitalize(x))
    .join(':');

  const pathWithoutParams = path?.split('?');

  const pageNameToReturn = pageType
    ? `VFIT:${path ? `${pageType}:${path}` : pageType}`
    : `VFIT:${pathWithoutParams?.[0] || ''}`;

  const prod_name_in_url =
    cart_product?.cart_product_name?.toLowerCase() || product_name?.toLowerCase();
  if (prod_name_in_url) {
    return pageNameToReturn?.replace(new RegExp(`${prod_name_in_url}:`, 'ig'), '');
  }
  return pageNameToReturn;
};

const retrievePageChannel = (pageType: string): string => {
  switch (pageType.toLowerCase()) {
    case 'privati':
    case 'landing':
    case 'vodafone':
      return 'consumer';
    case 'business' || 'grandiaziende':
      return 'business';
    default:
      return 'na_in_test_env';
  }
};

const shouldTrack = (event: string): boolean => {
  const forceTracking = event === 'tool_start' || event === 'tool_end';
  const queryString = new URLSearchParams(window?.location?.search);
  const queryParamsDisabled = queryString.get('act');
  return !queryParamsDisabled || forceTracking;
};

export { shouldTrack, retrievePageChannel, retrieveBasePageName };
