import {
  CMS,
  DXL,
  NEXT,
  WEBAPI_SERVERLESS,
  DXL_JOURNEY_APP,
  AWSAPI_CONSUMER,
  DXLCLOUD_CONSUMER,
  DXL_GATEWAY_CLOUD,
} from './config';
import { ClientOptions } from './model';

export const options: ClientOptions = {
  DXL,
  NEXT,
  CMS,
  WEBAPI_SERVERLESS,
  DXL_JOURNEY_APP,
  AWSAPI_CONSUMER,
  DXLCLOUD_CONSUMER,
  DXL_GATEWAY_CLOUD,
};
