import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const Copy: React.FC<ISvgComponent> = ({ color, height, width, shrinkable, ...rest }) => (
  <S.Svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      d="M6.11125 13.8888H2.75875C1.52875 13.8888 0.53125 12.8913 0.53125 11.6613V2.75875C0.53125 1.52875 1.52875 0.53125 2.75875 0.53125H11.6613C12.8913 0.53125 13.8888 1.52875 13.8888 2.75875V6.11125M8.33875 6.11125H17.2412C18.4715 6.11125 19.4688 7.10854 19.4688 8.33875V17.2412C19.4688 18.4715 18.4715 19.4688 17.2412 19.4688H8.33875C7.10854 19.4688 6.11125 18.4715 6.11125 17.2412V8.33875C6.11125 7.10854 7.10854 6.11125 8.33875 6.11125Z"
      stroke={color}
      strokeMiterlimit="10"
    />
  </S.Svg>
);
