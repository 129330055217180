import { HttpClient } from './client';

export { headersNext, mode, credentials, prefixUrl } from './config';

export const nextClient = new HttpClient('NEXT');
export const cmsClient = new HttpClient('CMS');
export const dxlClient = new HttpClient('DXL');
export const dxlClientJourneyApp = new HttpClient('DXL_JOURNEY_APP');
export const serverlessClient = new HttpClient('WEBAPI_SERVERLESS');
export const awsConsumerClient = new HttpClient('AWSAPI_CONSUMER');
export const dxlConsumerCloudClient = new HttpClient('DXLCLOUD_CONSUMER');
export const dxlGatewayCloud = new HttpClient('DXL_GATEWAY_CLOUD');

export default HttpClient;

export * from './client';
export * from './model';
export * from "./utils"
