import { keyframes } from 'styled-components';

/**
 * Extract from https://github.com/animate-css/animate.css/blob/main/animate.css
 * Version - 4.1.1
 */
export const fadeOutUp = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
`;

