import { IDeactivationModalDetail } from './interfaces';

export class IDeactivationModalService {
  private customDeactivateModalEvent: CustomEvent | undefined;

  /**
   * Handle Lead Modal
   */
  public handleDeactivateModal(deactivateDetail?: IDeactivationModalDetail): void {
    this.customDeactivateModalEvent = new CustomEvent('deactivateModalNotificationEvent', {
      detail: deactivateDetail,
    });
    document.dispatchEvent(this.customDeactivateModalEvent);
  }
}
