import React, { ErrorInfo } from 'react';
import { errorManager, ErrorService, LoggerInstance, TEventName } from '@vfit/shared/data-access';
import { checkWindow } from '@vfit/shared/data-access';
import { GenericDetail, VfModal } from '@vfit/shared/components';
import { IErrorBoundary } from './errorBoundary.models';

class ErrorBoundary extends React.Component<React.PropsWithChildren, { hasError: boolean }> {
  constructor(props: IErrorBoundary) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    const handleOnClose = () => {
      window.location.href = '/';
    };
    let pageError = `errorBoundary`;
    if (error?.name) pageError += `_${error?.message?.toLocaleLowerCase()?.replace(/ /g, '_')}_blk`;
    const trackError = {
      event_name: 'page_error' as TEventName,
      event_label_track: 'page_error',
      event_category: 'error',
      page_error: pageError,
      page_error_code: '',
      page_type: 'error page',
    };
    errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
      title: 'OPS!',
      message: 'Si è verificato un errore, riprovare in seguito',
      actionText: 'Chiudi',
      onClose: handleOnClose,
      actionEvent: handleOnClose,
      trackError,
      disableTrack: true,
    });
    return {
      hasError: true,
    };
  }

  override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    LoggerInstance.log({ error, errorInfo });
  }

  override render() {
    const { hasError } = this.state;
    const { children } = this.props;
    const handleOnClose = () => {
      window.location.href = '/';
    };
    if (hasError) {
      return (
        <VfModal isOpen={hasError} handleClose={handleOnClose}>
          <GenericDetail
            title="OPS!"
            description="La pagina non è al momento disponibile, riprovare in seguito"
            submitButtonLabel="Chiudi"
            submitButtonAction={handleOnClose}
            secondButtonLabel="Effettua l'accesso"
            secondButtonAction={() => {
              if (checkWindow()) {
                window.open(
                  'https://www.vodafone.it/area-utente/fai-da-te/Common/PaginaUnicadiLogin.html',
                  '_self'
                );
              }
            }}
          />
        </VfModal>
      );
    }
    return children;
  }
}

export default ErrorBoundary;

