import { useFormContext } from 'react-hook-form';
import { CustomInputProps } from '@vfit/shared/models';
import { ErrorMessage } from '../ErrorMessage/errorMessage';
import * as S from '../../cmsForm.style';

export const CustomInput = ({
  name,
  label,
  isHidden,
  isColMiddle,
  isDisabled,
  isPendingRequest,
  ...props
}: CustomInputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const error = errors[name]?.message as string | undefined;
  const id = `${name}-${props.type}-${label}`;

  return (
    <S.StyledInputContainer isColMiddle={isColMiddle} isHidden={isHidden}>
      <S.StyledFieldset isDisabled={isDisabled} isError={!!error}>
        <S.StyledInput
          {...register(name)}
          {...props}
          id={id}
          placeholder={' '}
          disabled={isDisabled || isPendingRequest}
        />
        {label && <S.StyledLegend htmlFor={id}>{label}</S.StyledLegend>}
      </S.StyledFieldset>
      <div>
        <ErrorMessage error={error} />
      </div>
    </S.StyledInputContainer>
  );
};
