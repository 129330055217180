import { css } from 'styled-components';
import { breakpoints } from '../../breakpoints';
import { cssfonts } from '../cssfonts';

export const h1 = {
  extrabold: css`
    ${cssfonts.exbold}
    font-size: 2.625rem; /* 42px */
    line-height: 1.15; /* 42/48 */

    @media (min-width: ${breakpoints.tablet}) {
      font-size: 4rem; /* 64px */
      line-height: 1.15; /* 64/72 */
    }

    @media (min-width: ${breakpoints.desktop}) {
      font-size: 4.375rem; /* 70px */
      line-height: 1.1; /* 70/76 */
    }
  `,
} as const;

