import { checkWindow } from '@vfit/shared/data-access';
import { handleEnv } from '@vfit/shared/data-access';

const getLivePreviewMedia = (fileName: string, folderName: 'images' | 'videos') => {
  const isInLivePreview = checkWindow() && localStorage?.getItem('isLP');
  const appType: string = handleEnv('NEXT_PUBLIC_APP_TYPE') || 'consumer';
  const cmsUrl = handleEnv('NEXT_PUBLIC_CMS');
  if (isInLivePreview && appType && cmsUrl) {
    let baseUrl = new URL(cmsUrl).origin;
    switch (appType) {
      case 'consumer':
        baseUrl = `${baseUrl}/content/dam/webaem/vodafone/consumer`;
        break;
      case 'business-homepage':
        baseUrl = `${baseUrl}/content/dam/webaem/vodafone/business`;
        break;
      case 'grandiaziende-homepage':
        baseUrl = `${baseUrl}/content/dam/webaem/vodafone/grandiaziende`;
        break;
      default:
        break;
    }
    const url = `${baseUrl}/${folderName}/${fileName}`;
    if (folderName === 'images') {
      return {
        src: url,
      };
    }
    return url;
  }
  return false;
};

export { getLivePreviewMedia };
