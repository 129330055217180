import { checkWindow } from '@vfit/shared/data-access';
import { useMediaQuery } from './useMediaQuery';

interface IDeviceType {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isBigDesktop: boolean;
}

const isTabletDevice = () => {
  const tabletReg = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i;
  if (!checkWindow()) return false;
  const usrAgent = navigator.userAgent;
  return tabletReg.test(usrAgent);
};

export function useDeviceType(): IDeviceType {
  const isBigDesktop = useMediaQuery('bigDesktop', 'min');
  const isDesktop = useMediaQuery('desktop', 'min');
  const isTabletVer = useMediaQuery('desktop', 'min');
  const isTabletLand = useMediaQuery('desktop', 'max');
  const isMobile = useMediaQuery('tablet', 'max');
  const isTablet =
    (!isMobile && !isDesktop && !isBigDesktop && (isTabletLand || isTabletVer)) || isTabletDevice();

  return {
    isDesktop,
    isBigDesktop,
    isTablet,
    isMobile,
  };
}
