import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const Spinner: React.FC<ISvgComponent> = ({ color, height, width, shrinkable, ...rest }) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 50 50"
    shrinkable={shrinkable}
    {...rest}
  >
    <circle cx="25" cy="25" r="20" stroke={color} strokeWidth="5" strokeLinecap="round" />
  </S.Svg>
);

