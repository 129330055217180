import { LinkProps } from 'next/link';

type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'mva:primary'
  | 'mva:secondary'
  | 'mva:alt1';

export enum IActionType {
  CUSTOM_URL = 1,
  CALL_ME_NOW = 4,
  COVERAGE_TOOL = 5,
  LOCAL_URL = 7,
  SHOW_DETAIL = 9,
  COVERAGE_TOOL_WITH_REDIRECT = 10,
  CHECKOUT_MODAL_MOBILE = 11,
  WHATSAPP_URL = 12,
  RETRY = 13,
  POPUP_DETAIL = 14,
  LEAD_MODAL = 15,
  POST_COVERAGE = 16,
  IFRAME_URL = 17,
  CALL_ME_NOW_SUNRISE = 18,
  DEACTIVATION_MODAL = 20,
}

export enum IActionStyle {
  PRIMARY = 1,
  SECONDARY = 2,
  TERTIARY = 3,
}

export interface IActionSlide {
  title?: string;
  descriptionModal?: string;
  type?: IActionType;
  small?: boolean;
  url?: string;
  localUrl?: string;
  callMeNow?: string;
  isBlank?: string;
  isForApp?: string;
  onClick?: (el: unknown) => void;
}

export interface IActionCustom extends IActionSlide {
  style?: IActionStyle;
}

export interface IActionSunrise extends IActionSlide {
  style?: ButtonVariant;
}

export interface IActionProps {
  onClick?: () => void;
  href?: LinkProps['href'];
  target?: React.HTMLAttributeAnchorTarget;
}
