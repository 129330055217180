export enum TechTypes {
  FTTH = 'FTTH',
  FIBRA_FTTH = 'FIBRA FTTH',
  MAKE = 'MAKE',
  VULA = 'VULA',
  NGA = 'NGA',
  ULL = 'ULL',
  ULL_SHDSL = 'ULL_SHDSL',
  BS = 'BS',
  BS_SHDSL = 'BS_SHDSL',
  FWA_INDOOR = 'FWA_INDOOR',
  FWA_OUTDOOR = 'FWA_OUTDOOR',
  FWA_OUTDOOR_5G = 'FWA_OUTDOOR_5G',
  FWA_INDOOR_5G = 'FWA_INDOOR_5G',
  FWA = 'FWA',
  T3G4G = '3G 4G',
  FTTC = 'FTTC',
  FTTH_TIM = 'FTTH_TIM',
  ADSL = 'ADSL',
}

export enum TechnologyCategories {
  FTTH = 'FTTH',
  FTTC = 'FTTC',
  ADSL = 'ADSL',
  FWAINDOOR = 'FWAINDOOR',
  FWAOUTDOOR = 'FWAOUTDOOR',
  FWAOUTDOOR5G = 'FWAOUTDOOR5G',
  FWAINDOOR5G = 'FWAINDOOR5G',
  FTTHTIM = 'FTTHTIM',
}

export enum TechnologyCategoriesAggregation {
  FTTH = 'FTTH',
  NOT_DISAGGREGATED = 'NOT_DISAGGREGATED',
  DISAGGREGATED = 'DISAGGREGATED',
  FWA_INDOOR = 'FWA_INDOOR',
  FWA_OUTDOOR = 'FWA_OUTDOOR',
  FTTH_TIM = 'FTTH_TIM',
  FALLBACK = 'FALLBACK',
}

