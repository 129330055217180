import styled, { keyframes } from 'styled-components';

const dash = keyframes`
  from {
    stroke-dashoffset: 320;
  }
  
  to {
    stroke-dashoffset: 0;
  }
`;

export const AnimatedPath = styled.path<{ strokeWidth: number }>`
  animation: ${dash} 4s linear infinite;
  stroke-width: ${({ strokeWidth }) => strokeWidth || 1}

`;

