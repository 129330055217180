import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const CallLog: React.FC<ISvgComponent> = ({ color, height, width, shrinkable, ...rest }) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 20 20"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      d="M13.7263 7.08576V9.58576H16.2263M13.7263 9.57925L17.0564 6.24917M12.8897 5.41584V2.91584H10.3897M12.8897 2.92235L9.55961 6.25243M17.8832 14.3449C17.8929 14.3709 17.8962 14.4002 17.8962 14.4328C17.8929 14.7062 17.8506 14.9764 17.7692 15.2401C17.4567 16.2524 16.6136 17.3429 15.6175 17.6359C13.1045 18.3846 10.458 17.6164 8.11755 16.4412C6.18395 15.4744 4.61169 13.9087 3.63187 11.9783C2.45023 9.65412 1.67875 7.02066 2.4307 4.52066C2.72692 3.53107 3.82393 2.69123 4.84281 2.38199L5.65987 2.16714C5.79658 2.13133 5.9333 2.2062 5.97888 2.33641L6.62341 4.22443C6.95218 5.1424 7.28096 6.05712 7.60974 6.97508L7.63252 7.0467C7.67809 7.17365 7.63903 7.31688 7.52836 7.40152C7.20934 7.6424 6.85127 7.8312 6.47041 7.9549C6.01794 8.09813 5.54593 8.18928 5.07067 8.22834C4.97953 8.2381 4.89815 8.28693 4.84932 8.36506C4.79724 8.43993 4.78421 8.53433 4.81351 8.62222C5.3083 10.1847 6.17093 11.604 7.32979 12.7628C8.49841 13.9184 9.92744 14.7778 11.4965 15.2694C11.5843 15.2954 11.6787 15.2824 11.7536 15.2335C11.8285 15.1847 11.8806 15.1033 11.8871 15.0122C11.9294 14.5402 12.0238 14.0714 12.167 13.6222C12.294 13.2414 12.4828 12.8865 12.7237 12.5708C12.8083 12.4601 12.9515 12.4178 13.0817 12.4666L13.1534 12.4894L15.917 13.4725C16.5746 13.7036 17.1345 13.938 17.792 14.1723C17.8246 14.1854 17.8636 14.283 17.8832 14.3449Z"
    />
  </S.Svg>
);



