import { API } from '@vfit/shared/data-access';
import { INextError, LoggerInstance, serverlessClient } from '@vfit/shared/data-access';
import { useMutation } from 'react-query';
import { ISKill } from './checkChannel.models';

import { IStoreCmbPostResponse } from './storeCmb.models';

/**
 * This method is used to aws lambda Vola Client - store CMB api service
 * This in used in the Vola Modal component
 * Method: POST
 *
 * @returns
 */
const storeCmbService = (
  id_channel: ISKill,
  telephone_number: string,
  contact_time: string,
  description: string
) =>
  serverlessClient.post(API.VOLA_CLIENT, {
    id_channel,
    data: {
      telephone_number,
      description,
      contact_time,
    },
    method: 'store_cmb',
  }) as Promise<IStoreCmbPostResponse>;

/**
 * This method is used to instantiate a custom useMutation hook to handle the volaClientService
 * @returns
 */
export const useStoreCmbMutation = () =>
  useMutation<
    IStoreCmbPostResponse,
    INextError,
    { id_channel: ISKill; telephone_number: string; contact_time: string; desc: string }
  >(
    ['storeCmbMutation'],
    ({ id_channel, telephone_number, contact_time, desc }) =>
      storeCmbService(id_channel, telephone_number, contact_time, desc),
    {
      onSuccess: (data: IStoreCmbPostResponse) => {
        localStorage.setItem('storeCmb', JSON.stringify(data));
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR on storeCmb: `, error);
        localStorage.setItem(`storeCmb`, JSON.stringify(error));
      },
    }
  );
