import { colors, pxToRem } from '@vfit/shared/themes';
import styled from 'styled-components';

export const ScrollableIndicator = styled.div`
  background-color: ${colors.$bebebe};
  border-radius: ${pxToRem(8)};
  height: ${pxToRem(6)};
  left: 50%;
  position: absolute;
  top: ${pxToRem(11)};
  transform: translateX(-50%);
  width: ${pxToRem(42)};
`;
