import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const TickLs: React.FC<ISvgComponent> = ({ color, height, width, shrinkable, ...rest }) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 17.033 17.033"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      fill={color}
      d="M1.652 7.835A.964.964 0 0 0 .288 9.2l5.03 5.03a.964.964 0 0 0 1.364 0L16.744 4.17a.966.966 0 1 0-1.365-1.366L6 12.183 1.653 7.834z"
    />
  </S.Svg>
);

