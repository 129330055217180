import get from 'lodash-es/get';
import { typography } from '../styled';
import { FlattenSimpleInterpolation } from 'styled-components';

type Serializable = null | undefined | string | number | boolean | bigint;
type MakeSerializable<T> = T extends Serializable ? T : never;

type Path<T, TToExtends = any, TKey extends keyof T = keyof T> = T[TKey] extends TToExtends // Se siamo nella foglia,
  ? MakeSerializable<TKey> // Utility per rendere serializabile un insieme di key (symbol non ammesso)
  : T[TKey] extends Record<string, any> // Controlla se non sono in una foglia
  ? TKey extends Serializable // Controllo che non ci siano symbol
    ? `${TKey}.${Path<T[TKey], TToExtends, keyof T[TKey]>}` // Interpolazione per creare il path: key1.key2...keyn
    : never // Condizioni che non devono accadere
  : never;

type Typography = typeof typography;

export type PathTypography = Path<Typography, FlattenSimpleInterpolation>;

export const getTypography = (path: PathTypography) => get(typography, path);

