import { checkWindow } from '../../utils/check-window';
import { retrieveBasePageName, retrievePageChannel } from './utils';
import {
  CartProduct,
  IDatalayer,
  IPageData,
  PageProduct,
  TEventName,
  TOptions,
  VisitorTrackingOpts,
} from './models';

export const getSystemEnvironment = (): 'App' | 'Web' | undefined => {
  if (checkWindow() && window.localStorage.getItem('isApp') === 'true') return 'App';
  if (
    checkWindow() &&
    (typeof window.BWB_JS !== 'undefined' || window.location.search.includes('isApp=true'))
  ) {
    window.localStorage.setItem('isApp', 'true');
    return 'App';
  }
  if (checkWindow()) return 'Web';
  return undefined;
};

export const trackOptions = (
  event: TEventName[] | string[],
  event_label,
  opts?: TOptions,
  product_name?: string,
  cart_product?: CartProduct,
  page_country?: string,
  page_language?: string,
  page_locale?: string
): IDatalayer => {
  const pageData: IPageData = {
    basePageName: '',
    pageChannel: '',
    pageName: '',
    pageType: '',
    sysEnv: getSystemEnvironment(),
  };

  const hostname = window.location.hostname.split('.');
  const querystringParams = checkWindow() && window.location.search;
  const urlParams = new URLSearchParams(querystringParams);
  const isAppFromQueryString = urlParams.get('app');
  const isApp = checkWindow() && localStorage.getItem('appSession');

  pageData.pageType = hostname[0] === 'www' ? hostname[1] : hostname[0];
  pageData.basePageName = retrieveBasePageName(cart_product, product_name);
  pageData.pageName = opts?.page_name_forced_override
    ? opts?.page_name_forced_override
    : `${pageData.basePageName}:${event_label || event?.[0] || ''}`;
  pageData.pageChannel = retrievePageChannel(pageData.pageType);

  // eslint-disable-next-line no-param-reassign
  delete opts?.['eventLabel'];

  return {
    page_country: page_country || 'IT',
    page_language: page_language || 'IT',
    page_locale: page_locale || 'it-IT',
    // Static info
    page_platform: 'NextJS',

    // Tracking Options
    ...opts,

    // Page information
    page_name: pageData.pageName,
    page_environment: pageData.sysEnv,
    event_name: event,
    page_channel: pageData.pageChannel,
    ...((event_label || event?.[0]) && { event_label: event_label || event?.[0] }),
    ...(!!isAppFromQueryString || isApp ? { onetrust_sdk_consent: '1:1,2:1,3:1,4:1' } : null),
  };
};

export const computeDatalayer = (
  event: TEventName[] | string[],
  event_label?: string,
  opts?: TOptions,
  pageProduct?: PageProduct,
  cartProduct?: CartProduct,
  visitorTrackingOpts?: VisitorTrackingOpts,
  page_country?: string,
  page_language?: string,
  page_locale?: string
): IDatalayer => ({
  ...pageProduct,
  ...cartProduct,
  ...visitorTrackingOpts,
  ...trackOptions(
    event,
    event_label,
    opts,
    Array.isArray(pageProduct?.product_name)
      ? pageProduct?.product_name?.[0] || ''
      : (pageProduct?.product_name as string) || '',
    cartProduct,
    page_country,
    page_language,
    page_locale
  ),
});
