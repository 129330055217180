import styled from 'styled-components';
import {
  breakpoints,
  colors,
  fonts,
  pxToCssFont,
  pxToFontSize,
  pxToRem,
} from '@vfit/shared/themes';
import { ICustomTextContainer } from './customText.models';

export const CustomTextContainer = styled.div<ICustomTextContainer>`
  text-align: ${({ textAlign }) => textAlign};
  ${({ modal }) =>
    modal ? 'margin-top: 16px; margin-bottom: 32px' : 'margin: 48px 23px 31px 23px'};
  font-family: ${({ fontFamily }) => fontFamily || fonts.light};
  ${({ sizeMobile, lineHeightMobile }) => pxToCssFont(sizeMobile || 30, lineHeightMobile || 38)};
  ${({ margin }) => (margin !== undefined ? `margin: ${pxToRem(...margin)}` : null)};

  @media (min-width: ${breakpoints.tablet}) {
    ${({ size, lineHeight }) => pxToCssFont(size || 36, lineHeight || 50)};
    ${({ modal }) => modal && 'margin-top: 24px; margin-bottom: 32px'};
    ${({ margin }) => (margin !== undefined ? `margin: ${pxToRem(...margin)}` : null)};
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${({ modal }) => modal && 'margin-top: 16px; margin-bottom: 40px'};
    ${({ margin }) => (margin !== undefined ? `margin: ${pxToRem(...margin)}` : null)};
  }

  b {
    font-family: ${fonts.exbold};
    font-weight: 400;
  }

  img {
    cursor: text;
    vertical-align: baseline;
  }

  a {
    color: ${colors.$262626};
    font-weight: 700;

    &:hover {
      color: ${colors.$e60000};
    }
  }

  button {
    font-family: ${fonts.exbold};
    font-size: ${({ sizeMobile }) => pxToFontSize(sizeMobile || 30)};
    font-weight: 400;
    color: ${colors.$e60000};
    background: none;
    border: none;
    padding: 0;
    outline: inherit;

    span {
      font-family: ${fonts.light};
      color: white;
      background-color: ${colors.$e60000};
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 50%;
      cursor: pointer;
    }

    &:hover {
      span {
        background-color: ${colors.$bd0000};
      }
    }

    &:active {
      span {
        background-color: ${colors.$a10000};
      }
    }
  }
`;

