import { getLivePreviewMedia } from './checkLivePreview';

const PLACEHOLDER_IMAGE = 'https://www.vodafone.it/portal/assets/img/menu/vodafone-logo.png';

const requireVideo = (video: string, videoMobile: string, isMobile: boolean): string => {
  const videoUrl = isMobile ? videoMobile || video : video;
  const livePreviewMedia = getLivePreviewMedia(videoUrl, 'videos');
  if (livePreviewMedia) return livePreviewMedia as string;
  const splitExt = videoUrl?.split('.');
  if (!splitExt) return '';
  const haveGoodNetwork = navigator?.['connection']?.['downlink']
    ? navigator?.['connection']?.['downlink'] >= 5
    : true;
  const quality = haveGoodNetwork ? '_medium' : '_low';
  const videoSplitted = `${splitExt[0]}${quality}`;
  return `${process.env['NEXT_PUBLIC_HOSTNAME']}/${process.env['NEXT_PUBLIC_MEDIA_PATHNAME'] || ''}/${videoSplitted}.${splitExt[1]}`;
};

const lambdaCustomLoaderHandler = ({ src, width }: { src: string; width: number }) => {
  const isDevLocal = process.env?.['NODE_ENV'] === 'development';
  if (isDevLocal) return `${process.env['NEXT_PUBLIC_HOSTNAME']}/${width}&text=${src}`;

  if (src === PLACEHOLDER_IMAGE) {
    return PLACEHOLDER_IMAGE;
  }

  const ext = src.substring(src.lastIndexOf('.') + 1, src.length).toLowerCase();
  if (['svg', 'gif', 'json'].includes(ext))
    return `${process.env['NEXT_PUBLIC_HOSTNAME']}/${process.env['NEXT_PUBLIC_MEDIA_PATHNAME'] || ''}/${src}`;

  return `${process.env['NEXT_PUBLIC_HOSTNAME']}/${process.env['NEXT_PUBLIC_MEDIA_PATHNAME'] || ''}/${src.replace(
    /.([^.]*)$/,
    '_$1'
  )}_w${width}.${process.env['NEXT_PUBLIC_MEDIA_IMG_FORMAT']}`;
};

const requireStaticImageComponent = (image: string, imageMobile?: string, isMobile?: boolean) =>
  lambdaCustomLoaderHandler({ src: isMobile ? imageMobile : image, width: 2048 });

const getObjImage = (filename: string): { src: string; srcSet?: string } => {
  const src = lambdaCustomLoaderHandler({ src: filename, width: 2048 });
  const srcSet = [640, 750, 828, 1080, 1200, 1920, 2048].reduce(
    (prev, breakpoint) =>
      `${prev ? `${prev},` : ''}${lambdaCustomLoaderHandler({
        src: filename,
        width: breakpoint,
      })} ${breakpoint}w`,
    ''
  );
  return { src, srcSet };
};

const requireStaticImageAtomComponent = (
  image: string,
  imageMobile?: string,
  isMobile?: boolean
): { src: string; srcSet?: string } => {
  const filename = isMobile ? imageMobile || image : image;
  return getObjImage(filename);
};

export {
  PLACEHOLDER_IMAGE,
  requireVideo,
  requireStaticImageComponent,
  requireStaticImageAtomComponent,
  lambdaCustomLoaderHandler,
};

