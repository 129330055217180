import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const Celebrate: React.FC<ISvgComponent> = ({
  color,
  secondColor,
  height,
  width,
  shrinkable,
  ...rest
}) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 64 64"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      stroke={secondColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m32 7.04 2.24 4.52 4.987.733-3.613 3.52.853 4.974L32 18.44l-4.466 2.347.853-4.974-3.613-3.52 4.986-.733z"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m11.321 15.067 2.84 2.4 3.587-.96-1.413 3.44 2.04 3.12-3.72-.28-2.334 2.893-.88-3.613-3.48-1.334 3.174-1.946zM52.679 15.067l-2.84 2.4-3.587-.96 1.413 3.44-2.04 3.12 3.72-.28 2.334 2.893.88-3.613 3.48-1.334-3.174-1.946z"
    />
    <path
      stroke={secondColor}
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M52.88 39.134a.467.467 0 0 1 .453-.467.453.453 0 1 1-.454.467z"
    />
    <path
      stroke={secondColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M10.666 43.12a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667z"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M50.666 51.12a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667z"
    />
    <path
      stroke={secondColor}
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M43.559 56.52a.453.453 0 0 1 .453-.454.428.428 0 0 1 .454.44.455.455 0 0 1-.77.326.453.453 0 0 1-.137-.312z"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M32 25.68v25.36M17.149 28.453l8.346 18.694M46.84 28.453l-8.347 18.694M17.292 51.614a.44.44 0 1 0 0-.88.44.44 0 0 0 0 .88z"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17.292 51.294a.12.12 0 1 0 0-.24.12.12 0 0 0 0 .24zM9.32 56.88a.44.44 0 1 0 0-.88.44.44 0 0 0 0 .88z"
    />
  </S.Svg>
);

