import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { IUseAppDrawer } from './modalAsDrawer.models';
import { useSwipeElement } from '@vfit/shared/hooks';

export const useAppDrawer: IUseAppDrawer = ({ onCloseModal }) => {
  const [show, setShow] = useState(true);
  const [isBgClickable, setIsBgClickable] = useState(false);
  const [colorTheBg, setColorTheBg] = useState(false);

  const bgProps = {
    animationTime: 700,
    colorTheBg,
    show,
  };

  useLayoutEffect(() => {
    setTimeout(() => setIsBgClickable(true), 100);
    setTimeout(() => setColorTheBg(true), bgProps.animationTime);
    const el = document.querySelector('#sticky-offer-summary') as HTMLElement;
    if (el) el.style.visibility = 'visible';
  }, []);

  const closeModalAfterAnimation = () => {
    setTimeout(() => onCloseModal?.(), bgProps.animationTime - 10);
    setColorTheBg(false);
    setShow(false);
  };

  const close = () => (isBgClickable ? closeModalAfterAnimation() : null);

  const { onTouchStart, onTouchMove, onTouchEnd, swipeStatus } = useSwipeElement();

  useEffect(() => {
    if (swipeStatus === 'down') closeModalAfterAnimation();
  }, [swipeStatus]);

  return {
    bgProps,
    close,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  };
};
