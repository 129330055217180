import React, { useState } from 'react';
import { FullScreenModal, FullScreenModalExtended } from '@vfit/shared/atoms';

import { IFrameDetail } from './interfaces';
import IframeDetail from '../IframeDetail/iframeDetail';
import VfModal from '../VfModal/vfModal';
import { LoggerInstance } from '@vfit/shared/data-access';

export const ModalIframeWrapper = () => {
  const initialIframe: IFrameDetail = {
    title: '',
    url: '',
    onClose: () => {LoggerInstance.info('ModalIframeWrapper:modalDetail:onClose')},
    fullscreen: false,
    fullScreenExtended: false,
  };
  const setModalHeight = () => 563;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalDetail, setModalDetail] = useState<IFrameDetail>(initialIframe);

  if (typeof document !== 'undefined') {
    document.addEventListener('iframeServiceNotificationEvent', (event) => {
      const { detail } = event as CustomEvent as { detail: IFrameDetail };
      setModalDetail(detail || modalDetail);
      setShowModal(!showModal);
    });
  }

  const resetModalStatus = () => {
    setModalDetail(initialIframe);
    setShowModal(false);
  };

  const handleCloseModal = () => {
    if (modalDetail.onClose) modalDetail.onClose();
    resetModalStatus();
  };

  if (modalDetail.fullScreenExtended) {
    return (
      <FullScreenModalExtended
        show
        showIcon={!!modalDetail?.showIcon}
        showLogo={modalDetail?.showLogo}
        onCloseModal={handleCloseModal}
      >
        <IframeDetail
          title={modalDetail.title || initialIframe.title || ''}
          url={modalDetail.url || initialIframe.url}
        />
      </FullScreenModalExtended>
    );
  }

  if (modalDetail.fullscreen) {
    return (
      <FullScreenModal show showIcon={!!modalDetail?.showIcon} onCloseModal={handleCloseModal}>
        <IframeDetail
          title={modalDetail.title || initialIframe.title || ''}
          url={modalDetail.url || initialIframe.url}
        />
      </FullScreenModal>
    );
  }

  return (
    <VfModal
      wrapperId={modalDetail.id}
      height={setModalHeight()}
      isOpen={showModal}
      handleClose={handleCloseModal}
    >
      <IframeDetail
        title={modalDetail.title || initialIframe.title || ''}
        url={modalDetail.url || initialIframe.url}
      />
    </VfModal>
  );
};
