import { Svg } from '../../../../../Svg';
import { NodeWithSvg } from '../../../NodeWithSvg';
import { useButton } from '../../button.hook';
import { Loader } from '../loader/loader';
import { IButtonBtn } from './buttonBtn.models';
import * as S from './buttonBtn.style';

export const Btn: React.FC<IButtonBtn> = ({
  children,
  disabled,
  onClick: _onClick,
  onMouseEnter: _onMouseEnter,
  onMouseLeave: _onMouseLeave,
  model = 'regular',
  size,
  svg,
  svgPosition = 'right',
  type = 'button',
  variant = 'primary',
  name,
  loading,
  ...rest
}) => {
  const { color, height, onClick, onMouseEnter, onMouseLeave } = useButton<HTMLButtonElement>({
    _onClick,
    _onMouseEnter,
    _onMouseLeave,
    disabled,
    size,
    variant,
  });
  const getButton = () => {
    if (model !== 'regular') return <Svg name={svg || 'plusBtn'} color={color} height={height} />;
    if (loading) return <Loader color={color} strokeWidth={2}/>;
    return (
      <NodeWithSvg color={color} height={height} svg={svg} svgPosition={svgPosition}>
        {children}
      </NodeWithSvg>
    );
  };

  return (
    <S.Button
      {...rest}
      disabled={disabled}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      model={model}
      size={size}
      type={type}
      variant={variant}
      data-name={name}
      loading={loading}
    >
      {getButton()}
    </S.Button>
  );
};

