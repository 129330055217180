import { useFormContext } from 'react-hook-form';
import { CustomInputProps } from '@vfit/shared/models';
import * as S from '../../cmsForm.style';
import { ErrorMessage } from '../ErrorMessage/errorMessage';

export const CustomRadio = ({
  name,
  label,
  options,
  isColMiddle,
  isHidden,
  isDisabled,
  isPendingRequest,
  ...props
}: CustomInputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const error = errors[name]?.message as string | undefined;

  return (
    <S.StyledInputContainer isColMiddle={isColMiddle} isHidden={isHidden}>
      <S.StyledRadioGroup>
        <label className="label">{label}</label>
        <section>
          {options &&
            options.map(({ description, value }) => (
              <label className="options" key={value}>
                <input
                  {...register(name)}
                  {...props}
                  value={value}
                  type="radio"
                  disabled={isDisabled || isPendingRequest}
                />
                <span>{description}</span>
              </label>
            ))}
        </section>
      </S.StyledRadioGroup>
      <ErrorMessage error={error} />
    </S.StyledInputContainer>
  );
};
