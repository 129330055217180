import ky from 'ky';
import type { Options, NormalizedOptions } from 'ky';
import { getToken, checkWindow, getItemCookie } from '@vfit/shared/data-access';
import { LoggerInstance } from '../../../index';
import {
  errorMessages,
  DxlError,
  NextError,
  delay,
  NEXT_ERR_MSG,
  NEXT_ERR_TRANSACTION_ID,
} from './utils';
import { ClientOption } from './model';
import handleEnv from '../handleEnv';
import {
  checkCustomHeaderNext,
  deleteMockCustomHeader,
  getHeadersDxlGatewayCloud,
  getHeadersNext,
  getSilentLoginHeadersNext,
  MOCK_DXL_TOKEN,
} from './config.utils';

type KyHeadersInit = Options['headers'];
export const headersNext: KyHeadersInit | undefined = {
  'access-control-allow-credentials': 'true',
  'access-control-allow-origin': '*',
  'cache-control': 'no-cache',
  'content-type': 'application/json',
  accept: 'application/json, text/plain, */*',
  'accept-encoding': 'gzip, deflate, br',
  'accept-language': 'en-US,en;q=0.9,it;q=0.8',
  connection: 'keep-alive',
  r7flowindicator: 'true',
  sunrise: 'true',
};

export const awsHeadersAPI: KyHeadersInit | undefined = {
  'cache-control': 'no-cache',
  'content-type': 'application/json',
};

export const mode = 'cors';
export const credentials = 'include';
export const prefixUrl = handleEnv('NEXT_PUBLIC_NEXT_ENVIRO') || '';
export const prefixUrlDXL = 'NEXT_PUBLIC_DXL_PREFIX' || '';
export const prefixUrlJourneyAppDXL = handleEnv('NEXT_PUBLIC_DXL_APP_PREFIX') || ''; // "https://dxl.vodafone.it/api"
export const prefixUrlJourneyAppDXLBS = handleEnv('NEXT_PUBLIC_DXL_BS_APP_PREFIX') || ''; // "https://bs.dxl.vodafone.it/api"
export const prefixUrlDXLConsumerCloud = handleEnv('NEXT_PUBLIC_DXL_CONSUMER_CLOUD') || '';
export const prefixUrlDXLGatewayCloud = handleEnv('NEXT_PUBLIC_DXL_GATEWAY_CLOUD') || '';
export const prefixUrlCMS = handleEnv('NEXT_PUBLIC_CMS') || '';
export const prefixUrlWebApi = handleEnv('NEXT_PUBLIC_WEBAPI_SERVERLESS') || '';
export const prefixUrlAwsApiConsumer = handleEnv('NEXT_PUBLIC_AWSAPI_CONSUMER_PREFIX') || '';
export const isRequiredCustomHeaders =
  handleEnv('NODE_ENV') !== 'development' && handleEnv('NEXT_PUBLIC_TEST_QA') !== 'TRUE';
export const mockApiKey = handleEnv('NX_MOCK_API_KEY');
export const isEnabledMockApiKey = handleEnv('NEXT_PUBLIC_MOCK_API_KEY_ENABLED') === 'TRUE';

export const NEXT: ClientOption = {
  ...checkCustomHeaderNext(),
  baseUrl: '',
  prefixUrl,
  searchParams: { salesChannel: 'selfService' },
  hooks: {
    beforeRequest: [
      // eslint-disable-next-line consistent-return
      async (request, options: NormalizedOptions & { silentLoginHeaders?: HeadersInit }) => {
        if (handleEnv('NODE_ENV') === 'LOCAL' || handleEnv('NODE_ENV') === 'development')
          await delay(1000);
        const urlObject = new URL(request.url);
        const urlPath = urlObject.pathname.replace('/api/next/', '');
        const headerLogger = {
          ...getHeadersNext(),
        };
        if (checkWindow()) {
          const token = getToken('uxfauthorization');
          if (token) {
            LoggerInstance.info(`${urlPath} REQ. HEADERS: `, headerLogger);
            return request.headers.set('Authorization', token as string);
          }
        }
        /* *
         * @todo check if we can put this in env file, to make centralised every variable
         */
        const SILENT_LOGIN =
          '/authentication/external-user/v1/silentLogin?salesChannel=selfService';
        const URLService = handleEnv('NEXT_PUBLIC_NEXT_ENVIRO');

        LoggerInstance.debug('API Request SILENT LOGIN:', request?.url);
        const res = await ky
          .post(URLService + SILENT_LOGIN, {
            json: {},
            headers: {
              ...getHeadersNext(),
              ...(options.silentLoginHeaders &&
                getSilentLoginHeadersNext(options?.silentLoginHeaders)),
            },
            retry: 0,
          })
          .catch((error) =>
            console.error('catch error beforeRequest', error, 'post at urlService: ', URLService)
          );
        if (res) {
          const auth = res.headers.get('uxfauthorization');

          if (auth && checkWindow()) {
            sessionStorage.setItem('uxfauthorization', auth);
            sessionStorage.setItem('uxfpath', window?.location?.href || '');
            request.headers.set('Authorization', auth);
          }
        }
        request.headers.forEach((value, name) => {
          const newProperty = value;
          headerLogger[newProperty] = name;
        });
        deleteMockCustomHeader(request.headers);
        LoggerInstance.info(`${urlPath} REQ. HEADERS: `, headerLogger);
      },
    ],
    afterResponse: [
      async (request, _options: NormalizedOptions & { ignoreUpdateToken?: boolean }, response:any) => {
        if (!response.ok) {
          localStorage.setItem(
            NEXT_ERR_TRANSACTION_ID,
            response?.headers?.get('dex-transaction-id')
          );
          localStorage.setItem(NEXT_ERR_MSG, response?.headers?.get('err-errormessage'));
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw new NextError(response, request).getError();
        }
        try {
          const body = response.status !== 204 ? await response.json() : {};
          if (body.error) throw new Error(`throw body error ${JSON.stringify(body.error)}`);
          const auth = response.headers.get('uxfauthorization');
          if (auth && !_options.ignoreUpdateToken) {
            LoggerInstance.debug('New Auth', auth);
            sessionStorage.setItem('uxfauthorization', auth);
          }
        } catch (e) {
          LoggerInstance.debug('Response is not a json');
        }
        const responseHeaderLogger = {};
        response.headers.forEach((name, value) => {
          responseHeaderLogger[value] = name;
        });
        const urlObject = new URL(request.url);
        const urlPath = urlObject.pathname.replace('/api/next/', '');
        LoggerInstance.info(`${urlPath} RES. HEADERS: `, responseHeaderLogger);
      },
    ],
  },
  timeout: 120000,
  retry: 0,
};

export const DXL: ClientOption = {
  baseUrl: '',
  headers: {
    'X-Device-UserAgent': 'MyVodafone/android/s/7.1.2/12.19.0/1.5/custom/"samsung"/"SM-N950N"',
    'X-Device-Id': '13f3165b460c43f59fddeae8ab9ec02f',
    clientId: '4002',
  },
  /**
   * @author andrea dattola
   IN MY OPINION, A GENERAL ENV VARIABLE SHOULD BE ADDED FOR THE PREFIX OF DXL, SO AS TO HANDLE THE PREFIXURL AS FOLLOWS

   prefixUrl:handleEnv('NEXT_PUBLIC_DXL_ENVIRO' as ENV), //todo check for env variable for dxl client
   */
  prefixUrl: prefixUrlDXL,
  searchParams: {},
  hooks: {
    beforeRequest: [
      (request) => {
        if (checkWindow()) {
          const token = getToken('dxl-jwt');
          if (!request?.url?.includes('auth/guest') && !token) {
            throw new DxlError(
              `401|_|'AUTH_EXPIRED'|_|'La sessione è scaduta, ricarica la pagina'`
            );
          }
          if (token) request.headers.set('X-Auth-Token', token);
        }
      },
    ],
    afterResponse: [
      async (_request, _options, response:any) => {
        if (handleEnv('NODE_ENV') === 'LOCAL' && checkWindow()) await delay(1000);
        if (!response.ok) {
          throw new DxlError(
            `${response.status}|_|${
              response.statusText || 'UNKNOWN_ERROR_RESPONSE_NOT_OK'
            }|_|Ops, si è verificato un errore.`
          );
        }
        try {
          const res = response.status !== 204 ? await response.json() : {};
          if (res.code && res.code !== 0) {
            const mappedMessage = errorMessages.customErrors.find((x: any) => x.code === res.code);
            throw new DxlError(
              `${res.code}|_|${res.description || 'UNKNOWN_ERROR_FROM_API'}|_|${
                mappedMessage?.message || res.message || 'Ops, si è verificato un errore.'
              }`
            );
          }
        } catch (e) {
          LoggerInstance.debug('Response is not a json');
        }
      },
    ],
  },
};

export const DXL_JOURNEY_APP: ClientOption = {
  headers: {},
  baseUrl: '',
  prefixUrl: prefixUrlJourneyAppDXL,
  searchParams: {},
  hooks: {
    beforeRequest: [
      (request) => {
        LoggerInstance.debug('dxlClient beforeRequest : ', request);

        if (typeof window !== 'undefined') {
          const token = window.sessionStorage.getItem('dxlJwt');
          LoggerInstance.debug('dxlClient token : ', token);

          if (!request.url.includes('auth/guest') && !token) {
            LoggerInstance.debug('401|_|AUTH_EXPIRED|_|La sessione è scaduta, ricarica la pagina');
          }

          // X-Auth-Token dxlJwt token set on headers DXL client
          if (token) {
            LoggerInstance.debug('dxlClient beforeRequest token: ', token);
            request.headers.set('X-Auth-Token', token);
          }
        }
      },
    ],
    afterResponse: [
      async (_request, _options, response:any) => {
        LoggerInstance.debug('dxlClient afterResponse : ', response);

        if (!response.ok) {
          throw new DxlError(
            `${response.status}|_|${
              response.statusText || 'UNKNOWN_ERROR_RESPONSE_NOT_OK'
            }|_|Ops, si è verificato un errore.`
          );
        }

        try {
          const res = response.status !== 204 ? await response.json() : {};
          if (res.code && res.code !== 0) {
            LoggerInstance.debug(
              `${res?.code}|_|${res?.description || 'UNKNOWN_API_ERROR'}|_|${
                res?.message || 'Attenzione, si è verificato un errore'
              }`
            );
          }
        } catch (e) {
          LoggerInstance.debug('Response is not a json');
        }
      },
    ],
  },
  timeout: 60000,
};

export const CMS: ClientOption = {
  baseUrl: '',
  headers: {},
  prefixUrl: prefixUrlCMS,
  searchParams: {},
  hooks: {
    beforeRequest: [],
    afterResponse: [
      // eslint-disable-next-line consistent-return
      async (_request, _options, response:any) => {
        if (response.ok) {
          const body = await response.json();
          if (body.error) throw new Error(`throw body error ${JSON.stringify(body.error)}`);
          LoggerInstance.debug('body response', body);
          return body;
        }
      },
    ],
  },
  timeout: 60000,
};

export const WEBAPI_SERVERLESS: ClientOption = {
  baseUrl: '',
  headers: {},
  prefixUrl: prefixUrlWebApi,
  searchParams: '',
  hooks: {
    beforeRequest: [],
    afterResponse: [
      async (_request, _options, response:any) => {
        const body = response ? await response.json() : {};
        if (!response.ok) throw new NextError(response, _request, body).getError();
        try {
          if (body.error) throw new Error(`throw body error ${JSON.stringify(body.error)}`);
          return body;
        } catch (e) {
          LoggerInstance.debug('Response is not a json');
        }
      },
    ],
  },
};

export const AWSAPI_CONSUMER: ClientOption = {
  baseUrl: '',
  headers: {
    ...awsHeadersAPI,
  },
  timeout: 60000,
  retry: 0,
  prefixUrl: prefixUrlAwsApiConsumer,
  searchParams: '',
  hooks: {
    beforeRequest: [
      async (request) => {
        const jwt = sessionStorage.getItem('dxlJwt');
        if (jwt && jwt != 'undefined') {
          request.headers.set('vf-a-appconsumerauth', jwt as string);
        }
      },
    ],
    afterResponse: [
      async (_request, _options, response:any) => {
        const isDevLocal =
          handleEnv('NODE_ENV') === 'LOCAL' || handleEnv('NODE_ENV') === 'development';
        const jwtAuth = response.headers.get('vf-a-appconsumerauth');
        if (jwtAuth || isDevLocal) {
          sessionStorage.setItem('dxlJwt', isDevLocal ? MOCK_DXL_TOKEN : jwtAuth);
        }
        if (!response.ok) {
          const body = await response.json();
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw new NextError(response, _request, body).getError();
        }
        try {
          const body = await response.json();
          if (body.error) throw new Error(`throw body error ${JSON.stringify(body.error)}`);
          LoggerInstance.debug('body response', body);
          return body;
        } catch (e) {
          LoggerInstance.debug('Response is not a json');
        }
      },
    ],
  },
};

export const DXLCLOUD_CONSUMER: ClientOption = {
  baseUrl: '',
  headers: {},
  prefixUrl: prefixUrlDXLConsumerCloud,
  searchParams: '',
  hooks: {
    beforeRequest: [
      (request) => {
        const cookie = getItemCookie('vf-a-customer-identification');
        if (cookie) request.headers.set('vf-a-customer-identification', cookie);
      },
    ],
    afterResponse: [
      // eslint-disable-next-line consistent-return
      async (_request, _options, response:any) => {
        if (response.ok) {
          const body = await response.json();
          if (body.error) throw new Error(`throw body error ${JSON.stringify(body.error)}`);
          LoggerInstance.debug('body response', body);
          return body;
        }
      },
    ],
  },
};

export const DXL_GATEWAY_CLOUD: ClientOption = {
  baseUrl: '',
  headers: getHeadersDxlGatewayCloud(),
  prefixUrl: prefixUrlDXLGatewayCloud,
  searchParams: '',
  hooks: {
    beforeRequest: [],
    afterResponse: [
      // eslint-disable-next-line consistent-return
      async (request, _options, response:any) => {
        if (!response.ok) {
          const body = await response.json();
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw new NextError(response, request, body).getError();
        }

        const body = await response.json();
        if (body.error) throw new Error(`throw body error ${JSON.stringify(body.error)}`);
        LoggerInstance.debug('body response', body);
        return body;
      },
    ],
  },
  timeout: 60000,
};
