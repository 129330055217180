import { css } from 'styled-components';
import { cssfonts } from '../cssfonts';

export const tag = {
  bold: css`
    ${cssfonts.bold}
    font-size: 0.75rem; /* 12px */
    letter-spacing: 0.5px;
    line-height: 1; /* differs from DS typography */
    text-transform: uppercase;
  `,
  regular: css`
    ${cssfonts.regular}
    font-size: 1rem; /* 16px */
    letter-spacing: 0.5px;
    line-height: 1.375rem; /* differs from DS typography */
    text-transform: capitalize;
  `,
} as const;

