import { useEffect } from 'react';
import { checkWindow } from '@vfit/shared/data-access';
import { useCookie } from '@vfit/shared/hooks';
import { useRouter } from 'next/router';

const CookieRedirect = () => {
  const { asPath } = useRouter();
  const isHomepage = asPath === '/';
  const [cookie] = useCookie('session-domain', '');

  useEffect(() => {
    // @ts-ignore
    if (checkWindow() && isHomepage && cookie && cookie !== window.origin)
      window.location.href = cookie.toString();
  }, [cookie]);

  return null;
};
export default CookieRedirect;
