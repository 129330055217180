import { IVolaModalDetail } from './interfaces';

export class IVolaModalService {
  private customVolaModalEvent: CustomEvent | undefined;

  /**
   * Handle Vola Modal
   */
  public handleVolaModal(volaDetail?: IVolaModalDetail): void {
    this.customVolaModalEvent = new CustomEvent('volaModalNotificationEvent', {
      detail: volaDetail,
    });
    document.dispatchEvent(this.customVolaModalEvent);
  }
}
