import { css } from 'styled-components';
import { colors } from '../../colors';
import { cssfonts } from '../cssfonts';

const common = css`
  font-size: 1rem; /* 16px */
  line-height: 1.4; /* 16/22 */
`;

export const body2 = {
  extrabold: css`
    ${cssfonts.exbold}
    ${common}
  `,
  bold: css`
    ${cssfonts.bold}
    ${common}
  `,
  link: css`
    ${cssfonts.bold}
    ${common}
    
    &:hover {
      ${colors.$e60000}
    }
  `,
  regular: css`
    ${cssfonts.regular}
    ${common}
  `,
} as const;

