import { keyframes } from 'styled-components';

export const rotateBack = keyframes`
    from {
      transform: rotate(-180deg);
    }

    to {
      transform: rotate(0);
    }
`;

