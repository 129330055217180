import { breakpoints, colors, pxToRem } from '@vfit/shared/themes';
import styled from 'styled-components';

export const Background = styled.div`
  background-color: #000000c4;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
`;

export const LogoWrapper = styled.div`
  position: absolute;
  z-index: 99;

  svg {
    height: ${pxToRem(30)};
    margin: ${pxToRem(16)};
    width: ${pxToRem(30)};

    @media (min-width: ${breakpoints.tablet}) {
      height: ${pxToRem(32)};
      margin: ${pxToRem(12, 16)};
      width: ${pxToRem(32)};
    }

    @media (min-width: ${breakpoints.desktop}) {
      height: ${pxToRem(48)};
      margin: ${pxToRem(24, 80)};
      width: ${pxToRem(48)};
    }
  }
`;

export const Container = styled.div`
  background-color: ${colors.$ffffff};
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;

  .closing-icon {
    margin-top: ${pxToRem(21.5)};
    margin-right: ${pxToRem(21.5)};
    position: absolute;
    right: 0;

    &:hover {
      cursor: pointer;
    }
  }

  svg {
    height: ${pxToRem(17.33)};
    width: ${pxToRem(17.33)};
  }

  @media (min-width: ${breakpoints.tablet}) {
    .closing-icon {
      margin-top: ${pxToRem(48)};
      margin-right: ${pxToRem(38)};
    }

    svg {
      height: ${pxToRem(19.5)};
      width: ${pxToRem(19.5)};
    }
  }
`;

export const ModalContent = styled.div`
  height: calc(100vh - ${pxToRem(48)});
  margin: ${pxToRem(32)} auto ${pxToRem(16)};
  overflow: auto;
  position: relative;
  width: calc(100% - ${pxToRem(32)});

  @media (min-width: ${breakpoints.desktop}) {
    width: calc(100% - ${pxToRem(226)});
  }
`;

export const IconWrap = styled.span`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
`;
