import styled from 'styled-components';
import { cssprops } from '@vfit/shared/themes';
import { IButtonStyledComponent } from '../../button.models';
import * as S from '../../button.style';

export const Button = styled.button<IButtonStyledComponent>`
  cursor: pointer;
  ${S.common}

  &:disabled {
    ${S.disabled}
  }

  ${cssprops.styled}
`;

