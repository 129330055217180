import styled, { createGlobalStyle } from 'styled-components';
import { breakpoints } from '@vfit/shared/themes';
import { IWrapperIdProps } from './vfModal.models';

export const OverlayGlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }

  #sticky-offer-summary {
    visibility: hidden;
  }
`;

// actually used only for VBI vola-ctc modal
export const WrapperOverrideStyle = styled.div<IWrapperIdProps>`
  ${(props) =>
    props.wrapperId &&
    `
      div #${props.wrapperId} {
        padding: 40px 10px 10px;

        @media (min-width: ${breakpoints.tablet}) {
          padding: 40px;
        }

        @media (min-width: ${breakpoints.desktop}) {
          padding: 40px 60px;
        }

        #${props.wrapperId}-closing-icon-container {
          position: absolute;
          top: 16px;
          right: 24px;

          span {
            float: none;
            margin: 0;
          }
        }

        .modalContent {
          margin: 0;
          height: 100%;

          > div {
            padding: 0
          }
        }
      }
    `}
`;
