import { css } from 'styled-components';
import { cssfonts } from '../cssfonts';

export const footnote1 = {
  regular: css`
    ${cssfonts.regular}
    font-size: 0.875rem; /* 14px */
    line-height: 1.3; /* 14/18 */
  `,
} as const;

