export interface ICountry {
  code: string;
  province?: string;
  municipality: string;
}

export interface IProvince {
  short?: string;
  long?: string;
}

export enum Gender {
  Male = 'M',
  Female = 'F',
}

export interface IFiscalCodeReverse {
  name: string;
  surname: string;
  gender: string;
  day: number;
  year: number;
  month: number;
  birthday: string;
  birthplace: string;
  birthplaceProvincia: string;
  cf: string;
}

