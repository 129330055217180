import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const CloseBtn: React.FC<ISvgComponent> = ({
  color,
  height,
  width,
  shrinkable,
  ...rest
}) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 14 14"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3.5"
      d="m1.75 12.25 10.5-10.5m0 10.5L1.75 1.75"
    />
  </S.Svg>
);

