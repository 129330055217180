import { useEffect, useState } from 'react';
import { getItemCookie, setItemCookie } from '@vfit/shared/data-access';

/**
 *
 * @param {String} key The key to store our data to
 * @param {String} defaultValue The default value to return in case the cookie doesn't exist
 */
export const useCookie = (key: string, defaultValue: string) => {
  const getCookie = () => getItemCookie(key) || defaultValue;
  const [cookie, setCookie] = useState(null);
  
  useEffect(()=>{
    setCookie(getCookie())
  },[])

  const updateCookie = (value: string, numberOfDays: number) => {
    setCookie(value);
    setItemCookie(key, value, numberOfDays);
  };

  return [cookie, updateCookie];
};

export default useCookie;
