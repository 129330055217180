import { CustomOptions, nextClient } from '@vfit/shared/data-access';
import { API } from '@vfit/shared/data-access';
import { IAttachmentListResponse } from './attachmentList.models';

export const attachmentList = (
  salesChannel: string,
  filters: string,
  customOptions?: CustomOptions
) =>
  nextClient.get(`${API.ATTACHMENT_LIST}`, {
    searchParams: {
      salesChannel,
      filters,
    },
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  }) as Promise<IAttachmentListResponse>;

