import { ScrollableIndicator } from '../../../ScrollBarIndicator';
import { IModalProps } from '../../modal.models';
import { useAppDrawer } from './modalAsDrawer.hook';
import * as S from './modalAsDrawer.style';

export const ModalAsDrawer: React.FC<IModalProps> = ({
  children,
  isModalFullContainer,
  onCloseModal,
}) => {
  const { bgProps, close, onTouchStart, onTouchMove, onTouchEnd } = useAppDrawer({
    onCloseModal,
  });

  return (
    <S.Background {...bgProps} onClick={close}>
      <S.Drawer
        isModalFullContainer={isModalFullContainer}
        onClick={(ev) => ev.stopPropagation()}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <ScrollableIndicator />
        {children && <S.Frame>{children}</S.Frame>}
      </S.Drawer>
    </S.Background>
  );
};
