import { useState } from 'react';
import { LoadingSpinner } from '@vfit/shared/atoms';
import { IIframeDetail } from './iframeDetail.models';
import { Container, Title } from './iframeDetail.style';

const IframeDetail = ({ title, url, options }: IIframeDetail) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <Container>
      <div className="iframe-holder">
        {title && <Title textAlign={options?.textAlign}>{title}</Title>}
        {isLoading && <LoadingSpinner />}
        <iframe
          src={url}
          title={title || ''}
          onLoad={() => setIsLoading(false)}
          frameBorder={0}
          style={{
            width: '100%',
            height: '99%',
          }}
        />
      </div>
    </Container>
  );
};

export default IframeDetail;
