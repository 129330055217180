import {LoggerInstance} from "@vfit/shared/data-access";

export const handleSuccess = (data: any) => {
  // TData
  LoggerInstance.debug('DATA HANDLE SUCCESS GENERIC', data);
};

export const handleError = (error: any) => {
  // TError
  LoggerInstance.error('ERROR HANDLER GENERIC', error);
};

