import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const Location: React.FC<ISvgComponent> = ({
  color,
  height,
  width,
  shrinkable,
  ...rest
}) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 18 18"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      fill={color}
      d="M14.5 7.667a.5.5 0 0 0-1 0zM9 15.333l-.327.378a.5.5 0 0 0 .654 0zM9 3.167a.5.5 0 0 0 0-1zm-.022-1a.5.5 0 0 0 0 1zM13.5 7.689a.5.5 0 0 0 1 0zm-2.667-.022c0 1.012-.82 1.833-1.833 1.833v1a2.833 2.833 0 0 0 2.833-2.833zM9 9.5a1.833 1.833 0 0 1-1.833-1.833h-1A2.833 2.833 0 0 0 9 10.5zM7.167 7.667c0-1.013.82-1.834 1.833-1.834v-1a2.833 2.833 0 0 0-2.833 2.834zM9 5.833c1.013 0 1.833.821 1.833 1.834h1A2.833 2.833 0 0 0 9 4.833zm4.5 1.834c0 .716-.272 1.527-.722 2.368-.447.834-1.05 1.66-1.662 2.395a24.745 24.745 0 0 1-2.431 2.515l-.01.008-.002.002.327.378.328.378v-.001c.002 0 .003-.002.004-.003l.012-.01a10.222 10.222 0 0 0 .208-.187 25.752 25.752 0 0 0 2.332-2.44c.638-.765 1.285-1.648 1.775-2.563.488-.91.841-1.89.841-2.84ZM9 15.333l.328-.377-.003-.003-.01-.008a5.743 5.743 0 0 1-.192-.173 24.745 24.745 0 0 1-2.238-2.342c-.613-.735-1.216-1.56-1.663-2.395-.45-.841-.722-1.652-.722-2.368h-1c0 .95.353 1.93.84 2.84.49.915 1.138 1.798 1.776 2.563a25.752 25.752 0 0 0 2.54 2.627l.012.01.003.003h.001v.001L9 15.333zM4.5 7.667a4.5 4.5 0 0 1 1.318-3.182l-.707-.707A5.5 5.5 0 0 0 3.5 7.667Zm1.318-3.182A4.5 4.5 0 0 1 9 3.167v-1a5.5 5.5 0 0 0-3.89 1.61zm3.16-1.318c.593 0 1.182.117 1.73.344l.383-.924a5.522 5.522 0 0 0-2.113-.42Zm1.73.344c.549.227 1.047.56 1.467.98l.708-.707a5.522 5.522 0 0 0-1.792-1.197zm1.467.98c.42.42.753.919.98 1.467l.925-.382a5.522 5.522 0 0 0-1.197-1.792zm.98 1.467c.228.55.345 1.137.345 1.731h1c0-.725-.143-1.443-.42-2.113z"
    />
  </S.Svg>
);

