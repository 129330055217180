import { LoggerInstance } from '@vfit/shared/data-access';
import jwtDecode from 'jwt-decode';
import { getToken } from './get-token';

export const checkJWTsValidity = (tokenKeys: string[]): string | false => {
  /* dxlAuthTokenCB, dxlAuthToken; */
  for (let i = 0; i < tokenKeys.length; i += 1) {
    const token = getToken(tokenKeys[i]);
    if (token) {
      try {
        const decodedJwt = jwtDecode(token);
        console.log('auth flow: decodedJwt', decodedJwt);
        const { exp } = decodedJwt as { exp: number };
        console.log('auth flow: decodedExp', exp);
        console.log('auth flow: date.now() < exp', Date.now() < exp * 1000);
        if (Date.now() < exp * 1000) return token;
      } catch (_) {
        LoggerInstance.debug('auth flow: JWT not valid');
      }
    }
  }
  return false;
};

