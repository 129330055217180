import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const Warning: React.FC<ISvgComponent> = ({ color, height, width, shrinkable, ...rest }) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 24 24"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="m23.371 17.846.082.194L14.881 2.99C14.148 1.707 13.125 1 12.001 1c-1.123 0-2.146.707-2.88 1.992L.631 17.846c-.735 1.285-.833 2.5-.268 3.482.565.983 1.68 1.488 3.148 1.488h16.98c1.469 0 2.587-.507 3.149-1.488.561-.98.466-2.197-.269-3.482zm-11.37 2.48a1.087 1.087 0 0 1-1.073-1.099 1.086 1.086 0 0 1 1.083-1.089 1.086 1.086 0 0 1 1.074 1.095c-.001.605-.486 1.095-1.082 1.094h-.002zM13.076 6.61l-.353 8.978a.727.727 0 0 1-.722.732.727.727 0 0 1-.721-.732l-.357-8.977a1.096 1.096 0 0 1 .934-1.232 1.086 1.086 0 0 1 1.214.947c.013.095.013.19 0 .285z"
      clipRule="evenodd"
    />
  </S.Svg>
);

