import React from 'react';
import { SpinnerIcon } from '@vfit/shared-icons';
import { SpinnerContainer } from './loadingSpinner.style';
import { ILoadingSPinnerProos } from './loadingSpinner.models';

const LoadingSpinner = ({ isChild }: ILoadingSPinnerProos) => (
  <SpinnerContainer isChild={isChild}>
    <SpinnerIcon />
  </SpinnerContainer>
);

export default LoadingSpinner;
