import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const StyledErrorMessage = styled.p`
  position: relative;
  animation: fadein 2s;
  margin-block: 0;
  margin-inline: 0;
  margin-bottom: -2px !important;
  color: ${colors.$e60000};
  ${pxToCssFont(12, 24)}
  font-family: ${fonts.regular};
  text-align: left;

  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: -8px !important;
  }
`;
