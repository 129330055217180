import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const LocateMe: React.FC<ISvgComponent> = ({
  color,
  height,
  width,
  shrinkable,
  ...rest
}) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 24 24"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.5 12h4m11 0h4M12 21.5v-4m0-11v-4m7.5 9.5a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0z"
    />
  </S.Svg>
);

