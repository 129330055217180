import { IToastDetail } from './interfaces';

export class ToastService {
  private customToastEvent: CustomEvent | undefined;

  /**
   * Handle Toast
   */
  public handleToast(toastDetail?: IToastDetail): void {
    this.customToastEvent = new CustomEvent('toastServiceNotificationEvent', {
      detail: toastDetail,
    });
    document.dispatchEvent(this.customToastEvent);
  }
}
