import { handleEnv } from '@vfit/shared/data-access';
import { isJSON } from './actions';

export const ENCODE_VALUE_PREFIX = 'data/base64/';

export const getItemCookie = (key: string) => {
  const itemCookie = document?.cookie?.split('; ')?.reduce((total, currentCookie) => {
    const item = currentCookie.split('=');
    const storedKey = item[0];
    const storedValue = item[1];
    return key === storedKey ? decodeURIComponent(storedValue) : total;
  }, '');
  const valueSplitted = itemCookie?.split(ENCODE_VALUE_PREFIX);
  const valueToAnalyze =
    valueSplitted?.length > 1 ? decodeURIComponent(atob(valueSplitted?.[1])) || '' : itemCookie;
  if (isJSON(valueToAnalyze)) return JSON.parse(valueToAnalyze);
  return valueToAnalyze;
};

/**
 * Method to return the value to encode
 * append prefix
 * @param value
 */
export const getValueToEncode = (value: string) => `${ENCODE_VALUE_PREFIX}${value}`;

/**
 * Method to set the item cookie
 * if needed the value in encoded in base64
 * @param key
 * @param value
 * @param numberOfDays
 * @param enableEncode
 * @param domain
 */
export const setItemCookie = (
  key: string,
  value: string | Record<string, any>,
  numberOfDays: number,
  enableEncode?: boolean,
  domain = 'vodafone.it'
) => {
  const isDevLocal = handleEnv('NODE_ENV') === 'development';
  const isValueString = typeof value === 'string';
  const parsedValue = isValueString ? value : JSON.stringify(value);
  const enableEncodeToSave = isValueString ? enableEncode : true;
  const valueToSave = enableEncodeToSave
    ? getValueToEncode(btoa(encodeURIComponent(parsedValue)))
    : parsedValue;
  const now = new Date();
  // set the time to be now + numberOfDays
  now.setTime(now.getTime() + numberOfDays * 60 * 60 * 24 * 1000);
  if (isDevLocal) {
    document.cookie = `${key}=${valueToSave}; expires=${now.toUTCString()}; path=/;`;
  } else {
    document.cookie = `${key}=${valueToSave}; expires=${now.toUTCString()}; path=/; domain=${domain}`;
  }
};
