import { useDeviceType } from '@vfit/shared/hooks';
import { checkIsApp } from '@vfit/consumer/data-access';
import { IModalProps } from './modal.models';
import { DefaultModal, ModalAsDrawer, ModalFullContainer } from './components';

const Modal = (props: IModalProps) => {
  const { isTablet } = useDeviceType();
  const { show, isModalFullContainer } = props;
  if (!show) return null;
  if (checkIsApp() && !isTablet) return <ModalAsDrawer {...props} />;
  if (isModalFullContainer) return <ModalFullContainer {...props} />;

  return <DefaultModal {...props} />;
};

export default Modal;

