import * as Yup from 'yup';
import { QueryClient } from 'react-query';
import { AnyObject } from 'yup/lib/types';
import {
  IActionStyle,
  ICMSForm,
  ICmsOrganizeForm,
  IErrorFormApi,
  InputProps,
  ISubmitOutput,
} from '@vfit/shared/models';
import { errorManager, ErrorService } from '@vfit/shared/data-access';
import { getButtonActionByActionType } from './actions';

type YupBoolean = Yup.BooleanSchema<boolean | undefined, AnyObject, boolean | undefined>;
type YupString = Yup.StringSchema<string | undefined, AnyObject, string | undefined>;
type YupNumber = Yup.NumberSchema<number | undefined, AnyObject, number | undefined>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MOCK_FORM = {
  config: {
    textAlign: 'left',
    title: 'Vuoi maggiori <b>informazioni?</b>',
    description: '<b>Lascia</b> i tuoi dati e ti contatteremo al più presto.',
    footer:
      '<p>Autorizzo Vodafone al contatto telefonico richiesto. <a href="https://www.vodafone.it/portal/Privati/Area-Privacy/La-nostra-informativa" target="_blank">Informativa Privacy</a></p>',
  },
  submit: {
    label: 'Ti chiamiamo gratis',
    variant: IActionStyle.SECONDARY,
    url: 'https://www.vodafone-api.it/lead_innovation/api/saveLeadSito.php',
    splitHiddenValues: false, // split hidden values in service body
    bodyServiceInputKey: '', // body service key for inputs, default no key
    method: 'POST',
    headers: [
      // {
      //   title: 'content-type',
      //   value: 'multipart/form-data',
      // },
    ],
    success: {
      title: 'Ok',
      message: 'Tutto ok, nessun problema',
      action: {
        title: 'Chiudi',
      },
    },
    error: {
      title: 'Ops',
      message: 'Si è verificato un problema',
      action: {
        title: 'Riprova',
      },
    },
  },
  forms: [
    {
      label: 'Inserisci il source',
      type: 'text',
      name: 'source',
      value: 'source value',
      isColMiddle: false,
      isHidden: true,
      validations: [],
    },
    {
      label: 'Inserisci la partita iva',
      type: 'text',
      name: 'piva',
      value: '',
      isColMiddle: false,
      validations: [
        {
          type: 'minLength',
          value: 3,
          message: 'Min. 3 caratteri',
        },
        {
          type: 'maxLength',
          value: 9,
          message: 'Max 9 caratteri',
        },
        {
          type: 'required',
          message: 'La partita iva è obbligatoria',
        },
      ],
    },
    {
      label: 'Azienda',
      type: 'text',
      name: 'azienda',
      value: '',
      isColMiddle: false,
      validations: [
        {
          type: 'minLength',
          value: 3,
          message: 'Min. 3 caratteri',
        },
        {
          type: 'maxLength',
          value: 9,
          message: 'Max 9 caratteri',
        },
        {
          type: 'required',
          message: "L'azienda è obbligatoria",
        },
      ],
    },
    {
      label: 'Data',
      type: 'date',
      name: 'Data di nascita',
      // format is aaaa-mm-dd
      value: '2018-07-22',
      minDate: '2018-01-01',
      maxDate: '2018-12-31',
      isColMiddle: false,
      validations: [
        {
          type: 'required',
          message: "L'azienda è obbligatoria",
        },
      ],
    },
    {
      type: 'select',
      name: 'Provincia',
      label: '--- Seleziona provincia ---',
      value: '',
      isColMiddle: true,
      options: [
        { value: '1', desc: 'Ancona' },
        { value: '2', desc: 'Macerata' },
        { value: '3', desc: 'Pesaro Urbino' },
        { value: '4', desc: 'Ascoli Piceno' },
        { value: '5', desc: 'Alessandria' },
        { value: '6', desc: 'Asti' },
        { value: '7', desc: 'Biella' },
        { value: '8', desc: 'Cuneo' },
        { value: '9', desc: 'Novara' },
        { value: '10', desc: 'Vercelli' },
        { value: '11', desc: 'Torino' },
        { value: '12', desc: 'Agrigento' },
        { value: '13', desc: 'Caltanissetta' },
        { value: '14', desc: 'Catania' },
        { value: '15', desc: 'Enna' },
        { value: '16', desc: 'Messina' },
        { value: '17', desc: 'Palermo' },
        { value: '18', desc: 'Ragusa' },
        { value: '19', desc: 'Siracusa' },
        { value: '20', desc: 'Trapani' },
        { value: '21', desc: 'Catanzaro' },
        { value: '22', desc: 'Cosenza' },
        { value: '23', desc: 'Crotone' },
        { value: '24', desc: 'Reggio Calabria' },
        { value: '25', desc: 'Vibo Valentia' },
        { value: '27', desc: 'Matera' },
        { value: '28', desc: 'Potenza' },
        { value: '29', desc: 'Bari' },
        { value: '30', desc: 'Brindisi' },
        { value: '31', desc: 'Foggia' },
        { value: '32', desc: 'Lecce' },
        { value: '33', desc: 'Taranto' },
        { value: '34', desc: 'Avellino' },
        { value: '35', desc: 'Benevento' },
        { value: '36', desc: 'Caserta' },
        { value: '37', desc: 'Napoli' },
        { value: '38', desc: 'Salerno' },
        { value: '39', desc: 'Frosinone' },
        { value: '40', desc: 'Latina' },
        { value: '41', desc: 'Rieti' },
        { value: '42', desc: 'Roma' },
        { value: '43', desc: 'Viterbo' },
        { value: '44', desc: 'Chieti' },
        { value: '45', desc: "L'Aquila" },
        { value: '46', desc: 'Pescara' },
        { value: '47', desc: 'Teramo' },
        { value: '48', desc: 'Arezzo' },
        { value: '49', desc: 'Firenze' },
        { value: '50', desc: 'Grosseto' },
        { value: '51', desc: 'Livorno' },
        { value: '52', desc: 'Lucca' },
        { value: '53', desc: 'Massa Carrara' },
        { value: '54', desc: 'Pisa' },
        { value: '55', desc: 'Pistoia' },
        { value: '56', desc: 'Siena' },
        { value: '57', desc: 'Bologna' },
        { value: '58', desc: 'Ferrara' },
        { value: '59', desc: 'Forlì-Cesena' },
        { value: '60', desc: 'Modena' },
        { value: '61', desc: 'Parma' },
        { value: '62', desc: 'Piacenza' },
        { value: '63', desc: 'Ravenna' },
        { value: '64', desc: 'Reggio Emilia' },
        { value: '65', desc: 'Rimini' },
        { value: '66', desc: 'Belluno' },
        { value: '67', desc: 'Padova' },
        { value: '68', desc: 'Rovigo' },
        { value: '69', desc: 'Treviso' },
        { value: '70', desc: 'Venezia' },
        { value: '71', desc: 'Verona' },
        { value: '72', desc: 'Vicenza' },
        { value: '73', desc: 'Gorizia' },
        { value: '74', desc: 'Pordenone' },
        { value: '75', desc: 'Udine' },
        { value: '76', desc: 'Trieste' },
        { value: '77', desc: 'Aosta' },
        { value: '78', desc: 'Cagliari' },
        { value: '79', desc: 'Nuoro' },
        { value: '80', desc: 'Oristano' },
        { value: '81', desc: 'Sassari' },
        { value: '82', desc: 'Genova' },
        { value: '83', desc: 'Imperia' },
        { value: '84', desc: 'Savona' },
        { value: '85', desc: 'La Spezia' },
        { value: '86', desc: 'Isernia' },
        { value: '87', desc: 'Campobasso' },
        { value: '88', desc: 'Perugia' },
        { value: '89', desc: 'Terni' },
        { value: '90', desc: 'Bergamo' },
        { value: '91', desc: 'Brescia' },
        { value: '92', desc: 'Como' },
        { value: '93', desc: 'Cremona' },
        { value: '94', desc: 'Lecco' },
        { value: '95', desc: 'Lodi' },
        { value: '96', desc: 'Mantova' },
        { value: '97', desc: 'Milano' },
        { value: '98', desc: 'Pavia' },
        { value: '99', desc: 'Sondrio' },
        { value: '100', desc: 'Varese' },
        { value: '101', desc: 'Trento' },
        { value: '102', desc: 'Bolzano' },
        { value: '103', desc: 'Prato' },
        { value: '104', desc: 'Verbano Cusio Ossola' },
        { value: '105', desc: 'Carbonia Iglesias' },
        { value: '106', desc: 'Medio Campidano' },
        { value: '107', desc: 'Ogliastra' },
        { value: '108', desc: 'Olbia Tempio' },
        { value: '109', desc: 'Monza e Brianza' },
        { value: '110', desc: 'Fermo' },
        { value: '111', desc: 'Barletta Andria Trani' },
      ],
      validations: [
        {
          type: 'required',
          message: 'La provincia è obbligatoria',
        },
      ],
    },
    {
      label: 'Numero di telefono',
      type: 'text',
      name: 'cell',
      value: '',
      isColMiddle: true,
      validations: [
        {
          type: 'minLength',
          value: 3,
          message: 'Min. 3 caratteri',
        },
        {
          type: 'maxLength',
          value: 9,
          message: 'Max 9 caratteri',
        },
        {
          type: 'required',
          message: 'Il cellulare è obbligatorio',
        },
        {
          type: 'regex',
          message: 'Inserire un cellulare valido',
          regex: /^(3)+[0-9]*$/,
        },
      ],
    },
    {
      type: 'radio',
      name: 'clienteVodafone',
      label: 'Sei già cliente Vodafone',
      value: '',
      isColMiddle: false,
      options: [
        {
          value: 'yes',
          desc: 'Si',
        },
        {
          value: 'no',
          desc: 'No',
        },
      ],
      validations: [
        {
          type: 'required',
          message: 'il campo è obbligatorio',
        },
      ],
    },
    {
      label: 'New Password',
      type: 'password',
      name: 'password',
      value: '',
      isColMiddle: false,
      validations: [
        {
          type: 'required',
          message: 'Password is required',
        },
        {
          type: 'minLength',
          value: 5,
          message: 'Min. 5 characters',
        },
      ],
    },
    {
      label: 'Repeat your password',
      type: 'password',
      name: 'repeat_password',
      value: '',
      isColMiddle: false,
      validations: [
        {
          type: 'required',
          message: 'Repeat password is required',
        },
        {
          type: 'minLength',
          value: 5,
          message: 'Min. 5 characters',
        },
        {
          type: 'oneOf',
          message: 'Passwords must match',
          ref: 'password',
        },
      ],
    },
    {
      label: 'E-mail address',
      type: 'email',
      name: 'email',
      value: '',
      isColMiddle: false,
      validations: [
        {
          type: 'required',
          message: 'Email is required',
        },
        {
          type: 'isEmail',
          message: 'Email no valid',
        },
      ],
    },
    {
      type: 'select',
      name: 'rol',
      label: 'Select an option: ',
      value: '',
      isColMiddle: false,
      options: [
        {
          value: 'admin',
          desc: 'Admin',
        },
        {
          value: 'user',
          desc: 'User',
        },
        {
          value: 'super-admin',
          desc: 'Super Admin',
        },
      ],
      validations: [
        {
          type: 'required',
          message: 'Rol is required',
        },
      ],
    },
    {
      type: 'radio',
      name: 'gender',
      label: 'Gender: ',
      value: '',
      isColMiddle: false,
      options: [
        {
          value: 'man',
          desc: 'Man',
        },
        {
          value: 'woman',
          desc: 'Woman',
        },
        {
          value: 'other',
          desc: 'Other',
        },
      ],
      validations: [
        {
          type: 'required',
          message: 'Gender is required',
        },
      ],
    },
    {
      type: 'checkbox',
      name: 'terms',
      typeValue: 'boolean',
      label: 'Terms and Conditions',
      value: false,
      isColMiddle: false,
      validations: [
        {
          type: 'isTrue',
          message: 'Accept the terms!',
        },
      ],
    },
  ],
};

const manageErrorLeadPlatform = (
  submitOutput: ISubmitOutput[],
  errorCmsApi?: IErrorFormApi,
  push?,
  queryClient?: QueryClient
) => {
  const DEFAULT_LEAD_ERROR: ISubmitOutput = {
    title: 'Ops',
    message: 'Si è verificato un problema. Riprovare in seguito',
    action: {},
  };
  const errorCode = errorCmsApi?.data?.['subCode'];
  const foundedErrors = submitOutput?.filter(
    (el) => el.statusCode?.toString() == errorCmsApi.status?.toString()
  );
  let foundedError: ISubmitOutput = foundedErrors?.[0] || DEFAULT_LEAD_ERROR;
  if (errorCode) {
    foundedError =
      foundedErrors?.find((el) => el.errorCode?.toString() == errorCode?.toString()) ||
      foundedErrors?.[0] ||
      DEFAULT_LEAD_ERROR;
  }
  errorManager.handleError(ErrorService.getSeverityErrorMedium(), {
    disableTrack: true,
    title: foundedError?.title || '',
    message: foundedError?.message || '',
    actionText: foundedError?.action?.title || '',
    ...(foundedError?.action?.type && {
      actionEvent: () => getButtonActionByActionType(foundedError?.action, push, queryClient),
    }),
  });
};

const manageSuccessLeadPlatform = (
  submitOutput: ISubmitOutput,
  push?,
  queryClient?: QueryClient
) => {
  errorManager.handleError(ErrorService.getSeverityErrorMedium(), {
    disableTrack: true,
    title: submitOutput?.title || '',
    message: submitOutput?.message || '',
    actionText: submitOutput?.action?.title || '',
    ...(submitOutput?.action?.type && {
      actionEvent: () => getButtonActionByActionType(submitOutput?.action, push, queryClient),
    }),
  });
};

const organizeForm = (cmsForm?: ICMSForm): ICmsOrganizeForm => {
  if (!cmsForm || Object.keys(cmsForm).length === 0)
    return {
      config: {},
      input: [],
    };
  return {
    ...cmsForm,
    submit: {
      ...cmsForm.submit,
      ...(cmsForm?.submit?.variant && {
        variant: parseInt(cmsForm?.submit?.variant, 10),
      }),
      ...(cmsForm.submit?.splitHiddenValues && {
        splitHiddenValues: cmsForm.submit.splitHiddenValues === 'true',
      }),
      ...(cmsForm.submit?.enableQueryParamsInHidden && {
        enableQueryParamsInHidden: cmsForm.submit.enableQueryParamsInHidden === 'true',
      }),
      ...(cmsForm.submit?.alwaysEnabledSubmitButton && {
        alwaysEnabledSubmitButton: cmsForm.submit.alwaysEnabledSubmitButton === 'true',
      }),
    },
    input: cmsForm?.input?.map((form) => ({
      ...form,
      ...(form.isHidden && { isHidden: form?.isHidden === 'true' }),
      ...(form.isColMiddle && { isColMiddle: form?.isColMiddle === 'true' }),
      ...(form.isDisabled && { isDisabled: form?.isDisabled === 'true' }),
      value: form.typeValue === 'boolean' ? !!form.value : form.value || '',
      validations:
        form.validations?.map((validation) => ({
          ...validation,
          ...(validation.regex && { regex: new RegExp(validation.regex) }),
        })) || [],
    })),
  };
};

const generateValidations = (field: InputProps): YupBoolean | YupString | YupNumber | null => {
  if (!field.validations) return null;
  let schema = Yup[field.typeValue || 'string']();
  // eslint-disable-next-line no-restricted-syntax
  for (const rule of field.validations) {
    switch (rule.type) {
      case 'isTrue':
        schema = (schema as YupBoolean).isTrue(rule.message);
        break;
      case 'isEmail':
        schema = (schema as YupString).email(rule.message);
        break;
      case 'minLength':
        schema = (schema as YupString).min(rule.value as number, rule.message);
        break;
      case 'maxLength':
        schema = (schema as YupString).max(rule.value as number, rule.message);
        break;
      case 'regex':
        schema = (schema as YupString).matches(rule.regex, rule.message);
        break;
      case 'oneOf':
        schema = (schema as YupString).oneOf([Yup.ref(rule.ref as string)], rule.message);
        break;
      default:
        schema = schema.required(rule.message);
        break;
    }
  }
  return schema;
};

const getInputs = (form: InputProps[]) => {
  const initialValues: { [key: string]: any } = {};
  const validationsFields: { [key: string]: any } = {};
  form.forEach((field) => {
    initialValues[field.name] = field.value;
    if (field.validations) {
      validationsFields[field.name] = generateValidations(field);
    }
  });
  return {
    validationSchema: Yup.object({ ...validationsFields }),
    initialValues,
    inputs: form,
  };
};

export interface IGetAllCmsForm {
  [key: string]: { forms: ICMSForm };
}

export {
  manageErrorLeadPlatform,
  manageSuccessLeadPlatform,
  organizeForm,
  generateValidations,
  getInputs,
};
