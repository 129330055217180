/* eslint-disable no-useless-escape */
/* eslint-disable radix */
/* eslint-disable no-param-reassign */
import * as yup from 'yup';
import { LoggerInstance } from '@vfit/shared/data-access';
import { AnyObject, OptionalObjectSchema, TypeOfShape } from 'yup/lib/object';
import { ConfigurationFields, FieldsName } from './model';

export const regex: Record<string, RegExp> = {
  // regexes currently used in the project
  msisdn: /^[0-9]+$/,
  phoneNumber: /^[0-9]*$/, // 2 equal
  city: /^[a-zA-Z+"àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ"+\s]*$/g, // 3
  postalCode: /^\d+$/, // 2
  stateOrProvince: /^[a-zA-Z+"àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ"+\s]*$/g, // 3 nel file cooverageToolManual c'è il required
  street: /^[a-zA-Z+"-àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ"+\s]*$/g, // 3 coverageToolManual c'è il required
  streetNumber: /^[a-z0-9+"/"+\s]+$/i, // 3 coverageToolManual c'è il required
  identificationNumber: /^[a-zA-Z0-9]*$/,
  expirationDate: /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/,
  fiscalCode: /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/,
  firstName: /^[A-zÀ-ú ']*$/, // 2
  lastName: /^[A-zÀ-ú ']*$/, // 2
  documentNumber: /^[a-zA-Z0-9]*$/,

  // Commonly used Regex
  // Digits
  wholeNumbers: /^\d+$/, // ex: https://www.regexpal.com/?fam=104020
  decimalNumbers: /^\d*\.\d+$/, // ex: https://www.regexpal.com/?fam=104021
  wholeAndDecimalNumbers: /^\d*(\.\d+)?$/, // ex: https://www.regexpal.com/?fam=104022
  negativePositiveWholeDecimalNumbers: /^-?\d*(\.\d+)?$/, // ex: https://www.regexpal.com/?fam=104023
  wholeDecimalFractions: /[-]?[0-9]+[,.]?[0-9]*([\/][0-9]+[,.]?[0-9]*)*/, // ex: https://www.regexpal.com/94462,
  //  Alphanumeric Characters
  alphanumericWithoutSpace: /^[a-zA-Z0-9]*$/, // ex: https://www.regexpal.com/?fam=104024,
  alphanumericWithSpace: /^[a-zA-Z0-9 ]*$/, // ex: https://www.regexpal.com/?fam=104025
  // EMAIL
  commonEmailIds: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/, // ex: https://www.regexpal.com/?fam=104026
  uncommonEmailIds: /^([a-z0-9_\.\+-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/, // ex: https://www.regexpal.com/?fam=104027
  // PASSWORD STRENGTH
  complexPassword:
    /(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/, // ex: https://www.regexpal.com/?fam=104028
  moderatePassword: /(?=(.*[0-9]))((?=.*[A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z]))^.{8,}$/, // ex: https://www.regexpal.com/?fam=104029
  // USERNAME
  alphanumericString: /^[a-z0-9_-]{3,16}$/, // ex: https://www.regexpal.com/?fam=104030
  // URL
  includeHttpsProtocol:
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/, // ex: https://www.regexpal.com/?fam=104034
  protocolOptional:
    /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/, // ex: https://www.regexpal.com/?fam=104035
  // IP
  IPv4Address:
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/, // ex: https://www.regexpal.com/?fam=104036z
  IPv6Address:
    /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/, // ex: https://www.regexpal.com/?fam=104037
  IPv4AndIPv6Addresses:
    /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/, // ex: https://www.regexpal.com/?fam=104038
  // DATES
  DateFormatYYYY_MM_dd_UsingSeparator: /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/, // ex: https://www.regexpal.com/?fam=104039
  dateFormat_dd_MM_YYYYUsingSeparators:
    /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/, // ex: https://regexr.com/346hf
  dateFormat_dd_mmm_YYYYUsingSeparators:
    /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/, // ex: https://regexr.com/39tr1
  // TIME
  timeFormatHH_MM_12_Hour: /^(0?[1-9]|1[0-2]):[0-5][0-9]$/,
  mobilePhoneNumber: /^(3)([0-9]{8,9})$/,
};
export const configurationInputs: Partial<ConfigurationFields> = {
  fixedPhone: {
    regex: { value: regex.phoneNumber, message: 'Inserire un numero valido' },
    min: { value: 10, message: 'Campo errato' },
    max: { value: 11, message: 'Campo errato' },
    string: true,
    required: 'Campo obbligatorio',
  },
  // TODO: Re-evaluate validation when error designs are available
  msisdn: {
    string: true,
    regex: { value: /^[0-9]+$/ },
    min: { value: 9, message: 'Il numero inserito è troppo corto' },
    max: { value: 10, message: 'Il numero inserito è troppo lungo' },
    required: 'Campo obbligatorio',
  },
  phoneNumber: {
    string: true,
    required: 'Campo obbligatorio',
    min: { value: 9, message: 'Inserire un numero valido' },
    max: { value: 10, message: 'Inserire un numero valido' },
    regex: { value: regex.mobilePhoneNumber, message: 'Inserire un numero valido' },
  }, // 2 equal
  city: {
    regex: { value: /^[a-zA-Z+"àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ"+\s]*$/g },
    string: true,
    min: { value: 1, message: 'Campo obbligatorio' },
    required: 'Campo obbligatorio',
  }, // 3
  postalCode: {
    regex: { value: /^\d+$/ },
    string: true,
    min: { value: 1, message: 'Campo obbligatorio' },
    required: 'Campo obbligatorio',
  }, // 2
  stateOrProvince: {
    regex: { value: /^[a-zA-Z+"àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ"+\s]*$/g },
    string: true,
    min: { value: 1, message: 'Campo obbligatorio' },
    required: 'Campo obbligatorio',
  }, // 3 nel file cooverageToolManual c'è il required
  street: {
    regex: { value: /^[a-zA-Z+"-àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ"+\s]*$/g },
    string: true,
    min: { value: 1, message: 'Campo obbligatorio' },
    required: 'Campo obbligatorio',
  }, // 3 coverageToolManual c'è il required
  streetNumber: {
    regex: { value: /^[a-z0-9+"/"+\s]+$/i },
    string: true,
    min: { value: 1, message: 'Campo obbligatorio' },
    required: 'Campo obbligatorio',
  }, // 3 coverageToolManual c'è il required
  identificationNumber: {
    regex: { value: /^[a-zA-Z0-9]*$/ },
    string: true,
    min: { value: 6, message: 'Inserire un numero di documento valido' },
    max: { value: 15, message: 'Inserire un numero di documento valido' },
    required: 'Campo obbligatorio',
  },
  expirationDate: {
    // regex: /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/,
    string: true,
    required: 'Campo obbligatorio ',
    test: {
      field: 'rangeTest',
      message: 'Inserire una data di scadenza valida - made with capability',
      fn: (value) => {
        if (value?.includes('_') === false) {
          if (value.match(/^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/)) {
            const today: Date = new Date();
            const dmy = value.split('/');
            const selectedDate = new Date(parseInt(dmy[2]), parseInt(dmy[1]) - 1, parseInt(dmy[0]));
            const yesterday: Date = new Date();
            yesterday.setDate(today.getDate() - 1);
            const tenYears: Date = new Date();
            tenYears.setFullYear(today.getFullYear() + 10);
            if (selectedDate > yesterday && selectedDate <= tenYears) return true;
            return false;
          }
          return false;
        }
        return false;
      },
    },
  },
  nationality: {
    string: true,
    required: 'Campo obbligatorio ',
  },
  identificationType: {
    string: true,
    required: 'Campo obbligatorio ',
  },
  fiscalCode: {
    string: true,
    min: { value: 1, message: 'Campo Obbligatorio' },
    max: { value: 16, message: 'Inserire un codice fiscale valido' },
    required: 'Campo obbligatorio ',
    test: {
      field: 'fiscalCode',
      message: 'Inserire un codice fiscale valido - made with capability',
      fn: (value) => {
        if (value?.length === 16)
          if (
            value?.match(/^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/)
          )
            return true;
          else return false;
        return true;
      },
    },
    /*  regex:
      /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/, */
  },
  firstName: {
    required: 'Campo obbligatorio required ',
    string: true,
    regex: { value: /^[A-zÀ-ú ']*$/, message: 'Inserire un nome valido' },
    min: { value: 1, message: 'Campo obbligatorio' },
  }, // 2
  lastName: {
    required: 'Campo obbligatorio ',
    string: true,
    regex: { value: /^[A-zÀ-ú ']*$/, message: 'Inserire un cognome valido' },
    min: { value: 1, message: 'Campo obbligatorio del min' },
  }, // 2
  documentNumber: {
    regex: {
      value: /^[a-zA-Z0-9]*$/,
      message: 'Inserire un numero di documento valido',
    },
    min: { value: 6, message: 'Inserire un numero di documento valido' },
    max: { value: 15, message: 'Inserire un numero di documento valido' },
    string: true,
    required: 'Campo obbligatorio',
  },
  emailAddress: {
    string: true,
    required: 'Campo obbligatorio',
    email: 'inserire email nel formato corretto ',
  },
  placeId: {
    string: true,
    required: 'Campo obbligatorio',
  },
};

const normalizeInput = (input: FieldsName): FieldsName => {
  if (input.slice(-1) === '*') return input.slice(0, -1) as FieldsName;
  return input;
};
const handleInputsObject = (tempObj, inputLabel: FieldsName) => {
  const input: FieldsName = normalizeInput(inputLabel);
  tempObj = { ...tempObj, [input]: yup };
  const designedInput = configurationInputs[input];

  const isRequired = inputLabel.slice(-1) === '*' && designedInput.required;

  if (designedInput.string) tempObj[input] = tempObj[input].string();
  if (designedInput.min)
    tempObj[input] = tempObj[input].min(designedInput.min.value, designedInput.min.message);
  if (designedInput.max)
    tempObj[input] = tempObj[input].max(designedInput.max.value, designedInput.max.message);
  if (designedInput.regex)
    tempObj[input] = tempObj[input].matches(
      designedInput.regex?.value || designedInput.regex,
      designedInput.regex?.message || 'Campo errato'
    );
  if (isRequired) tempObj[input] = tempObj[input].required(isRequired);

  if (designedInput.test)
    tempObj[input] = tempObj[input].test(
      designedInput.test?.field || null,
      designedInput.test?.message || null,
      designedInput.test?.fn || designedInput.test
    );
  return tempObj;
};

export const defineYupSchema = (
  inputs: FieldsName[] | FieldsName
): OptionalObjectSchema<NonNullable<unknown>, AnyObject, TypeOfShape<NonNullable<unknown>>> => {
  let tempObj = {};
  if (Array.isArray(inputs)) {
    inputs.forEach((input) => {
      tempObj = handleInputsObject(tempObj, input);
    });
  } else if (configurationInputs[inputs]) {
    tempObj = handleInputsObject(tempObj, inputs);
  }
  return yup.object({ ...tempObj });
};

export const getYupSchema = (
  inputs: FieldsName[] | FieldsName
): OptionalObjectSchema<NonNullable<unknown>, AnyObject, TypeOfShape<NonNullable<unknown>>> | void => {
  if (Array.isArray(inputs)) {
    inputs.forEach((input) => {
      const normalizedInput = normalizeInput(input);
      const designedInput = configurationInputs[normalizedInput];
      if (designedInput) {
        return defineYupSchema(inputs);
      } LoggerInstance.debug('error no input found', input, configurationInputs);
    });
  } else if (configurationInputs[inputs]) {
    return defineYupSchema(inputs);
  } else {
    LoggerInstance.debug('error no input found', inputs, configurationInputs);
  }
  return console.error('get yup schema not work!');
};
