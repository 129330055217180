import { useMedia } from 'react-use';
import { breakpoints } from '@vfit/shared/themes';
import { handleEnv, useFullRender } from '@vfit/shared/data-access';

// defined defaultState to prevent a hydration mismatches when server side rendering
const isSetDefaultState = (fullRender: boolean): boolean | undefined => {
  const defaultMediaQueryValue = handleEnv('NEXT_PUBLIC_MEDIA_QUERY_DEFAULT_STATE') || '';

  if (defaultMediaQueryValue !== '' && fullRender) {
    return defaultMediaQueryValue?.toString()?.toLowerCase() === 'true';
  }
  return undefined;
};

export function useMediaQuery(breakpoint: keyof typeof breakpoints, logic: 'max' | 'min') {
  const { fullRender } = useFullRender();
  const query = `(${logic}-width: ${breakpoints[breakpoint]})`;
  return useMedia(query, isSetDefaultState(fullRender));
}
