import { pxToRem } from '@vfit/shared/themes';
import { ILoader } from './loader.models';
import { AnimatedPath } from './loader.style';

export const Loader = ({ size = 16, color = 'black', strokeWidth = 1 }: ILoader) => (
  <svg height={pxToRem(size)}  viewBox="0 0 34 44" fill="none">
    <AnimatedPath
      strokeDasharray={320}
      strokeDashoffset={0}
      d="M 25.087 15.996 C 22.75 14.751 21.479 14.556 18.482 14.057 C 18.482 8.568 21.978 3.08 21.978 3.08 C 21.978 3.08 16.551 4.005 12.501 6.572 C 9.738 8.324 7.145 10.989 5.509 13.558 C 2.404 18 1.843 22.526 2.033 26.264 C 2.247 35.036 9.567 41.58 17.562 41.58 C 25.557 41.58 32 35.321 32 27.283 C 32 21.617 29.359 18.36 25.38 16.156 Z"
      stroke={color}
      strokeWidth={strokeWidth}
    />
  </svg>
);

