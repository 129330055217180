import { INextError, ITrackError } from '@vfit/shared/data-access';

// export const errorMock = (
//   apiCall: string,
//   error?: INextError,
//   errorType?: string,
//   errorCode?: string,
//   errorMessage?: string
// ): ITrackError => ({
//   error: {
//     url:
//       error?.url?.substring(error.url.lastIndexOf('/') + 1, error?.url?.length)?.split('?')?.[0] ||
//       apiCall ||
//       '',
//     status: error?.status || 'timeout',
//     statusText: error?.statusText || 'timeout',
//   },
//   errorMessage: errorMessage || `Error occurred in ${apiCall} API service`,
//   errorType,
//   errorCode,
// });

export const errorMock = (
  apiCall: string,
  error?: INextError,
  errorType?: string,
  errorCode?: string,
  errorMessage?: string,
  isBlocking?: boolean,
  otherInfo?: any
): ITrackError => {
  return {
    error: {
      url:
        error?.url
          ?.substring(error.url.lastIndexOf('/') + 1, error?.url?.length)
          ?.split('?')?.[0] ||
        apiCall ||
        '',
      status: error?.status || 'timeout',
      statusText: error?.statusText || 'timeout',
    },
    errorMessage: errorMessage || `Error occurred in ${apiCall} API service`,
    errorType,
    errorCode,
    ...((error?.headers?.get('dex-transaction-id')) && {
      transaction_id: error?.headers?.get('dex-transaction-id') || '',
    }),
    ...(error?.headers?.get('err-backenderrorcode') && {
      err_backendErrorCode: error?.headers?.get('err-backenderrorcode') || '',
    }),
    ...(error?.headers?.get('err-backenderrormessage') && {
      err_backendErrorMessage: error?.headers?.get('err-backenderrormessage') || '',
    }),
    ...(error?.headers?.get('err-backendsource') && {
      err_backendSource: error?.headers?.get('err-backendsource') || '',
    }),
    ...(error?.headers?.get('err-errorcode') && {
      err_errorCode: error?.headers?.get('err-errorcode') || '',
    }),
    ...((error?.headers?.get('err-errormessage')) && {
      err_errorMessage: error?.headers?.get('err-errormessage') || '',
    }),
    ...(isBlocking && { isBlocking }),
    ...(otherInfo && { otherInfo }),
  };
};