import { useFormContext } from 'react-hook-form';
import { CustomInputProps } from '@vfit/shared/models';
import { ErrorMessage } from '../ErrorMessage/errorMessage';
import * as S from '../../cmsForm.style';

export const CustomCheckbox = ({
  name,
  label,
  isColMiddle,
  isHidden,
  isDisabled,
  isPendingRequest,
  ...props
}: CustomInputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const error = errors[name]?.message as string | undefined;

  return (
    <S.StyledCheckboxContainer isColMiddle={isColMiddle} isHidden={isHidden}>
      <S.StyledCheckboxLabel>
        <S.StyledCheckbox
          {...props}
          {...register(name)}
          disabled={isDisabled || isPendingRequest}
        />
        {label}
      </S.StyledCheckboxLabel>
      <ErrorMessage error={error} />
    </S.StyledCheckboxContainer>
  );
};
