import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const ThumbUp: React.FC<ISvgComponent> = ({ color, height, width, fill, ...rest }) => (
  <S.Svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M5.86719 21.3889H18.5971C20.0297 21.3889 21.2085 20.2771 20.5706 18.7215C22.0317 17.0365 21.2608 15.7619 21.2608 15.7619C21.2608 15.7619 22.6436 14.2622 21.3006 12.8036C21.6487 11.8274 21.8477 10.9644 20.5756 10.1014C19.3035 9.23839 16.2742 10.0728 15.1675 10.2133C13.6902 9.74078 18.0997 2.61166 13.4067 2.4873C13.3868 2.53829 12.8595 2.36295 13.0087 4.21332C13.0087 6.14451 9.36398 10.4446 8.17144 12.1184"
      fill={fill}
      stroke={color}
    />
    <path
      d="M8.95329 21.3886H5.47142C4.81182 21.3886 4.17923 21.1266 3.71281 20.6602C3.2464 20.1938 2.98438 19.5612 2.98438 18.9016V13.9275C2.98438 13.2679 3.2464 12.6353 3.71281 12.1689C4.17923 11.7025 4.81182 11.4404 5.47142 11.4404H6.46624C7.12585 11.4404 7.75844 11.7025 8.22485 12.1689C8.69126 12.6353 8.95329 13.2679 8.95329 13.9275V21.3886Z"
      fill={fill}
      stroke={color}
    />
  </S.Svg>
);
