import styled, { createGlobalStyle } from 'styled-components';
import { breakpoints, colors } from '@vfit/shared/themes';
import { ICSSContainer } from '../../modal.models';

export const OverlayGlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: fixed;
  }
`;

export const Background = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  background-color: #0000008f;
`;

export const Container = styled.div<ICSSContainer>`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  border-top-left-radius: ${(props) => (props.withBorderRadius ? '20' : '0')}px;
  border-top-right-radius: ${(props) => (props.withBorderRadius ? '20' : '0')}px;
  background-color: ${colors.$ffffff};
  overflow: hidden;

  .closing-icon {
    float: right;
    margin-top: 11.5px;

    @media (min-width: ${breakpoints.tablet}) {
      margin-top: 21.5px;
      margin-right: 21.5px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  a {
    color: ${colors.$262626} !important;
    font-weight: 700 !important;

    :visited {
      color: ${colors.$262626} !important;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    min-height: inherit;
    width: fit-content;
    height: fit-content;
    ${(props) =>
      !props.tabletMode
        ? `
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 62.2%;
          border-radius: 20px;
           .closing-icon {
            margin-top: 29.5px;
            margin-right: 21.5px;
          }
        `
        : `
         left: 50%;
          top: 50%;
          border-radius: 6px;
          transform: translate(-50%, -50%);`}
  }

  @media (min-width: ${breakpoints.desktop}) {
    min-height: inherit;
    left: 50%;
    top: 50%;
    // border-radius: 6px;
    transform: translate(-50%, -50%);
    min-width: fit-content;
    height: fit-content;
    max-width: .closing-icon {
      margin-top: 29.5px;
      margin-right: 29.5px;
    }
  }

  @media (min-width: ${breakpoints.bigDesktop}) {
    min-height: inherit;
    max-width: fit-content;
  }
`;

export const ModalContent = styled.div<{
  height: string | number | undefined;
  containerHeight?: string;
}>`
  overflow: auto;
  scrollbar-width: 3px;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

// TODO: wip FIGMA modal error styles
export const ContainerModalError = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.$ffffff};

  @media (min-width: ${breakpoints.tablet}) {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 62.2%;
  }

  .closing-icon:hover {
    cursor: pointer;
  }
`;

export const ModalErrorHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 25px;
  border-bottom: 1px solid #5e5e5e;
`;

export const ModalErrorContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 25px 25px;
`;

export const ModalErrorFooter = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 25px;

  button {
    text-transform: uppercase;
    max-width: 300px;
  }
`;

export const IconButton = styled.div<{ color?: string }>`
  position: absolute;
  right: 24px;
  path {
    stroke: ${(props) => (props.color ? props.color : colors.$bebebe)};
  }
`;
