import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const Error: React.FC<ISvgComponent> = ({ color, height, width, shrinkable, ...rest }) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 24 24"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      d="m21.634 17.045-7-12.964a3 3 0 0 0-5.265 0l-7 12.959A3 3 0 0 0 5 21.48h14a3 3 0 0 0 2.634-4.435zM11.5 7.983a.5.5 0 1 1 1 0v7.062a.5.5 0 1 1-1 0zm.5 10.125a.625.625 0 1 1 .44-.186.625.625 0 0 1-.44.182z"
      fill={color}
    />
  </S.Svg>
);

