import { IDeactivationForm } from '@vfit/shared/models';
import { IDeactivationModalDetail, deactivationModalmanager } from '@vfit/shared/data-access';

export const openDeacivationModal = (props: IDeactivationModalDetail) => {
  deactivationModalmanager.handleDeactivateModal({ ...props });
};
export interface IGetAllDeactivationForm {
  [key: string]: IDeactivationForm;
}

