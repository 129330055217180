import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const Close: React.FC<ISvgComponent> = ({ color, height, width, shrinkable, ...rest }) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 24 24"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m5.5 18.5 13-13m0 13-13-13"
    />
  </S.Svg>
);

