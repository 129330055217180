import { ILeadModalDetail } from './interfaces';

export class ILeadModalService {
  private customLeadModalEvent: CustomEvent | undefined;

  /**
   * Handle Lead Modal
   */
  public handleLeadModal(leadDetail?: ILeadModalDetail): void {
    this.customLeadModalEvent = new CustomEvent('leadModalNotificationEvent', {
      detail: leadDetail,
    });
    document.dispatchEvent(this.customLeadModalEvent);
  }
}
