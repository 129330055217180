import { breakpoints, colors, getTypography, pxToRem } from '@vfit/shared/themes';
import { css } from 'styled-components';
import { IButtonStyledComponent } from './button.models';

const medium = css`
  height: ${pxToRem(35)};
  width: ${pxToRem(35)};
`;

// Sizes used when the model variable is circle
const circleSizes = {
  small: css`
    height: ${pxToRem(26)};
    width: ${pxToRem(26)};
  `,
  medium: css`
    ${medium}
  `,
  big: css`
    height: ${pxToRem(55)};
    width: ${pxToRem(55)};
  `,
  responsive: css`
    ${medium}

    @media (min-width: ${breakpoints.desktop}) {
      height: ${pxToRem(50)};
      width: ${pxToRem(50)};
    }
  `,
};

// Sizes used when 'model' variable is regular by default
// default size created to take into consideration the previous components using height 45
const regularSizes = {
  default: css`
    height: ${pxToRem(45)};
  `,
  small: css`
    height: ${pxToRem(30)};
  `,
  medium: css`
    height: ${pxToRem(40)};
  `,
  big: css`
    height: ${pxToRem(55)};
  `,
  responsive: css`
    height: ${pxToRem(55)};

    @media (min-width: ${breakpoints.desktop}) {
      height: ${pxToRem(50)};
    }
  `,
};

// ${({ variant }) =>
//   variant?.startsWith('mva') ? getTypography('button1.regular') : getTypography('button2.bold')};

const font = css<IButtonStyledComponent>`
  ${({ variant, size }) => {

    if (!variant?.startsWith('mva'))
      return getTypography('button2.bold')

    if (size == 'small')
      return getTypography('button2.regular')

    return getTypography('button1.regular')
  }
  };
`;

const regular = css<IButtonStyledComponent>`
  ${font}
  ${({ size }) => regularSizes[size || 'default']}
  padding: ${({ variant }) =>
    variant?.startsWith('mva') ? pxToRem(10, 12) : pxToRem(10, 24)};
  width: 100%;
  @media (min-width: ${breakpoints.desktop}) {
        padding: ${({ variant }) =>
    variant?.startsWith('mva') ? pxToRem(10, 16) : pxToRem(10, 24)};
    };
`;

const circle = css<IButtonStyledComponent>`
  border-radius: 50%;
  ${({ size }) => circleSizes[size || 'small']}
`;

const variants = {
  primary: css`
    background-color: ${colors.$e60000};
    border-color: ${colors.$e60000};
    color: ${colors.$ffffff};

    &:hover {
      background-color: ${colors.$bd0000};
      border-color: ${colors.$bd0000};
    }

    &:active {
      background-color: ${colors.$a10000};
      border-color: ${colors.$a10000};
    }
  `,
  secondary: css`
    background-color: 'transparent';
    border-color: ${colors.$262626};
    color: ${colors.$262626};

    &:hover {
      background-color: ${colors.$262626};
      color: ${colors.$ffffff};
    }

    &:active {
      background-color: ${colors.$7e7e7e};
      border-color: ${colors.$7e7e7e};
    }
  `,
  tertiary: css`
    background-color: ${colors.$ffffff};
    border: none;
    color: ${colors.$262626};

    &:hover {
      background-color: ${colors.$ffffff_60};
    }

    &:active {
      background-color: ${colors.$ffffff_80};
    }
  `,
  'mva:primary': css`
    background-color: ${colors.$e60000};
    border-color: ${colors.$e60000};
    color: ${colors.$ffffff};

    &:hover {
      background-color: ${colors.$c30000};
      border-color: ${colors.$c30000};
    }

    &:active {
      background-color: ${colors.$a10000};
      border-color: ${colors.$a10000};
    }
  `,
  'mva:secondary': css`
    background-color: ${colors.$0d0d0d};
    border-color: ${colors.$0d0d0d};
    color: ${colors.$ffffff};

    &:hover {
      background-color: ${colors.$313131};
      border-color: ${colors.$313131};
    }

    &:active {
      background-color: ${colors.$565656};
      border-color: ${colors.$565656};
    }
  `,
  'mva:alt1': css`
    background-color: 'transparent';
    border-color: ${colors.$262626};
    color: ${colors.$262626};

    &:hover {
      background-color: ${colors.$313131};
      border-color: ${colors.$313131};
      color: ${colors.$ffffff};
    }

    &:active {
      background-color: ${colors.$565656};
      border-color: ${colors.$565656};
    }
  `,
};

const disabledVariants = {
  primary: css`
    background-color: ${colors.$bebebe};
    border-color: ${colors.$bebebe};
    color: ${colors.$ffffff};
  `,
  secondary: css`
    background-color: transparent;
    border-color: ${colors.$bebebe};
    color: ${colors.$bebebe};
  `,
  tertiary: css`
    background-color: ${colors.$ffffff_60};
    color: ${colors.$bebebe};
  `,
  'mva:primary': css`
    background-color: ${colors.$eb8a90};
    border-color: ${colors.$eb8a90};
    color: ${colors.$ffffff};
  `,
  'mva:secondary': css`
    background-color: ${colors.$9a9a9a};
    border-color: ${colors.$9a9a9a};
  `,
  'mva:alt1': css`
    background-color: transparent;
    border-color: ${colors.$9a9a9a};
    color: ${colors.$9a9a9a};
  `,
};

export const common = css<IButtonStyledComponent>`
  align-items: center;
  border-radius: ${({ variant }) => (variant?.startsWith('mva') ? pxToRem(6) : pxToRem(30))};
  border-style: solid;
  border-width: ${({ variant }) => (variant?.startsWith('mva') ? '2px' : '1px')};
  display: inline-flex;
  gap: ${pxToRem(8)};
  justify-content: center;
  transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  white-space: nowrap;
  ${({ variant }) => variants[variant || 'primary']}
  ${({ model }) => (model === 'regular' ? regular : circle)}
  ${({ loading }) => loading && preventInteraction}

  &::selection {
    background-color: transparent;
  }

  & svg * {
    transition: stroke 0.3s ease, fill 0.3s ease;
  }
`;

// combining both properties together will prevent the button interaction
export const preventInteraction = css<IButtonStyledComponent>`
  cursor: not-allowed;
  pointer-events: none;
`;

// cursor: not-allowed; is ignored because pointer-events: none;
// but pointer-events: none; is necessary to disable Link from Next
export const disabled = css<IButtonStyledComponent>`
  ${({ variant }) => disabledVariants[variant || 'primary']}
  ${preventInteraction}
`;



