import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const Call: React.FC<ISvgComponent> = ({ color, height, width, shrinkable, ...rest }) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 18 18"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeMiterlimit="10"
      d="M16.882 13.283a.36.36 0 0 1 0 .091c-.005.283-.05.564-.134.834a3.657 3.657 0 0 1-2.15 2.391c-2.5.75-5.158 0-7.5-1.191a10.125 10.125 0 0 1-4.483-4.459c-1.184-2.325-1.95-5-1.2-7.5A3.675 3.675 0 0 1 3.84 1.324l.833-.216a.275.275 0 0 1 .325.166l.667 1.892.983 2.75v.075a.317.317 0 0 1-.15.35 3.8 3.8 0 0 1-1.058.558 6.717 6.717 0 0 1-1.4.275.308.308 0 0 0-.225.134.283.283 0 0 0 0 .258 10.216 10.216 0 0 0 2.5 4.167 10.308 10.308 0 0 0 4.167 2.5.283.283 0 0 0 .258 0 .307.307 0 0 0 .133-.217c.041-.473.133-.94.275-1.392.124-.38.313-.735.559-1.05a.3.3 0 0 1 .375-.133h.075l2.758.983c.658.234 1.217.467 1.875.7.033-.016.075.1.092.159z"
    />
  </S.Svg>
);

