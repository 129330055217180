import { IFrameService } from './iframeManager';
import { IFrameDetail } from './iframeManager/interfaces';
import { ToastService } from './toastManager';
import { IToastDetail } from './toastManager/interfaces';
import { ILeadModalService } from './leadModalManager';
import { IVolaModalService } from './volaModalManager';
import { ILeadModalDetail, IProductLead } from './leadModalManager/interfaces';
import { IDeactivationModalDetail } from './deactivationModalmanager/interfaces';
import { IHtmlModalDetail } from './htmlModalManager/interfaces';
import { IVolaModalDetail } from './volaModalManager/interfaces';
import { handleEnv } from './handleEnv';
import { LoggerInstance } from './logger';

import {
  DxlError,
  errorMessages,
  delay,
  NEXT_ERR_MSG,
  NEXT_ERR_TRANSACTION_ID,
} from './fetcher/utils';

import {
  CustomOptions,
  CustomMutationOptions,
  handleUseQuery,
  handleUseQueryes,
  resetData,
  UseQueryConfig,
} from './ReactQuery';
import OneTrustCookieConsents from './tracking/oneTrustCookieConsents';
import { ErrorDetail, Severity } from './errorManager/interfaces';
import { ErrorService } from './errorManager';
import { TrackingContextProvider, useTrackingProvider } from './tracking/provider';
import { DxlResponse, INextError, ITrackError, ITrackErrorRes } from './fetcher';
import { IDeactivationModalService } from './deactivationModalmanager';
import { IHtmlModalService } from './htmlModalManager';

export const iFrameManager = new IFrameService();
export const toastManager = new ToastService();
export const leadModalManager = new ILeadModalService();
export const volaModalManager = new IVolaModalService();
export const errorManager = new ErrorService();
export const deactivationModalmanager = new IDeactivationModalService();
export const htmlModalManager = new IHtmlModalService();

/** tracking folder */
export { default as useTracking } from './tracking/use-tracking';
export { tracking } from './tracking/use-tracking';
export * from './tracking/models';
export * from './tracking/utils';
export * from './contexts/tabsContext';
export * from './fullRender';

export type { CustomOptions, CustomMutationOptions, UseQueryConfig, DxlResponse };
export type {
  IFrameDetail,
  IToastDetail,
  INextError,
  ITrackError,
  ITrackErrorRes,
  ILeadModalDetail,
  IProductLead,
  IVolaModalDetail,
  ErrorDetail,
  Severity,
  IDeactivationModalDetail,
  IHtmlModalDetail
};

export {
  TrackingContextProvider,
  LoggerInstance,
  handleEnv,
  ErrorService,
  handleUseQuery,
  handleUseQueryes,
  resetData,
  OneTrustCookieConsents,
  DxlError,
  errorMessages,
  delay,
  NEXT_ERR_MSG,
  NEXT_ERR_TRANSACTION_ID,
  useTrackingProvider,
};
export * from './fetcher';
export * from './regex';
export * from './query'
