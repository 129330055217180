import { ISvgComponent } from '../../svg.models';
import * as S from '../svg.style';

export const ChevronUp: React.FC<ISvgComponent> = ({
  color,
  height,
  width,
  shrinkable,
  ...rest
}) => (
  <S.Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 24 24"
    shrinkable={shrinkable}
    {...rest}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeMiterlimit="10"
      d="m3.5 16.354 8.5-8.5 8.5 8.5"
    />
  </S.Svg>
);

